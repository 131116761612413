import createPersistedState from 'vuex-persistedstate';

export const bestPracticesInitialState = {
  frequentOutlines: [],
  allDecks: [],
  decksLoaded: [],
  selectedOutlines: ['All'],
  sortByFilter: 'Recommended',
  selectedViewType: 'Decks',
  bpeDeckLandindSearchText: '',
  bpeSlideLandindSearchText: '',
  previousRoute: '',
};

export const bestPracticesExamples = {
  namespaced: true,
  state: {
    ...bestPracticesInitialState,
  },
  getters: {
    getFrequentOutlines: (state) => state.frequentOutlines ? state.frequentOutlines : [],
    getAllDecks: (state) => state.allDecks ? state.allDecks : [],
    getDecksLoaded: (state) => state.decksLoaded ? state.decksLoaded : [],
    getSelectedOutlines: (state) => state.selectedOutlines ? state.selectedOutlines : ['All'],
    getSortBy: (state) => state.sortByFilter ? state.sortByFilter : 'Recommended',
    getSelectedViewType: (state) => state.selectedViewType ? state.selectedViewType : 'Decks',
    getBPEDeckLandingSearchText: (state) => state.bpeDeckLandindSearchText,
    getBPESlideLandingSearchText: (state) => state.bpeSlideLandindSearchText,
    getPreviousRoute: (state) => state.previousRoute,
  },
  mutations: {
    SET_FREQUENT_OUTLINES(state, outlines) {
      state.frequentOutlines = outlines;
    },
    SET_DECKS_ON_LOAD(state, decks) {
      state.allDecks = [...decks];
      state.decksLoaded = [...decks];
    },
    SET_DECKS_LOADED(state, decks) {
      state.decksLoaded = [...decks];
    },
    SET_SELECTED_OUTLINES_AND_SEARCH(state, outline) {
      let outlines = [];
      const existingSelected = [...state.selectedOutlines];
      if (outline !== 'All') {
        outlines = [...existingSelected].filter(
          (otl) => otl !== 'All',
        );
        if (outlines.indexOf(outline) === -1) {
          outlines.push(outline);
        } else {
          outlines = [...existingSelected].filter(
            (otl) => otl !== outline,
          );
          if (outlines.length <= 0) {
            outlines = ['All'];
          }
        }
      } else {
        outlines = ['All'];
      }
      state.selectedOutlines = [...outlines];
      // filter decks based on outlines selected

      if (outlines.length === 1 && outlines[0] === 'All') {
        state.decksLoaded = [...state.allDecks];
      } else {
        state.decksLoaded = [...state.allDecks].filter((prz) => outlines.includes(prz.type));
      }
    },
    SET_SORT_BY_FILTER_DECKS(state, filter) {
      state.sortByFilter = filter;
      if(filter === 'A-Z') {
        state.decksLoaded.sort((a, b) => {
          const nameA = a.name.toUpperCase();
          const nameB = b.name.toUpperCase();
        
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        
      } else if (filter === 'Z-A') {
        state.decksLoaded.sort((a, b) => {
          const nameA = a.name.toUpperCase();
          const nameB = b.name.toUpperCase();
        
          if (nameA < nameB) {
            return 1;
          }
          if (nameA > nameB) {
            return -1;
          }
          return 0;
        });
      } else if (filter === 'Most Popular') {
        const existingDecks = [...state.decksLoaded];
        const trendingDecks = [...existingDecks].filter((itm) => itm.isTrending).sort((a, b) => Number(b.viewCount) - Number(a.viewCount));
        const otherDecks = [...existingDecks].filter((itm) => !itm.isTrending).sort((a, b) => Number(b.viewCount) - Number(a.viewCount));
        state.decksLoaded = [...trendingDecks, ...otherDecks];
      } else {
        state.decksLoaded = [...state.decksLoaded].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));
      }
    },
    SET_BPE_VIEW_TYPE(state, type){
      state.selectedViewType = type;
    },
    SET_BPE_DECK_LOCAL_SEARCH_TEXT(state, text){
      state.bpeDeckLandindSearchText = text;
    },
    SET_BPE_PREVIOUS_ROUTE(state, text) {
      state.previousRoute = text;
    },
    SET_BPE_SLIDES_LOCAL_SEARCH_TEXT(state, text){
      state.bpeSlideLandindSearchText = text;
    },
    UPDATE_BPE_DECK_WITH_ACTION(state, actionData) {
      const { deck, action, origin, currentUser } = actionData;
      if (action === 'addFav' || action === 'removeFav') {
        const indexToUpdate = state.decksLoaded.findIndex(
          (dk) => (origin === 'hybrid_search' ? dk.prezentationId : dk.id) === (origin === 'hybrid_search' ? deck.prezentationId : deck.id),
        );
        const indexToUpdateAll = state.allDecks.findIndex(
          (dk) => (origin === 'hybrid_search' ? dk.prezentationId : dk.id) === (origin === 'hybrid_search' ? deck.prezentationId : deck.id),
        );
        if (origin === 'hybrid_search' && action === 'addFav') {
          state.decksLoaded[indexToUpdate].favourites_by.push(currentUser.user.num_id);
          state.allDecks[indexToUpdateAll].favourites_by.push(currentUser.user.num_id);
        } else if (origin === 'hybrid_search' && action === 'removeFav') {
          let indexx = state.decksLoaded[indexToUpdate].favourites_by.indexOf(currentUser.user.num_id);
          if (indexx > -1) {
            state.decksLoaded[indexToUpdate].favourites_by.splice(indexx, 1);
          }
          indexx = state.allDecks[indexToUpdateAll].favourites_by.indexOf(currentUser.user.num_id);
          if (indexx > -1) {
            state.allDecks[indexToUpdateAll].favourites_by.splice(indexx, 1);
          }
        } else {
          state.decksLoaded[indexToUpdate].isFavorite =
            action === 'addFav' ? true : false;
          state.allDecks[indexToUpdateAll].isFavorite =
            action === 'addFav' ? true : false;
        }
      } else if (action === 'downloadDeck') {
        const indexToUpdate = state.decksLoaded.findIndex(
          (dk) => (origin === 'hybrid_search' ? dk.prezentationId : dk.id) === (origin === 'hybrid_search' ? deck.prezentationId : deck.id),
        );
        const indexToUpdateAll = state.allDecks.findIndex(
          (dk) => (origin === 'hybrid_search' ? dk.prezentationId : dk.id) === (origin === 'hybrid_search' ? deck.prezentationId : deck.id),
        );
        if (origin === 'hybrid_search') {
          state.decksLoaded[indexToUpdate].downloaded_by.push(currentUser.user.num_id);
          state.allDecks[indexToUpdateAll].downloaded_by.push(currentUser.user.num_id);
        } else {
          state.decksLoaded[
            indexToUpdate
          ].isDownloaded = true;
          state.allDecks[indexToUpdateAll].isDownloaded = true;
        }
      }
    },
  },
  actions: {
    updateBPEDeckWithAction({ commit }, actionData) {
      commit('UPDATE_BPE_DECK_WITH_ACTION', actionData);
    },
    setFrequentOutlines({ commit }, outlines) {
      commit ('SET_FREQUENT_OUTLINES', outlines);
    },
    setDecksOnLoad({ commit }, decks) {
      commit ('SET_DECKS_ON_LOAD', decks);
    },
    setDecksLoaded({ commit }, decks) {
      commit ('SET_DECKS_LOADED', decks);
    },
    setSelectedOutlinesAndSearch({ commit }, outlines) {
      commit('SET_SELECTED_OUTLINES_AND_SEARCH', outlines);
    },
    setSortByFilterDecks({ commit }, filter) {
      commit('SET_SORT_BY_FILTER_DECKS', filter);
    },
    setBPEviewType({commit}, type){
      commit('SET_BPE_VIEW_TYPE', type)
    },
    setBPEDeckLocalSearchText({commit}, text){
      commit('SET_BPE_DECK_LOCAL_SEARCH_TEXT', text)
    },
    setBPESlideLocalSearchText({commit}, text){
      commit('SET_BPE_SLIDES_LOCAL_SEARCH_TEXT', text)
    },
    setBPEPreviousRoute({commit}, text) {
      commit('SET_BPE_PREVIOUS_ROUTE', text)
    },
  },
  persist: createPersistedState({
    key: 'vuex_bestPracticesExamples',
    paths: [],
  }),
};