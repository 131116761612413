import Vue from 'vue';
import Vuex from 'vuex';
import { API, Storage } from 'aws-amplify';
import API2 from '../../utils/api';
import {
  listThemess,
  favoriteByUser,
  listPrivateAudiences,
} from '@/graphql/queries';
import { fpTypes } from '../../mock/fingerprint';
import { MatomoAnalyticsHandler } from '../../components/common/Analytics/MatomoAnalyticsHandler';
import usersApi from '../../API/users-api';
import {
  getFingerprint,
  updatePrivateAudience,
  updateAudience,
  updateAudienceFingerPrint,
} from '../../utils/api-helper';
import createPersistedState from 'vuex-persistedstate';
import { frameUserPreferencesResponse } from '../../utils/common';
import { getFileURL } from '@/utils/calyrex';

Vue.use(Vuex);

export const usersInitialState = {
  currentUser: { preferences: [] },
  audience: null,
  currentTestAudience: null,
  currentPrivateAudience: null,
  currentActiveSubscription: null,
  sessionKey: '',
  assetVotes: [],
  onedriveFolderId: null,
  onedriveAccessToken: null,
  currentTheme: null,
  themes: [],
  availablePlans: [],
  upgradeProgress: false,
  shownPaymentDialog: false,
  assetFolder: '',
  currentUserFavorites: [],
  searchProgress: false,
  availableAddOns: [],
  searchTerms: [],
  isTrialExpired: false,
  trialDaysLeft: 0,
  waitForFP: false,
  userAssociatedCompanies: [],
  companyDomains: [],
  sharedPrivateAudienceListByUser: [],
  sharedAudienceListByUser: [],
  sharedUserListByUser: [],
  companyData: {},
  currSearchedPerson: {},
  intialDataLoaded: false,
  companyDetails: {},
  startLoadingSlidesFlag: false,
  sharedUserCount: null,
  prezentationInProcess: null,
  storylines: [],
  fingerPrintAdditionalData: {},
  hybridSearchQueryPayload: {},
  leftSideBarStatus: false,
  isOPFundLow: false,
  opLimitedBanner: null,
  opUnreadMessageData: [],
  totalUsersInCompany: 0,
  teamUsersLoadedFlag: false,
  typesenseRealtimeAudiences: [],
  typesenseSelfAudience: null,
  typesenseAudiencesLoaded: false,
  limitsInfoObj: {},
  limitsInfo: [],
  showUpgradePopup: false,
  isBannerActive: false,
  upgradePopupType: '',
  uploadFlowCTAOrigin: '',
  showUpgradeBanner: false,
  tdCompletionPercentage: 0,
  showConfettiFlag: false,
  recentSlideSearches: [],
  recentBPESlideSearches: [],
  quickSelectionTemplates: 'All',
  slidesAudienceSelected: null,
  userDownloadPreference: '',
};

const avatars = {
  director: 'lion.png',
  navigator: 'Wolf.png',
  scholar: 'Owl.png',
  performer: 'Peacock.png',
  architect: 'Horse.svg',
  surgeon: 'Eagle.png',
  scientist: 'Dolphin.png',
  producer: 'Bear.png',
};

const formatUsersList = (data = []) =>
  data.map((user) => ({
    ...user,
    avatar:
      user.fingerPrint &&
      `/assets/img/${avatars[user.fingerPrint.toLowerCase()]}`,
    fullName: !user.lastName
      ? user.firstName
      : `${user.firstName} ${user.lastName}`,
    role: user.role || 'User',
  }));

export const users = {
  namespaced: true,
  state: {
    ...usersInitialState,
  },
  getters: {
    getCurrentUser: (state) => state.currentUser,
    getRecentSlideSearches: (state) => state.recentSlideSearches,
    getRecentBPESlideSearches: (state) => state.recentBPESlideSearches,
    getRecentLearnSearches: (state) =>
      state.currentUser.user.recentLearnSearches,
    getCurrentTestAudience: (state) => state.currentTestAudience,
    getUpgradeProgress: (state) => state.upgradeProgress,
    getAssetFolder: (state) => state.assetFolder,
    isUserLoggedIn: (state) => !!state.CurrentUser?.user.id,
    getIsNonBrandedCompany: (state) => state.currentUser?.company?.isNonBranded,
    getIsDownloadLimitExceeded: (state) => (type) => {
      if (state.currentUser.subscriptions[0].planID === '12345') {
        if (type === 'best-practice-library') {
          return (
            state.currentUser.user.knowDownloads >=
            (state.currentUser.user.knowDownloadLimit || 5)
          );
        } else {
          return (
            state.currentUser.user.downloadUsed >=
            state.currentUser.user.downloadLimit
          );
        }
      } else {
        return false;
      }
    },
    /* TODO: Change the name to "doUserHaveStandardSubscription" from "doUserHavePremiumSubscription" and all its references,
      as the logic checks for user being on standard subscription */
    doUserHavePremiumSubscription: (state) => {
      return (
        state.currentUser.subscriptions[0].planID !== '12349' &&
        state.currentUser.subscriptions[0].planID !== '12347'
      );
    },
    fingerprintCreatedViewed: (state) => {
      const { fingerprintCreated, fingerprintViewed } =
        state.currentUser.user || {};
      if (
        (typeof fingerprintCreated === 'number' ||
          typeof fingerprintCreated === 'string') &&
        (typeof fingerprintViewed === 'string' ||
          typeof fingerprintViewed === 'number')
      ) {
        return Number(fingerprintCreated) + Number(fingerprintViewed);
      }
      return 0;
    },
    getGenerateSlideDownloadLimitExceeded: (state) => {
      if (
        state.currentUser.subscriptions[0].planID === '12345' &&
        state.currentUser.user.generateSlidesDownloaded >= 30
      ) {
        return true;
      } else {
        return false;
      }
    },
    getUser: (state) => state.currentUser,
    userStats: (state) => {
      if (state.currentUser && state.teamUsers) {
        const user = state.currentUser;
        const teamUser = state.teamUsers.find((u) => u.id === user.user.id);
        return teamUser || {};
      }
      return {};
    },
    getCompanyDomain: (state) => state.companyDomains,
    getCurrSearchedPerson: (state) => state.currSearchedPerson,
    getStartLoadingSlidesFlag: (state) => state.startLoadingSlides,
    getPrezentationInProcess: (state) => state.prezentationInProcess,
    isKnowEnabled: (state) =>
      state.currentUser &&
      state.currentUser.features.find(
        (f) => f.feature_name === 'best-practice-library' && f.enabled,
      ),
    isActiveUserLineChartEnabled: (state) => {
      if (state.currentUser) {
        const feature = state.currentUser.features.find(
          (f) => f.feature_name === 'active-user-line-chart',
        );
        return feature && feature.enabled;
      }
      return false;
    },
    isTrialUpgradePayByCardEnabled: (state) => {
      if (state.currentUser) {
        const feature = state.currentUser.features.find(
          (f) => f.feature_name === 'pay_by_card_trial',
        );
        return feature && feature.enabled;
      }
      return false;
    },
    isFilterByBPEFunctionEnabled: (state) => {
      if (state.currentUser) {
        const feature = state.currentUser.features.find(
          (f) => f.feature_name === 'filter_by_bpe_function',
        );
        return feature && feature.enabled;
      }
      return false;
    },
    getGenerateAccessLevel: (state) => {
      const feature = state.currentUser.features.find(
        (f) => f.feature_name === 'slide-generate',
      );
      return (
        (feature &&
          feature.enabled &&
          feature.access_level &&
          feature.access_level === 'full_access') ||
        (feature &&
          feature.access_level &&
          feature.access_level === 'limited_access') ||
        (feature &&
          feature.access_level &&
          feature.access_level === 'restricted_access')
      );
    },
    getAGLivePromptAccess: (state) => {
      const feature = state.currentUser.features.find(
        (f) => f.feature_name === 'ag_live_prompts',
      );
      return feature && feature.enabled;
    },
    getAGLiveSuggestionEnabled: (state) => {
      return state.currentUser.user.hideAgPromptSuggestions === 0;
    },
    getUploadAccessLevel: (state) => {
      const feature = state.currentUser.features.find(
        (f) => f.feature_name === 'user-prezentation-upload',
      );
      return (
        (feature &&
          feature.enabled &&
          feature.access_level &&
          feature.access_level === 'full_access') ||
        (feature &&
          feature.access_level &&
          feature.access_level === 'limited_access') ||
        (feature &&
          feature.access_level &&
          feature.access_level === 'restricted_access')
      );
    },
    getSynthesisAccessLevel: (state) => {
      const feature = state.currentUser.features.find(
        (f) => f.feature_name === 'synthesis',
      );
      return (
        (feature &&
          feature.enabled &&
          feature.access_level &&
          feature.access_level === 'full_access') ||
        (feature &&
          feature.access_level &&
          feature.access_level === 'limited_access') ||
        (feature &&
          feature.access_level &&
          feature.access_level === 'restricted_access')
      );
    },
    getOPAccessLevel: (state) => {
      if (state.currentUser) {
        const op = state.currentUser.features.find(
          (f) => f.feature_name === 'overnight-prezentations',
        );
        if (
          op.access_level != 'no_access' &&
          !op.enabled &&
          op.access_level != null
        ) {
          return 'disabled';
        }
        return op && op.access_level
          ? op.access_level
          : op.enabled
          ? 'full_access'
          : 'no_access';
      }
      return 'no_access';
    },
    getFeatureAccessDate: (state) => (feature) => {
      if (state.currentUser) {
        const op = state.currentUser.features.find(
          (f) => f.feature_name === feature,
        );
        return op?.updated_at;
      }
      return null;
    },
    getSubFeatureReleaseDate: (state) => (feature, subFeature) => {
      if (state.currentUser) {
        const features = state.currentUser.features.find(
          (f) => f.feature_name === feature,
        );
        const subFeatures = features?.sub_features.find(
          (f) => f.feature_name === subFeature,
        );
        if (subFeatures) {
          return subFeatures.date;
        }
        return null;
      }
      return null;
    },
    getOPLimitedBanner: (state) => state.opLimitedBanner,
    isPrezentationUploadEnabled: (state) =>
      state.currentUser &&
      state.currentUser.features.find(
        (f) =>
          (f.feature_name === 'user-prezentation-upload' &&
            f.enabled &&
            f.access_level === 'full_access') ||
          (f.feature_name === 'user-prezentation-upload' &&
            f.access_level === 'limited_access'),
      ),
    getTemplateByCode: (state) => (code) => {
      return state.themes.find((theme) => theme.code === code);
    },
    getStorylines: (state) => state.storylines,
    getTotalUsersInCompany: (state) => state.totalUsersInCompany,
    isUploadFullAccess(state) {
      const uploadFeature = state.currentUser?.features?.find(
        (ft) => ft.feature_name === 'user-prezentation-upload',
      );
      return (
        (uploadFeature &&
          uploadFeature.enabled &&
          uploadFeature.access_level &&
          uploadFeature.access_level === 'full_access') ||
        (uploadFeature &&
          uploadFeature.access_level &&
          uploadFeature.access_level === 'restricted_access')
      );
    },
    isUploadLimittedAccess(state) {
      const uploadFeature = state.currentUser?.features?.find(
        (ft) => ft.feature_name === 'user-prezentation-upload',
      );
      return (
        uploadFeature &&
        uploadFeature.access_level &&
        (uploadFeature.access_level === 'limited_access' ||
          uploadFeature.access_level === 'full_access')
      );
    },
    isRedesignFullAccess(state) {
      const uploadFeature = state.currentUser?.features?.find(
        (ft) => ft.feature_name === 'redesign',
      );
      return (
        (uploadFeature &&
          uploadFeature.enabled &&
          uploadFeature.access_level &&
          uploadFeature.access_level === 'full_access') ||
        (uploadFeature &&
          uploadFeature.access_level &&
          uploadFeature.access_level === 'restricted_access')
      );
    },
    isRedesignLimitedAccess(state) {
      const uploadFeature = state.currentUser?.features?.find(
        (ft) => ft.feature_name === 'redesign',
      );
      return (
        uploadFeature &&
        uploadFeature.access_level &&
        uploadFeature.access_level === 'limited_access'
      );
    },
    isRedesignLimitedAccess(state) {
      const uploadFeature = state.currentUser?.features?.find(
        (ft) => ft.feature_name === 'redesign',
      );
      return (
        uploadFeature &&
        uploadFeature.access_level &&
        uploadFeature.access_level === 'limited_access'
      );
    },
    isRedesignConfidenceScoreFullAccess(state) {
      const uploadFeature = state.currentUser?.features?.find(
        (ft) => ft.feature_name === 'redesign_confidence_score',
      );
      return (
        (uploadFeature &&
          uploadFeature.enabled &&
          uploadFeature.access_level &&
          uploadFeature.access_level === 'full_access') ||
        (uploadFeature &&
          uploadFeature.access_level &&
          uploadFeature.access_level === 'restricted_access')
      );
    },

    isComplyFullAccess(state) {
      const uploadFeature = state.currentUser?.features?.find(
        (ft) => ft.feature_name === 'comply',
      );
      return (
        (uploadFeature &&
          uploadFeature.enabled &&
          uploadFeature.access_level &&
          uploadFeature.access_level === 'full_access') ||
        (uploadFeature &&
          uploadFeature.access_level &&
          uploadFeature.access_level === 'restricted_access')
      );
    },
    isComplyLimitedAccess(state) {
      const uploadFeature = state.currentUser?.features?.find(
        (ft) => ft.feature_name === 'comply',
      );
      return (
        uploadFeature &&
        uploadFeature.access_level &&
        uploadFeature.access_level === 'limited_access'
      );
    },
    isComplyLimitedAccess(state) {
      const uploadFeature = state.currentUser?.features?.find(
        (ft) => ft.feature_name === 'comply',
      );
      return (
        uploadFeature &&
        uploadFeature.access_level &&
        uploadFeature.access_level === 'limited_access'
      );
    },
    isResdesignLimittedAccess(state) {
      const uploadFeature = state.currentUser?.features?.find(
        (ft) => ft.feature_name === 'redesign',
      );
      return (
        uploadFeature &&
        uploadFeature.access_level &&
        (uploadFeature.access_level === 'limited_access' ||
          uploadFeature.access_level === 'full_access')
      );
    },
    isGenerateFullAccess(state) {
      const generateFeature = state.currentUser?.features?.find(
        (ft) => ft.feature_name === 'slide-generate',
      );
      return (
        (generateFeature &&
          generateFeature.enabled &&
          generateFeature.access_level &&
          generateFeature.access_level === 'full_access') ||
        (generateFeature &&
          generateFeature.access_level &&
          generateFeature.access_level === 'restricted_access')
      );
    },
    isGenerateLivePromptsFullAccess(state) {
      const generateFeature = state.currentUser?.features?.find(
        (ft) => ft.feature_name === 'ag_live_prompts',
      );
      return (
        (generateFeature &&
          generateFeature.enabled &&
          generateFeature.access_level &&
          generateFeature.access_level === 'full_access') ||
        (generateFeature &&
          generateFeature.access_level &&
          generateFeature.access_level === 'restricted_access')
      );
    },
    isPrezentationCommentFullAccess(state) {
      const prezentationCommentFeature = state.currentUser?.features?.find(
        (ft) => ft.feature_name === 'prezentation-comment',
      );
      return prezentationCommentFeature && prezentationCommentFeature.enabled;
    },
    isPrezComplyFullAccess(state) {
      const feature = state.currentUser?.features?.find(
        (ft) => ft.feature_name === 'prez-comply',
      );
      return feature && feature.enabled;
    },
    isBPEPrezComplyFullAccess(state) {
      const feature = state.currentUser?.features?.find(
        (ft) => ft.feature_name === 'bpl-comply',
      );
      return feature && feature.enabled;
    },
    isPrezAnonymizeFullAccess(state) {
      const feature = state.currentUser?.features?.find(
        (ft) => ft.feature_name === 'prez-anonymize',
      );
      return feature && feature.enabled;
    },
    isPrezSynthesisFullAccess(state) {
      const feature = state.currentUser?.features?.find(
        (ft) => ft.feature_name === 'prez-synthesis',
      );
      return feature && feature.enabled;
    },
    isComplyFullAccess(state) {
      const uploadFeature = state.currentUser?.features?.find(
        (ft) => ft.feature_name === 'comply',
      );
      return (
        (uploadFeature &&
          uploadFeature.enabled &&
          uploadFeature.access_level &&
          uploadFeature.access_level === 'full_access') ||
        (uploadFeature &&
          uploadFeature.access_level &&
          uploadFeature.access_level === 'restricted_access')
      );
    },
    isSynthesisFullAccess(state) {
      const synthesisFeature = state.currentUser?.features?.find(
        (ft) => ft.feature_name === 'synthesis',
      );
      return (
        (synthesisFeature &&
          synthesisFeature.enabled &&
          synthesisFeature.access_level &&
          synthesisFeature.access_level === 'full_access') ||
        (synthesisFeature &&
          synthesisFeature.access_level &&
          synthesisFeature.access_level === 'restricted_access') ||
        (synthesisFeature &&
          synthesisFeature.access_level &&
          synthesisFeature.access_level === 'limited_access')
      );
    },
    getUserVisualPreference(state) {
      if (state.currentUser.user.visualPreference.length)
        return state.currentUser.user.visualPreference[0];
      return '';
    },
    isAcceleratorsEnabled: (state) => {
      if (state.currentUser) {
        const feature = state?.currentUser?.features.find(
          (f) => f.feature_name === 'accelerators',
        );
        return feature && feature.enabled;
      }
      return false;
    },
    isEmailOutsideCompanyEnabled(state) {
      const feature = state.currentUser?.features?.find(
        (ft) => ft.feature_name === 'email-outside-company',
      );
      return feature && feature.enabled;
    },
    getTdCompletionPercentage: (state) =>
      state.tdCompletionPercentage ? state.tdCompletionPercentage : 0,
    getConfettiFlag: (state) =>
      state.showConfettiFlag ? state.showConfettiFlag : false,
    isReplaceBrandImageEnabled: (state) => {
      if (state.currentUser) {
        const feature = state?.currentUser?.features.find(
          (f) => f.feature_name === 'replace_image_brand',
        );
        return feature && feature.enabled;
      }
      return false;
    },
    allowAgProTips: (state) => {
      if (state.currentUser) {
        const allowAgProTipsFeature = state?.currentUser?.features?.find(
          (ft) => ft.feature_name === 'ag_pro_tips',
        );
        return allowAgProTipsFeature && allowAgProTipsFeature.enabled;
      }
      return false;
    },
    allowAgDayZeroPopup: (state) => {
      if (state.currentUser) {
        const allowAgDayZeroPopup = state?.currentUser?.features?.find(
          (ft) => ft.feature_name === 'ag_zero_day_popup',
        );
        return allowAgDayZeroPopup && allowAgDayZeroPopup.enabled;
      }
      return false;
    },
    allowReplaceImageOverall: (state) => {
      if (state.currentUser) {
        const feature = state?.currentUser?.features.find(
          (f) => f.feature_name === 'replace_image_overall',
        );
        if (feature) {
          return feature?.enabled;
        }
        return false;
      }
      return false;
    },
    allowReplaceIconOverall: (state) => {
      if (state.currentUser) {
        const repIconOverallFeature = state?.currentUser?.features?.find(
          (ft) => ft.feature_name === 'replace_icon_overall',
        );
        return repIconOverallFeature && repIconOverallFeature.enabled;
      }
      return false;
    },
    allowReplaceImageTabs: (state) => {
      if (state.currentUser) {
        const repImgAdobeLibrary = state?.currentUser?.features?.find(
          (ft) => ft.feature_name === 'replace_image_adobe_library',
        );
        const repImgBrand = state?.currentUser?.features?.find(
          (ft) => ft.feature_name === 'replace_image_brand',
        );
        const repImgUpload = state?.currentUser?.features?.find(
          (ft) => ft.feature_name === 'replace_image_custom_upload',
        );
        return (
          (repImgAdobeLibrary && repImgAdobeLibrary.enabled) ||
          (repImgBrand && repImgBrand.enabled) ||
          (repImgUpload && repImgUpload.enabled)
        );
      }
    },
    allowReplaceIconTabs: (state) => {
      if (state.currentUser) {
        const repIconAdobeLibrary = state?.currentUser?.features?.find(
          (ft) => ft.feature_name === 'replace_icon_brand_library',
        );
        const repIconBrand = state?.currentUser?.features?.find(
          (ft) => ft.feature_name === 'replace_icon_prezent_library',
        );
        const repIconUpload = state?.currentUser?.features?.find(
          (ft) => ft.feature_name === 'replace_icon_user_uploaded_icons',
        );

        return (
          (repIconAdobeLibrary && repIconAdobeLibrary.enabled) ||
          (repIconBrand && repIconBrand.enabled) ||
          (repIconUpload && repIconUpload.enabled)
        );
      }
    },
    getUserDownloadPreference: (state) =>
      state.userDownloadPreference ? state.userDownloadPreference : '',
  },
  mutations: {
    SET_USER_DOWNLOAD_PREFERENCE(state, val) {
      state.userDownloadPreference = val;
    },
    SET_SLIDE_RECENT_QUERIES_ON_MOUNT(state, queries) {
      let objectArray = [];
      if (queries?.length > 0) {
        objectArray = queries.map((item) => ({
          category: '',
          type: 'Slides',
          name: item,
        }));
      }
      state.recentSlideSearches = [...objectArray];
    },
    SET_BPE_SLIDE_RECENT_QUERIES_ON_MOUNT(state, queries) {
      let objectArray = [];
      if (queries?.length > 0) {
        objectArray = queries.map((item) => ({
          category: '',
          type: 'Slides',
          name: item,
        }));
      }
      state.recentBPESlideSearches = [...objectArray];
    },
    SET_LEARN_RECENT_QUERIES_ON_MOUNT(state, queries) {
      let objectArray = [];
      if (queries?.length > 0) {
        objectArray = queries;
      }
      state.currentUser.user.recentLearnSearches = objectArray;
    },
    UPDATE_RECENT_SLIDE_QUERIES(state, query) {
      if (state.recentSlideSearches.length >= 3) {
        state.recentSlideSearches.pop();
      }
      state.recentSlideSearches.unshift({
        ...query,
      });
    },
    UPDATE_RECENT_BPE_SLIDE_QUERIES(state, query) {
      if (state.recentBPESlideSearches.length >= 3) {
        state.recentBPESlideSearches.pop();
      }
      state.recentBPESlideSearches.unshift({
        ...query,
      });
    },
    UPDATE_RECENT_LEARN_QUERIES(state, query) {
      if (state.currentUser.user.recentLearnSearches.length >= 3) {
        state.currentUser.user.recentLearnSearches.pop();
      }
      state.currentUser.user.recentLearnSearches.unshift(query);
    },
    SET_SLIDES_AUDIENCE_SELECTED(state, data) {
      state.slidesAudienceSelected = data;
    },
    UPDATE_RECENT_SLIDE_QUERIESS(state, query) {
      const recentSlideSearches = state.recentSlideSearches.filter(
        (item) => item.name !== query.name,
      );
      recentSlideSearches.unshift({
        ...query,
      });
      state.recentSlideSearches = [...recentSlideSearches];
    },
    UPDATE_RECENT_BPE_SLIDE_QUERIESS(state, query) {
      const recentBPESlideSearches = state.recentBPESlideSearches.filter(
        (item) => item.name !== query.name,
      );
      recentBPESlideSearches.unshift({
        ...query,
      });
      state.recentBPESlideSearches = [...recentBPESlideSearches];
    },
    UPDATE_RECENT_LEARN_QUERIESS(state, query) {
      const recentLearnSearches =
        state.currentUser.user.recentLearnSearches.filter(
          (item) => item !== query,
        );
      recentLearnSearches.unshift(query);
      state.currentUser.user.recentLearnSearche = [...recentLearnSearches];
    },
    SET_UPLOAD_FLOW_CTA_ORIGIN(state, uploadFlowCTAOrigin) {
      state.uploadFlowCTAOrigin = uploadFlowCTAOrigin;
    },
    SET_LEFT_SIDEBAR_STATUS(state, status) {
      state.leftSideBarStatus = status;
    },
    SET_STORYLINES_ON_LOGIN(state, storylines) {
      state.storylines = storylines;
    },
    SET_PREZENTATION_IN_PROCESS(state, prezentationInProcess) {
      state.prezentationInProcess = prezentationInProcess;
    },
    SET_START_LOADING_SLIDES_FLAG(state, startLoadingSlidesFlag) {
      state.startLoadingSlidesFlag = startLoadingSlidesFlag;
    },
    SET_TYPESENSE_REALTIME_AUDIENCES(state, { audiences, self }) {
      state.typesenseRealtimeAudiences = audiences;
      if (self) {
        state.typesenseSelfAudience = self;
      }
      state.typesenseAudiencesLoaded = true;
    },
    SET_FINGERPRINT_ADDITIONAL_DETAILS(state, data) {
      state.fingerPrintAdditionalData = data;
    },
    TOGGLE_FAVORITE_BEFORE_SYNC_WITH_DB(state, currUserFavs) {
      state.currentUserFavorites = currUserFavs;
    },
    SET_CURR_SEARCHED_PERSON(state, currSearchedPerson) {
      state.currSearchedPerson = currSearchedPerson;
    },
    RESET_CURR_SEARCHED_PERSON(state, resetValue) {
      state.currSearchedPerson = resetValue;
    },
    setSearchTerms(state, terms) {
      state.searchTerms = terms;
    },
    addCompanyDomain(state, domains) {
      state.companyDomains = domains;
    },
    setCompanyData(state, data) {
      state.companyData = data;
    },
    addSearchTerms(state, term) {
      if (state.searchTerms) {
        state.searchTerms.push(term);
      } else {
        state.searchTerms = [term];
      }
    },
    setSearchProgress(state, value) {
      state.searchProgress = value;
    },
    setCurrentUserFavorites(state, value) {
      state.currentUserFavorites = value;
    },
    setAssetFolder(state, folder) {
      state.assetFolder = folder;
    },
    setShownPaymentDialog(state, val) {
      state.shownPaymentDialog = val;
    },
    setAvailableAddOns(state, plans) {
      state.availableAddOns = plans;
    },
    setAvailablePlans(state, plans) {
      state.availablePlans = plans;
    },
    setCurrentTheme(state, theme) {
      state.currentTheme = theme;
    },
    SET_OP_FUND_LOW(state, isFundLow) {
      state.isOPFundLow = isFundLow;
    },
    SET_OP_LIMITED_BANNER(state, content) {
      state.opLimitedBanner = content;
    },
    SET_OPTICKET_UNREAD_MESSAGE_DATA(state, data) {
      state.opUnreadMessageData = data;
    },
    setThemes(state, themes) {
      themes.forEach((theme) => {
        theme.thumbnailURL = `/assets/img/themes/${theme.code}.jpg`;
        if (theme.thumbnail) {
          getFileURL(null, `public/${theme.thumbnail}`, null, 'storage').then(
            (s3url) => {
              theme.thumbnailURL = s3url;
            },
          );
          getFileURL(null, `public/${theme.sampleSlide}`, null, 'storage').then(
            (s3url) => {
              theme.sampleSlideURL = s3url;
            },
          );
        }
      });

      state.themes = themes;
    },
    setOnedriveFolderId(state, id) {
      state.onedriveFolderId = id;
    },
    setOnedriveAccessToken(state, token) {
      state.onedriveAccessToken = token;
    },
    setCurrentSubscription(state, subscription) {
      if (state.availablePlans && state.availablePlans.length > 0) {
        subscription.plan = state.availablePlans.find(
          (item) => item.id === subscription.planID,
        );
      }
      state.currentActiveSubscription = subscription;
    },
    setIsTrialExpired(state, isTrialExpired) {
      state.isTrialExpired = isTrialExpired;
    },
    setTrialDaysLeft(state, trialDaysLeft) {
      state.trialDaysLeft = trialDaysLeft;
    },
    setAssetVotes(state, votes) {
      state.assetVotes = votes;
    },

    setSessionKey(state, key) {
      state.sessionKey = key;
    },
    setCurrentPrivateAudience(state, audience) {
      state.currentPrivateAudience = audience;
    },
    setCurrentTestAudience(state, audience) {
      state.currentTestAudience = audience;
    },
    setPrivateAudiencePreferences(state, { pref, preferences }) {
      if (pref.fingerprint) {
        const fp = fpTypes.find(
          (fprint) => fprint.title === String(pref.fingerprint).toLowerCase(),
        );
        state.currentPrivateAudience.fingerPrint = fp.title;
        state.currentPrivateAudience.bio = fp.description;
        state.currentPrivateAudience.preferences = fp.preferences;
        state.currentPrivateAudience.avatar = fp.logo;
        state.currentPrivateAudience.visualPreference = pref.visualPreference;
        state.currentPrivateAudience.dataPreference = pref.dataPreference;
        state.currentPrivateAudience.fingerPrint = pref.fingerprint;
        state.currentPrivateAudience.industry = pref.industrySelected;
        state.currentPrivateAudience.jobFunction = pref.functionSelected;
        state.currentPrivateAudience.subDivision = pref.groupSelected;
        state.currentPrivateAudience.region = pref.regionSelected;
        state.currentPrivateAudience.jobTitle = pref.jobTitleSelected;
        state.currentPrivateAudience.fpCompletionDate = pref.fpCompletionDate;
        const userPrefreneces = frameUserPreferencesResponse(preferences);
        updatePrivateAudience({
          id: pref.audienceID,
          userID: state.currentUser.user.id,
          visualPreference: [pref.visualPreference],
          dataPreference: [pref.dataPreference],
          storyPreference: [pref.storyPreference],
          fingerPrint: pref.fingerprint,
          industry: pref.industrySelected,
          jobFunction: pref.functionSelected,
          subDivision: pref.groupSelected,
          region: pref.regionSelected,
          jobTitle: pref.jobTitleSelected,
          fpCompletionDate: pref.fpCompletionDate,
          // active: true,
          ...userPrefreneces,
        }).then(
          (value) => {
            console.log(value.privateAudience);
            // state.currentPrivateAudience = value.data.updatePrivateAudience;
          },
          (reason) => {
            console.error(reason); // Error!
          },
        );
      }
    },
    setPrivateAudiencePersonalPreferences(state, pref) {
      console.log(state);
      updatePrivateAudience({
        id: pref.id,
        userID: state.currentUser.user.id,
        personalPreference: pref.personalPreference.map((el) => el.title),
      }).then(
        (value) => {
          console.log(value.privateAudience);
          state.currentPrivateAudience = {
            ...state.currentPrivateAudience,
            personalPreference: value.privateAudience.personalPreference,
          };
        },
        (reason) => {
          console.error(reason); // Error!
        },
      );
    },
    persistCurrentTestAudiencePersonalPreference(state) {
      updateAudienceFingerPrint({
        uuid: state.currentTestAudience.uuid,
        id: state.currentTestAudience.id,
        personalPreference: state.currentTestAudience.personalPreference,
      }).then(
        (value) => {
          console.log(value);
        },
        (reason) => {
          console.error(reason); // Error!
        },
      );
    },
    persistCurrentTestAudience(state) {
      updateAudience({
        id: state.currentTestAudience.id,
        fingerPrint:
          state.currentTestAudience.fingerPrint.charAt(0).toUpperCase() +
          state.currentTestAudience.fingerPrint.slice(1),
        visualPreference: [state.currentTestAudience.visualPreference],
        storyPreference: [state.currentTestAudience.storyPreference],
        dataPreference: [state.currentTestAudience.dataPreference],
      }).then(
        (value) => {
          console.log(value.data.updateAudience);
        },
        (reason) => {
          console.error(reason); // Error!
        },
      );
    },

    SET_USER_ASSOCIATED_COMPANIES(state, companies) {
      state.userAssociatedCompanies = companies;
    },
    SET_SHARED_PRIVATE_AUDIENCE_BY_USER(state, audience) {
      state.sharedPrivateAudienceListByUser = audience.map((e) => ({
        ...e,
        shared: true,
        audienceType: 'private_audience',
      }));
    },
    SET_SHARED_AUDIENCE_BY_USER(state, audience) {
      state.sharedAudienceListByUser = audience.map((e) => ({
        ...e,
        shared: true,
        audienceType: 'audience',
      }));
    },
    SET_SHARED_USER(state, audience) {
      audience.forEach((user) => {
        user.profileImageUrl = '';
        if (user.profileImage && !user.profileImageUrl) {
          getFileURL(null, `public/${user.profileImage}`, null, 'storage').then(
            (s3url) => {
              user.profileImageUrl = s3url;
            },
          );
        }
      });
      state.sharedUserListByUser = audience;
    },
    setCurrentUser(state, currentUserInfo) {
      // get presigned profile image and set
      if (
        currentUserInfo.user?.profileImage &&
        !currentUserInfo.user?.profileImageUrl
      ) {
        getFileURL(
          null,
          `public/${currentUserInfo.user.profileImage}`,
          null,
          'storage',
        ).then((s3url) => {
          currentUserInfo.user.profileImageUrl = s3url;
        });
      }
      let obj = {};
      currentUserInfo.featureLimits.forEach((item) => {
        obj[item.feature] = { ...item };
      });
      currentUserInfo.featureLimitsObject = obj;

      if (state.currentUser.hasOwnProperty('userIp')) {
        const userIp = state.currentUser.userIp;
        state.currentUser = [];
        state.currentUser = { ...currentUserInfo, userIp };
      } else {
        state.currentUser = currentUserInfo;
      }
    },
    SET_TD_COMPLETION_PERCENTAGE(state, value) {
      state.tdCompletionPercentage = value;
    },
    SET_CONFETTI_FLAG(state) {
      if (!state.showConfettiFlag) {
        state.showConfettiFlag = true;
        setTimeout(() => {
          state.showConfettiFlag = false;
        }, 3000);
      }
    },
    setLimitsInfo(state, limitsInfo = []) {
      state.limitsInfo = limitsInfo;
      let featuresObj = {};
      let obj = {};

      state.currentUser.features?.forEach((item) => {
        let isFullAccess = item?.access_level === 'full_access';
        if (item.feature_name === 'slide-generate') {
          obj.generate = { ...item, feature: item.feature_name, isFullAccess };
          featuresObj.generate = {
            ...item,
            feature: item.feature_name,
            isFullAccess,
          };
        } else if (item.feature_name === 'comply') {
          obj.comply = { ...item, feature: item.feature_name, isFullAccess };
          featuresObj.comply = {
            ...item,
            feature: item.feature_name,
            isFullAccess,
          };
        } else if (item.feature_name === 'overnight-prezentations') {
          obj.op = { ...item, feature: item.feature_name, isFullAccess };
          featuresObj.op = {
            ...item,
            feature: item.feature_name,
            isFullAccess,
          };
        }
        obj[item.feature_name] = {
          ...item,
          feature: item.feature_name,
          isFullAccess,
        };
        featuresObj[item.feature_name] = {
          ...item,
          feature: item.feature_name,
          isFullAccess,
        };
      });
      limitsInfo?.forEach((item) => {
        let originalFeatureData = state.currentUser?.featureLimitsObject?.[
          item.feature
        ] || { limits: 0 };
        let accessLevel = featuresObj?.[item.feature]?.access_level;
        let isFullAccess = accessLevel === 'full_access';
        obj[item.feature] = {
          ...item,
          ...originalFeatureData,
          access_level: accessLevel,
          isFullAccess,
          isLimitCrossed: originalFeatureData.limits <= item.usage_count,
          remainingLimit: originalFeatureData.limits - item.usage_count,
        };
      });
      state.currentUser?.featureLimits?.forEach((item) => {
        let accessLevel = featuresObj?.[item.feature]?.access_level;
        let isFullAccess = accessLevel === 'full_access';

        obj[item.feature] = {
          ...item,
          access_level: accessLevel,
          isFullAccess,
          isLimitCrossed: false,
          remainingLimit: item.limits,
          ...(obj[item.feature] || {}),
        };
      });
      state.limitsInfoObj = JSON.parse(JSON.stringify(obj));
    },
    SET_UPGRADE_POPUP(state, data) {
      state.showUpgradePopup = data.upgradePopup;
      state.upgradePopupType = data.popupType;
    },
    SET_TEMPLATE_TOOL_PLACEHOLDER(state, data) {
      state.quickSelectionTemplates = data;
    },
    setCurrentTestAudienceFingerprint(state, { fingerprint }) {
      if (fingerprint) {
        const fp = fpTypes.find(
          (fprint) => fprint.title === String(fingerprint).toLowerCase(),
        );
        state.currentTestAudience.fingerPrint = fp.title;
        state.currentTestAudience.bio = fp.description;
        state.currentTestAudience.preferences = fp.preferences;
        state.currentTestAudience.avatar = fp.logo;
      } else {
        state.currentTestAudience.fingerPrint = 'Director';
        state.currentTestAudience.avatar = '/assets/img/lion.png';
      }
    },
    async setCurrentUserFingerprint(state, { fingerprint, preferences }) {
      state.waitForFP = true;
      if (fingerprint !== undefined && fingerprint !== null) {
        const fp = fpTypes.find(
          (fprint) => fprint.title.toLowerCase() === fingerprint.toLowerCase(),
        );
        Vue.set(state.currentUser, 'preferences', fp.preferences);
        Vue.set(state.currentUser, 'fingerPrint', fp.title);
        Vue.set(state.currentUser, 'bio', fp.description);
        Vue.set(state.currentUser, 'avatar', fp.logo);
        Vue.set(
          state.currentUser.user,
          'industry',
          preferences.industrySelected,
        );
        Vue.set(
          state.currentUser.user,
          'jobFunction',
          preferences.functionSelected,
        );
        Vue.set(
          state.currentUser.user,
          'subDivision',
          preferences.groupSelected,
        );
        Vue.set(state.currentUser.user, 'region', preferences.regionSelected);
        Vue.set(
          state.currentUser.user,
          'jobTitle',
          preferences.jobTitleSelected,
        );
        Vue.set(
          state.currentUser.user,
          'fpCompletionDate',
          preferences.fpCompletionDate,
        );
        /* state.currentUser.fingerPrint = fp.title;
                state.currentUser.bio = fp.description;
                state.currentUser.preferences = fp.preferences;
                state.currentUser.avatar = fp.logo; */
        state.waitForFP = false;
        console.log('***FP is ready***');
        console.log(state.currentUser);
        console.log(state.waitForFP);
        const userPrefreneces = frameUserPreferencesResponse(preferences);
        const userPayload = {
          fingerPrint:
            fingerprint.charAt(0).toUpperCase() + fingerprint.slice(1),
          visualPreference: state.currentUser.user.visualPreference,
          storyPreference: state.currentUser.user.storyPreference,
          dataPreference: state.currentUser.user.dataPreference,
          industry: preferences.industrySelected,
          jobFunction: preferences.functionSelected,
          subDivision: preferences.groupSelected,
          region: preferences.regionSelected,
          jobTitle: preferences.jobTitleSelected,
          fpCompletionDate: preferences.fpCompletionDate,
          ...userPrefreneces,
        };
        usersApi.methods.updateUserProfile(userPayload).then(
          (value) => {
            state.currentUser.user.fingerPrint = value.fingerPrint;
            state.currentUser.user.industry = value.industry;
            state.currentUser.user.jobFunction = value.jobFunction;
            state.currentUser.user.subDivision = value.subDivision;
            state.currentUser.user.region = value.region;
            state.currentUser.user.jobTitle = value.jobTitle;
            state.currentUser.user.fpCompletionDate = value.fpCompletionDate;
            console.log('updated FP bio and logo');
            MatomoAnalyticsHandler.fingerPrintRetook(value);
          },
          (reason) => {
            console.error(reason); // Error!
          },
        );
      } else {
        state.currentUser.user.fingerPrint = 'Director';
        state.currentUser.user.avatar = '/assets/img/lion.png';
      }
    },
    setCurrentTestAudienceVisualpreference(state, visualPreference) {
      state.currentTestAudience.visualPreference = visualPreference;
      console.log(
        `computed visualPreference=${state.currentTestAudience.visualPreference}`,
      );
    },
    setVisualpreference(state, visualPreference) {
      state.currentUser.user.visualPreference = [];
      state.currentUser.user.visualPreference.push(visualPreference);
      console.log(
        `computed visualPreference=${state.currentUser.user.visualPreference}`,
      );
    },
    setCurrentTestAudienceStorypreference(state, storyPreference) {
      state.currentTestAudience.storyPreference = storyPreference;
      console.log(
        `computed storyPreference=${state.currentTestAudience.storyPreference}`,
      );
    },
    setStorypreference(state, storyPreference) {
      state.currentUser.user.storyPreference = [];
      state.currentUser.user.storyPreference.push(storyPreference);
      console.log(
        `computed storyPreference=${state.currentUser.user.storyPreference}`,
      );
    },
    setCurrentTestAudienceDatapreference(state, dataPreference) {
      state.currentTestAudience.dataPreference = dataPreference;
      console.log(
        `computed dataPreference${state.currentTestAudience.dataPreference}`,
      );
    },
    setDatapreference(state, dataPreference) {
      state.currentUser.user.dataPreference = [];
      state.currentUser.user.dataPreference.push(dataPreference);
      console.log(
        `computed dataPreference${state.currentUser.user.dataPreference}`,
      );
    },
    setCurrentTestAudiencePersonalPreferences(state, personalPreferences) {
      state.currentTestAudience.personalPreference = personalPreferences;
    },
    setCurrentUserPersonalPreferences(state, personalPreferences) {
      state.currentUser.user.personalPreference = personalPreferences;
    },
    SET_COMPANY_DETAILS(state, companyDetails) {
      state.companyDetails = companyDetails;
    },
    SET_COMPANY_DETAILS(state, companyDetails) {
      state.companyDetails = companyDetails;
    },

    SET_SAVED_QUERY_PAYLOD(state, savedQueryPayload) {
      state.hybridSearchQueryPayload = savedQueryPayload;
    },

    RESET_STATE(state) {
      state = {
        ...usersInitialState,
      };
    },
    SET_UPGRADE_BANNER(state, data) {
      state.showUpgradeBanner = data;
    },
    SET_BANNER_ACTIVE(state, data) {
      state.isBannerActive = data;
    },
  },
  actions: {
    setUserDownloadPreference({ commit }, val) {
      commit('SET_USER_DOWNLOAD_PREFERENCE', val);
    },
    setSlideRecentQueriesOnMount({ commit }, queries) {
      commit('SET_SLIDE_RECENT_QUERIES_ON_MOUNT', queries);
    },
    setBPESlideRecentQueriesOnMount({ commit }, queries) {
      commit('SET_BPE_SLIDE_RECENT_QUERIES_ON_MOUNT', queries);
    },
    setLearnRecentQueriesOnMount({ commit }, queries) {
      commit('SET_LEARN_RECENT_QUERIES_ON_MOUNT', queries);
    },
    updateRecentSlideQueries({ commit, state }, query) {
      const data = state.recentSlideSearches.find(
        (ele) => ele.name === query.name,
      );
      if (!data) {
        commit('UPDATE_RECENT_SLIDE_QUERIES', query);
      } else {
        commit('UPDATE_RECENT_SLIDE_QUERIESS', query);
      }
    },
    updateRecentBPESlideQueries({ commit, state }, query) {
      const data = state.recentBPESlideSearches.find(
        (ele) => ele.name === query.name,
      );
      if (!data) {
        commit('UPDATE_RECENT_BPE_SLIDE_QUERIES', query);
      } else {
        commit('UPDATE_RECENT_BPE_SLIDE_QUERIESS', query);
      }
    },
    updateRecentLearnQueries({ commit, state }, query) {
      const data = state.currentUser.user.recentLearnSearches.find(
        (ele) => ele === query,
      );
      if (!data) {
        commit('UPDATE_RECENT_LEARN_QUERIES', query);
      } else {
        commit('UPDATE_RECENT_LEARN_QUERIESS', query);
      }
    },
    setSlidesAudienceSelected({ commit }, data) {
      commit('SET_SLIDES_AUDIENCE_SELECTED', data);
    },
    setTDCompletionPercentage({ commit }, percentage) {
      commit('SET_TD_COMPLETION_PERCENTAGE', percentage);
    },
    showConfetti({ commit }) {
      commit('SET_CONFETTI_FLAG');
    },
    setUploadFlowCTAOrigin({ commit }, uploadFlowCTAOrigin) {
      commit('SET_UPLOAD_FLOW_CTA_ORIGIN', uploadFlowCTAOrigin);
    },
    setPrezentationInProcess({ commit }, prezentationInProcess) {
      commit('SET_PREZENTATION_IN_PROCESS', prezentationInProcess);
    },
    setLeftSideBarStatus({ commit }, status) {
      commit('SET_LEFT_SIDEBAR_STATUS', status);
    },
    resetState({ commit }) {
      commit('RESET_STATE', null);
    },
    setStartLoadingSlidesFlag({ commit }, startLoadingSlidesFlag) {
      commit('SET_START_LOADING_SLIDES_FLAG', startLoadingSlidesFlag);
    },
    toggleFavoriteBeforeSyncWithDB(
      { commit, state },
      { action, userId, favData },
    ) {
      let favs = [];
      if (action === 'createFavorite') {
        favs = [...state.currentUserFavorites, favData];
        commit('TOGGLE_FAVORITE_BEFORE_SYNC_WITH_DB', favs);
      } else if (action === 'deleteFavorite') {
        favs = state.currentUserFavorites.filter(
          (fav) => fav.id !== favData.id,
        );
        commit('TOGGLE_FAVORITE_BEFORE_SYNC_WITH_DB', favs);
      }
    },
    async setCurrSearchedPerson({ commit, state }, { id, type }) {
      try {
        console.log('---------', type);
        const fingerprint = await getFingerprint(id, type);
        commit('SET_CURR_SEARCHED_PERSON', fingerprint);
      } catch (err) {
        console.log(err);
        commit('SET_CURR_SEARCHED_PERSON', null);
      }
    },
    resetCurrSearchedPerson({ commit }, resetValue) {
      commit('RESET_CURR_SEARCHED_PERSON', resetValue);
    },
    addCompanyDomain(context, domains) {
      context.commit('addCompanyDomain', domains);
    },
    addCompanyData(context, companyData) {
      context.commit('setCompanyData', companyData);
    },
    addSearchTerms(context, term) {
      context.commit('addSearchTerms', term);
    },
    setSearchProgress(context, val) {
      context.commit('setSearchProgress', val);
    },
    setFingerPrintAdditionalDetails(context, data) {
      context.commit('SET_FINGERPRINT_ADDITIONAL_DETAILS', data);
    },
    fetchCurrentUserFavorites(context, userId) {
      return new Promise(async (resolve, reject) => {
        const favorites = [];

        // define recursive function to handle nextToken
        const getFavoritesByUser = async (nextToken) => {
          const queryObject = nextToken
            ? {
                query: favoriteByUser,
                variables: { userID: userId, nextToken },
              }
            : { query: favoriteByUser, variables: { userID: userId } };

          try {
            const favoriteData = await API.graphql(queryObject);

            favorites.push(...favoriteData.data.favoriteByUser.items);

            if (favoriteData.data.favoriteByUser.nextToken) {
              await getFavoritesByUser(
                favoriteData.data.favoriteByUser.nextToken,
              );
            }
            resolve(favorites);
          } catch (err) {
            reject(err.message);
          }
        };

        if (userId) {
          await getFavoritesByUser(null);
        }

        context.commit('setCurrentUserFavorites', favorites);
        resolve(favorites);
      });
    },
    setSharedPrivateAudienceByUser(context, audienceList) {
      context.commit('SET_SHARED_PRIVATE_AUDIENCE_BY_USER', audienceList);
    },
    setSharedAudienceByUser(context, audienceList) {
      context.commit('SET_SHARED_AUDIENCE_BY_USER', audienceList);
    },
    setSharedUserByUser(context, audienceList) {
      context.commit(
        'SET_SHARED_USER',
        audienceList.map((e) => {
          return {
            ...e,
            audienceType: 'user',
            shared: true,
          };
        }),
      );
    },
    setAssetFolder(context, val) {
      context.commit('setAssetFolder', val);
    },
    setShownPaymentDialog(context, val) {
      context.commit('setShownPaymentDialog', val);
    },
    async toggleUserAGPromptSuggestions(context) {
      const { commit, state } = context;
      const userData = {
        performIncrements: {
          hideAgPromptSuggestions:
            state.currentUser?.user?.hideAgPromptSuggestions === 1 ? -1 : 1,
        },
      };
      const user = {
        ...state.currentUser.user,
        hideAgPromptSuggestions:
          state.currentUser.user.hideAgPromptSuggestions === 1 ? 0 : 1,
      };
      commit('setCurrentUser', { ...state.currentUser, user });
      await usersApi.methods
        .updateUserProfile(userData)
        .then(() => {})
        .catch((err) => console.log(err));
    },
    async setCurrentTheme(context, theme) {
      const { commit, state } = context;
      if (
        state.currentUser &&
        state.currentUser?.user?.role.toLowerCase() === 'trialuser' &&
        state.currentUser?.company?.isNonBranded &&
        state.currentUser?.designerTheme?.jobSubmitted &&
        state.currentUser?.user?.designerBanner >= 1 &&
        state.currentUser?.designerTheme?.status === 'completed' &&
        theme?.isUserUploaded
      ) {
        const userData = {
          performIncrements: {
            designerPopupSeen: 3,
            designerCompleteBanner: 1,
          },
        };
        await usersApi.methods
          .updateUserProfile(userData)
          .then((updatedUser) => {
            const userInfo = state.currentUser;
            userInfo.user = updatedUser;
            commit('setCurrentUser', userInfo);
          })
          .catch((err) => console.log(err));
      }

      commit('setCurrentTheme', theme);
    },
    setOPFundLow(context, isFundLow) {
      context.commit('SET_OP_FUND_LOW', isFundLow);
    },
    setOPLimtedBanner(context, content) {
      context.commit('SET_OP_LIMITED_BANNER', content);
    },
    setOnedriveFolderId(context, id) {
      context.commit('setOnedriveFolderId', id);
    },
    setOnedriveAccessToken(context, token) {
      context.commit('setOnedriveAccessToken', token);
    },
    setAssetVotes(context, votes) {
      context.commit('setAssetVotes', votes);
    },
    setSessionKey(context, key) {
      context.commit('setSessionKey', key);
    },
    setCurrentTestAudience(context, audience) {
      context.commit('setCurrentTestAudience', audience);
    },
    setCurrentUser(context, currentUserInfo) {
      return new Promise((resolve) => {
        context.commit('setCurrentUser', currentUserInfo);

        context.commit(
          'setIsTrialExpired',
          currentUserInfo.subscriptions[0].isExpired,
        );
        // context.commit('setCurrentTheme', currentUserInfo.theme);
        if (currentUserInfo.subscriptions?.length) {
          context.commit(
            'setCurrentSubscription',
            currentUserInfo.subscriptions[0],
          );
        }
        resolve();
      });
    },
    setLimitData(context, limitsInfo) {
      context.commit('setLimitsInfo', limitsInfo);
    },
    setUpgradePopup(context, data) {
      context.commit('SET_UPGRADE_POPUP', data);
    },
    setTemplateToolPlaceHolder(context, data) {
      context.commit('SET_TEMPLATE_TOOL_PLACEHOLDER', data);
    },
    setTypesenseRealtimeAudiences(context, payload) {
      context.commit('SET_TYPESENSE_REALTIME_AUDIENCES', payload);
    },
    setCurrentSubscription(context, subscription) {
      context.commit('setCurrentSubscription', subscription);
    },
    loadAvailableAddOns(context, user) {
      // removing as this graphql query is no longer used
      context.commit('setAvailableAddOns', null);
      // return new Promise((resolve, reject) => {
      //   console.log(user);
      //   API.graphql({ query: listAddOns })
      //     .then((addOnsData) => {
      //       const addOns = [];
      //       if (
      //         addOnsData.data.listAddOns.items &&
      //         addOnsData.data.listAddOns.items.length > 0
      //       ) {
      //         addOnsData.data.listAddOns.items.forEach((item) => {
      //           if (item.status.toLowerCase() === 'active') {
      //             addOns.push(item);
      //           }
      //         });
      //       }
      //       console.log('***Available addons****');
      //       console.log(addOns);

      //       resolve(addOns);
      //     })
      //     .catch((err) => {
      //       console.log(err.message);
      //       reject(err);
      //     });
      // });
    },
    loadAvailablePlans(context, user) {
      return new Promise((resolve, reject) => {
        API2.get('/plans')
          .then((planData) => {
            const plans = [];
            if (
              planData.data.listPlans.items &&
              planData.data.listPlans.items.length > 0
            ) {
              planData.data.listPlans.items.forEach((item) => {
                if (item.status.toLowerCase() === 'active') {
                  plans.push(item);
                }
              });
            }
            context.commit('setAvailablePlans', plans);
            resolve(plans);
          })
          .catch((err) => {
            console.log(err);
            reject(err);
          });
      });
    },
    setSearchTerms(context, terms) {
      context.commit('setSearchTerms', terms);
    },
    loadCurrentSubscription(context, user) {
      return new Promise((resolve, reject) => {
        if (!user) reject();
        if (user.subscriptions.length) {
          context.commit('setCurrentSubscription', user.subscriptions[0]);
          resolve(user.subscriptions[0]);
        } else {
          // context.commit('setIsTrialExpired', true);
          resolve({});
        }
      });
    },
    setOPTicketUnreadMessageData(context, message) {
      context.commit('SET_OPTICKET_UNREAD_MESSAGE_DATA', message);
    },

    setFilteredThemes(context, themes) {
      context.commit('setThemes', themes);
    },
    loadThemes(context, user) {
      return new Promise((resolve, reject) => {
        let themes = [];

        API.graphql({ query: listThemess })
          .then((themeData) => {
            themes = themeData.data.listThemess.items.filter(
              (item) =>
                /* item.teamID === user.teamID && item.organizationID === user.organizationID && */ item.companyID ===
                user.companyID,
            );

            context.commit('setThemes', themes);
            resolve(themes);
          })
          .catch((err) => {
            console.log(err.message);
            reject(err);
          });
      });
    },
    async setUserAssociatedCompanies(context, companyId) {
      const path = '/association/getall';
      return new Promise((resolve, reject) => {
        let associatedCompanies = [];
        API2.post(path, { company_id: `${companyId}` })
          .then((response) => {
            associatedCompanies = response.associatedCompanies;
            context.commit(
              'SET_USER_ASSOCIATED_COMPANIES',
              associatedCompanies,
            );
            resolve(associatedCompanies);
          })
          .catch((err) => {
            console.log(err.message);
            reject(err);
          });
      });
    },
    setCurrentPrivateAudience(context, audience) {
      context.commit('setCurrentPrivateAudience', audience);
    },
    setPrivateAudiencePersonalPreferences(context, pref) {
      context.commit('setPrivateAudiencePersonalPreferences', pref);
    },
    setCurrentTestAudiencePersonalPreferences(context, personalPreferences) {
      context.commit(
        'setCurrentTestAudiencePersonalPreferences',
        personalPreferences.map((el) => el.title),
      );
      context.commit('persistCurrentTestAudiencePersonalPreference');
    },
    setCurrentUserPersonalPreferences(context, personalPreferences) {
      const userPayload = {
        personalPreference: [...personalPreferences.map((el) => el.title)],
      };
      console.log(userPayload, 'payload');
      return new Promise((resolve, reject) => {
        usersApi.methods.updateUserProfile(userPayload).then(
          () => {
            console.log('success call');
            context.commit(
              'setCurrentUserPersonalPreferences',
              personalPreferences.map((el) => el.title),
            );
            resolve(true);
          },
          (reason) => {
            reject(reason); // Error!
          },
        );
      });
    },

    setUserLearningModule(context, number) {
      context.commit('setUserLearningModule', number);
    },
    setCurrentUserFingerprint(context, preferences) {
      return new Promise((resolve) => {
        let fingerprint = 'None';
        let visualPreference = 'None';
        let storyPreference = 'None';
        let dataPreference = 'None';
        switch (preferences.question0) {
          case 1:
            switch (preferences.question1) {
              case 1:
                switch (preferences.question2) {
                  case 1:
                    switch (preferences.question3) {
                      case 1:
                        switch (preferences.question4) {
                          case 1:
                            switch (preferences.question5) {
                              case 1:
                                visualPreference = 'Vibrant';
                                storyPreference = 'Deductive';
                                dataPreference = 'Narrative';
                                fingerprint = 'Performer';
                                break;
                              case 2:
                                visualPreference = 'Vibrant';
                                storyPreference = 'Deductive';
                                dataPreference = 'Objective';
                                fingerprint = 'Scholar';
                                break;
                            }
                            break;
                          case 2:
                            switch (preferences.question5) {
                              case 1:
                                visualPreference = 'Vibrant';
                                storyPreference = 'Deductive';
                                dataPreference = 'Narrative';
                                fingerprint = 'Performer';
                                break;
                              case 2:
                                visualPreference = 'Vibrant';
                                storyPreference = 'Deductive';
                                dataPreference = 'Narrative';
                                fingerprint = 'Performer';
                                break;
                            }
                            break;
                        }
                        break;
                      case 2:
                        switch (preferences.question4) {
                          case 1:
                            switch (preferences.question5) {
                              case 1:
                                visualPreference = 'Vibrant';
                                storyPreference = 'Inductive';
                                dataPreference = 'Narrative';
                                fingerprint = 'Navigator';
                                break;
                              case 2:
                                visualPreference = 'Vibrant';
                                storyPreference = 'Inductive';
                                dataPreference = 'Objective';
                                fingerprint = 'Director';
                                break;
                            }
                            break;
                          case 2:
                            switch (preferences.question5) {
                              case 1:
                                visualPreference = 'Vibrant';
                                storyPreference = 'Inductive';
                                dataPreference = 'Narrative';
                                fingerprint = 'Navigator';
                                break;
                              case 2:
                                visualPreference = 'Vibrant';
                                storyPreference = 'Inductive';
                                dataPreference = 'Narrative';
                                fingerprint = 'Navigator';
                                break;
                            }
                            break;
                        }
                        break;
                    }
                    break;
                  case 2:
                    switch (preferences.question3) {
                      case 1:
                        switch (preferences.question4) {
                          case 1:
                            switch (preferences.question5) {
                              case 1:
                                visualPreference = 'Vibrant';
                                storyPreference = 'Deductive';
                                dataPreference = 'Narrative';
                                fingerprint = 'Performer';
                                break;
                              case 2:
                                visualPreference = 'Vibrant';
                                storyPreference = 'Deductive';
                                dataPreference = 'Objective';
                                fingerprint = 'Scholar';
                                break;
                            }
                            break;
                          case 2:
                            switch (preferences.question5) {
                              case 1:
                                visualPreference = 'Vibrant';
                                storyPreference = 'Deductive';
                                dataPreference = 'Narrative';
                                fingerprint = 'Performer';
                                break;
                              case 2:
                                visualPreference = 'Vibrant';
                                storyPreference = 'Deductive';
                                dataPreference = 'Narrative';
                                fingerprint = 'Performer';
                                break;
                            }
                            break;
                        }
                        break;
                      case 2:
                        switch (preferences.question4) {
                          case 1:
                            switch (preferences.question5) {
                              case 1:
                                visualPreference = 'Vibrant';
                                storyPreference = 'Inductive';
                                dataPreference = 'Narrative';
                                fingerprint = 'Navigator';
                                break;
                              case 2:
                                visualPreference = 'Vibrant';
                                storyPreference = 'Inductive';
                                dataPreference = 'Objective';
                                fingerprint = 'Director';
                                break;
                            }
                            break;
                          case 2:
                            switch (preferences.question5) {
                              case 1:
                                visualPreference = 'Vibrant';
                                storyPreference = 'Inductive';
                                dataPreference = 'Narrative';
                                fingerprint = 'Navigator';
                                break;
                              case 2:
                                visualPreference = 'Vibrant';
                                storyPreference = 'Inductive';
                                dataPreference = 'Narrative';
                                fingerprint = 'Navigator';
                                break;
                            }
                            break;
                        }
                        break;
                    }
                    break;
                }
                break;
              case 2:
                switch (preferences.question2) {
                  case 1:
                    switch (preferences.question3) {
                      case 1:
                        switch (preferences.question4) {
                          case 1:
                            switch (preferences.question5) {
                              case 1:
                                visualPreference = 'Vibrant';
                                storyPreference = 'Deductive';
                                dataPreference = 'Narrative';
                                fingerprint = 'Performer';
                                break;
                              case 2:
                                visualPreference = 'Vibrant';
                                storyPreference = 'Deductive';
                                dataPreference = 'Objective';
                                fingerprint = 'Scholar';
                                break;
                            }
                            break;
                          case 2:
                            switch (preferences.question5) {
                              case 1:
                                visualPreference = 'Vibrant';
                                storyPreference = 'Deductive';
                                dataPreference = 'Narrative';
                                fingerprint = 'Performer';
                                break;
                              case 2:
                                visualPreference = 'Vibrant';
                                storyPreference = 'Deductive';
                                dataPreference = 'Narrative';
                                fingerprint = 'Performer';
                                break;
                            }
                            break;
                        }
                        break;
                      case 2:
                        switch (preferences.question4) {
                          case 1:
                            switch (preferences.question5) {
                              case 1:
                                visualPreference = 'Vibrant';
                                storyPreference = 'Inductive';
                                dataPreference = 'Narrative';
                                fingerprint = 'Navigator';
                                break;
                              case 2:
                                visualPreference = 'Vibrant';
                                storyPreference = 'Inductive';
                                dataPreference = 'Objective';
                                fingerprint = 'Director';
                                break;
                            }
                            break;
                          case 2:
                            switch (preferences.question5) {
                              case 1:
                                visualPreference = 'Vibrant';
                                storyPreference = 'Inductive';
                                dataPreference = 'Narrative';
                                fingerprint = 'Navigator';
                                break;
                              case 2:
                                visualPreference = 'Vibrant';
                                storyPreference = 'Inductive';
                                dataPreference = 'Narrative';
                                fingerprint = 'Navigator';
                                break;
                            }
                            break;
                        }
                        break;
                    }
                    break;

                  case 2:
                    switch (preferences.question3) {
                      case 1:
                        switch (preferences.question4) {
                          case 1:
                            switch (preferences.question5) {
                              case 1:
                                visualPreference = 'Minimal';
                                storyPreference = 'Deductive';
                                dataPreference = 'Narrative';
                                fingerprint = 'Producer';
                                break;
                              case 2:
                                visualPreference = 'Minimal';
                                storyPreference = 'Deductive';
                                dataPreference = 'Objective';
                                fingerprint = 'Scientist';
                                break;
                            }
                            break;
                          case 2:
                            switch (preferences.question5) {
                              case 1:
                                visualPreference = 'Minimal';
                                storyPreference = 'Deductive';
                                dataPreference = 'Narrative';
                                fingerprint = 'Producer';
                                break;
                              case 2:
                                visualPreference = 'Minimal';
                                storyPreference = 'Deductive';
                                dataPreference = 'Narrative';
                                fingerprint = 'Producer';
                                break;
                            }
                            break;
                        }
                        break;
                      case 2:
                        switch (preferences.question4) {
                          case 1:
                            switch (preferences.question5) {
                              case 1:
                                visualPreference = 'Minimal';
                                storyPreference = 'Inductive';
                                dataPreference = 'Narrative';
                                fingerprint = 'Surgeon';
                                break;
                              case 2:
                                visualPreference = 'Minimal';
                                storyPreference = 'Inductive';
                                dataPreference = 'Objective';
                                fingerprint = 'Architect';
                                break;
                            }
                            break;
                          case 2:
                            switch (preferences.question5) {
                              case 1:
                                visualPreference = 'Minimal';
                                storyPreference = 'Inductive';
                                dataPreference = 'Narrative';
                                fingerprint = 'Surgeon';
                                break;
                              case 2:
                                visualPreference = 'Minimal';
                                storyPreference = 'Inductive';
                                dataPreference = 'Narrative';
                                fingerprint = 'Surgeon';
                                break;
                            }
                            break;
                        }
                        break;
                    }
                    break;
                }
            }
            break;

          case 2:
            switch (preferences.question1) {
              case 1:
                switch (preferences.question2) {
                  case 1:
                    switch (preferences.question3) {
                      case 1:
                        switch (preferences.question4) {
                          case 1:
                            switch (preferences.question5) {
                              case 1:
                                visualPreference = 'Vibrant';
                                storyPreference = 'Deductive';
                                dataPreference = 'Narrative';
                                fingerprint = 'Performer';
                                break;
                              case 2:
                                visualPreference = 'Vibrant';
                                storyPreference = 'Deductive';
                                dataPreference = 'Objective';
                                fingerprint = 'Scholar';
                                break;
                            }
                            break;
                          case 2:
                            switch (preferences.question5) {
                              case 1:
                                visualPreference = 'Vibrant';
                                storyPreference = 'Deductive';
                                dataPreference = 'Narrative';
                                fingerprint = 'Performer';
                                break;
                              case 2:
                                visualPreference = 'Vibrant';
                                storyPreference = 'Deductive';
                                dataPreference = 'Narrative';
                                fingerprint = 'Performer';
                                break;
                            }
                            break;
                        }
                        break;
                      case 2:
                        switch (preferences.question4) {
                          case 1:
                            switch (preferences.question5) {
                              case 1:
                                visualPreference = 'Vibrant';
                                storyPreference = 'Inductive';
                                dataPreference = 'Narrative';
                                fingerprint = 'Navigator';
                                break;
                              case 2:
                                visualPreference = 'Vibrant';
                                storyPreference = 'Inductive';
                                dataPreference = 'Objective';
                                fingerprint = 'Director';
                                break;
                            }
                            break;
                          case 2:
                            switch (preferences.question5) {
                              case 1:
                                visualPreference = 'Vibrant';
                                storyPreference = 'Inductive';
                                dataPreference = 'Narrative';
                                fingerprint = 'Navigator';
                                break;
                              case 2:
                                visualPreference = 'Vibrant';
                                storyPreference = 'Inductive';
                                dataPreference = 'Narrative';
                                fingerprint = 'Navigator';
                                break;
                            }
                            break;
                        }
                        break;
                    }
                    break;

                  case 2:
                    switch (preferences.question3) {
                      case 1:
                        switch (preferences.question4) {
                          case 1:
                            switch (preferences.question5) {
                              case 1:
                                visualPreference = 'Minimal';
                                storyPreference = 'Deductive';
                                dataPreference = 'Narrative';
                                fingerprint = 'Producer';
                                break;
                              case 2:
                                visualPreference = 'Minimal';
                                storyPreference = 'Deductive';
                                dataPreference = 'Objective';
                                fingerprint = 'Scientist';
                                break;
                            }
                            break;
                          case 2:
                            switch (preferences.question5) {
                              case 1:
                                visualPreference = 'Minimal';
                                storyPreference = 'Deductive';
                                dataPreference = 'Narrative';
                                fingerprint = 'Producer';
                                break;
                              case 2:
                                visualPreference = 'Minimal';
                                storyPreference = 'Deductive';
                                dataPreference = 'Narrative';
                                fingerprint = 'Producer';
                                break;
                            }
                            break;
                        }
                        break;
                      case 2:
                        switch (preferences.question4) {
                          case 1:
                            switch (preferences.question5) {
                              case 1:
                                visualPreference = 'Minimal';
                                storyPreference = 'Inductive';
                                dataPreference = 'Narrative';
                                fingerprint = 'Surgeon';
                                break;
                              case 2:
                                visualPreference = 'Minimal';
                                storyPreference = 'Inductive';
                                dataPreference = 'Objective';
                                fingerprint = 'Architect';
                                break;
                            }
                            break;
                          case 2:
                            switch (preferences.question5) {
                              case 1:
                                visualPreference = 'Minimal';
                                storyPreference = 'Inductive';
                                dataPreference = 'Narrative';
                                fingerprint = 'Surgeon';
                                break;
                              case 2:
                                visualPreference = 'Minimal';
                                storyPreference = 'Inductive';
                                dataPreference = 'Narrative';
                                fingerprint = 'Surgeon';
                                break;
                            }
                            break;
                        }
                        break;
                    }
                    break;
                }
                break;
              case 2:
                switch (preferences.question2) {
                  case 1:
                    switch (preferences.question3) {
                      case 1:
                        switch (preferences.question4) {
                          case 1:
                            switch (preferences.question5) {
                              case 1:
                                visualPreference = 'Minimal';
                                storyPreference = 'Deductive';
                                dataPreference = 'Narrative';
                                fingerprint = 'Producer';
                                break;
                              case 2:
                                visualPreference = 'Minimal';
                                storyPreference = 'Deductive';
                                dataPreference = 'Objective';
                                fingerprint = 'Scientist';
                                break;
                            }
                            break;
                          case 2:
                            switch (preferences.question5) {
                              case 1:
                                visualPreference = 'Minimal';
                                storyPreference = 'Deductive';
                                dataPreference = 'Narrative';
                                fingerprint = 'Producer';
                                break;
                              case 2:
                                visualPreference = 'Minimal';
                                storyPreference = 'Deductive';
                                dataPreference = 'Narrative';
                                fingerprint = 'Producer';
                                break;
                            }
                            break;
                        }
                        break;
                      case 2:
                        switch (preferences.question4) {
                          case 1:
                            switch (preferences.question5) {
                              case 1:
                                visualPreference = 'Minimal';
                                storyPreference = 'Inductive';
                                dataPreference = 'Narrative';
                                fingerprint = 'Surgeon';
                                break;
                              case 2:
                                visualPreference = 'Minimal';
                                storyPreference = 'Inductive';
                                dataPreference = 'Objective';
                                fingerprint = 'Architect';
                                break;
                            }
                            break;
                          case 2:
                            switch (preferences.question5) {
                              case 1:
                                visualPreference = 'Minimal';
                                storyPreference = 'Inductive';
                                dataPreference = 'Narrative';
                                fingerprint = 'Surgeon';
                                break;
                              case 2:
                                visualPreference = 'Minimal';
                                storyPreference = 'Inductive';
                                dataPreference = 'Narrative';
                                fingerprint = 'Surgeon';
                                break;
                            }
                            break;
                        }
                        break;
                    }
                    break;

                  case 2:
                    switch (preferences.question3) {
                      case 1:
                        switch (preferences.question4) {
                          case 1:
                            switch (preferences.question5) {
                              case 1:
                                visualPreference = 'Minimal';
                                storyPreference = 'Deductive';
                                dataPreference = 'Narrative';
                                fingerprint = 'Producer';
                                break;
                              case 2:
                                visualPreference = 'Minimal';
                                storyPreference = 'Deductive';
                                dataPreference = 'Objective';
                                fingerprint = 'Scientist';
                                break;
                            }
                            break;
                          case 2:
                            switch (preferences.question5) {
                              case 1:
                                visualPreference = 'Minimal';
                                storyPreference = 'Deductive';
                                dataPreference = 'Narrative';
                                fingerprint = 'Producer';
                                break;
                              case 2:
                                visualPreference = 'Minimal';
                                storyPreference = 'Deductive';
                                dataPreference = 'Narrative';
                                fingerprint = 'Producer';
                                break;
                            }
                            break;
                        }
                        break;
                      case 2:
                        switch (preferences.question4) {
                          case 1:
                            switch (preferences.question5) {
                              case 1:
                                visualPreference = 'Minimal';
                                storyPreference = 'Inductive';
                                dataPreference = 'Narrative';
                                fingerprint = 'Surgeon';
                                break;
                              case 2:
                                visualPreference = 'Minimal';
                                storyPreference = 'Inductive';
                                dataPreference = 'Objective';
                                fingerprint = 'Architect';
                                break;
                            }
                            break;
                          case 2:
                            switch (preferences.question5) {
                              case 1:
                                visualPreference = 'Minimal';
                                storyPreference = 'Inductive';
                                dataPreference = 'Narrative';
                                fingerprint = 'Surgeon';
                                break;
                              case 2:
                                visualPreference = 'Minimal';
                                storyPreference = 'Inductive';
                                dataPreference = 'Narrative';
                                fingerprint = 'Surgeon';
                                break;
                            }
                            break;
                        }
                        break;
                    }
                    break;
                }
            }
        }
        if (!preferences.emailPreview) {
          context.commit('setVisualpreference', visualPreference);
          context.commit('setStorypreference', storyPreference);
          context.commit('setDatapreference', dataPreference);
          context.commit('setCurrentUserFingerprint', {
            fingerprint,
            preferences,
          });
        }
        resolve(fingerprint);
      });
    },

    setPrivateAudienceFingerprint(context, payload) {
      return new Promise((resolve) => {
        const { preferences } = payload;
        const { industrySelected } = payload;
        const { functionSelected } = payload;
        const { groupSelected } = payload;
        const { regionSelected } = payload;
        const { jobTitleSelected } = payload;
        const { fpCompletionDate } = payload;
        const audienceID = payload.id;
        let fingerprint = 'None';
        let visualPreference = 'None';
        let storyPreference = 'None';
        let dataPreference = 'None';
        switch (preferences.question0) {
          case 1:
            switch (preferences.question1) {
              case 1:
                switch (preferences.question2) {
                  case 1:
                    switch (preferences.question3) {
                      case 1:
                        switch (preferences.question4) {
                          case 1:
                            switch (preferences.question5) {
                              case 1:
                                visualPreference = 'Vibrant';
                                storyPreference = 'Deductive';
                                dataPreference = 'Narrative';
                                fingerprint = 'Performer';
                                break;
                              case 2:
                                visualPreference = 'Vibrant';
                                storyPreference = 'Deductive';
                                dataPreference = 'Objective';
                                fingerprint = 'Scholar';
                                break;
                            }
                            break;
                          case 2:
                            switch (preferences.question5) {
                              case 1:
                                visualPreference = 'Vibrant';
                                storyPreference = 'Deductive';
                                dataPreference = 'Narrative';
                                fingerprint = 'Performer';
                                break;
                              case 2:
                                visualPreference = 'Vibrant';
                                storyPreference = 'Deductive';
                                dataPreference = 'Narrative';
                                fingerprint = 'Performer';
                                break;
                            }
                            break;
                        }
                        break;
                      case 2:
                        switch (preferences.question4) {
                          case 1:
                            switch (preferences.question5) {
                              case 1:
                                visualPreference = 'Vibrant';
                                storyPreference = 'Inductive';
                                dataPreference = 'Narrative';
                                fingerprint = 'Navigator';
                                break;
                              case 2:
                                visualPreference = 'Vibrant';
                                storyPreference = 'Inductive';
                                dataPreference = 'Objective';
                                fingerprint = 'Director';
                                break;
                            }
                            break;
                          case 2:
                            switch (preferences.question5) {
                              case 1:
                                visualPreference = 'Vibrant';
                                storyPreference = 'Inductive';
                                dataPreference = 'Narrative';
                                fingerprint = 'Navigator';
                                break;
                              case 2:
                                visualPreference = 'Vibrant';
                                storyPreference = 'Inductive';
                                dataPreference = 'Narrative';
                                fingerprint = 'Navigator';
                                break;
                            }
                            break;
                        }
                        break;
                    }
                    break;
                  case 2:
                    switch (preferences.question3) {
                      case 1:
                        switch (preferences.question4) {
                          case 1:
                            switch (preferences.question5) {
                              case 1:
                                visualPreference = 'Vibrant';
                                storyPreference = 'Deductive';
                                dataPreference = 'Narrative';
                                fingerprint = 'Performer';
                                break;
                              case 2:
                                visualPreference = 'Vibrant';
                                storyPreference = 'Deductive';
                                dataPreference = 'Objective';
                                fingerprint = 'Scholar';
                                break;
                            }
                            break;
                          case 2:
                            switch (preferences.question5) {
                              case 1:
                                visualPreference = 'Vibrant';
                                storyPreference = 'Deductive';
                                dataPreference = 'Narrative';
                                fingerprint = 'Performer';
                                break;
                              case 2:
                                visualPreference = 'Vibrant';
                                storyPreference = 'Deductive';
                                dataPreference = 'Narrative';
                                fingerprint = 'Performer';
                                break;
                            }
                            break;
                        }
                        break;
                      case 2:
                        switch (preferences.question4) {
                          case 1:
                            switch (preferences.question5) {
                              case 1:
                                visualPreference = 'Vibrant';
                                storyPreference = 'Inductive';
                                dataPreference = 'Narrative';
                                fingerprint = 'Navigator';
                                break;
                              case 2:
                                visualPreference = 'Vibrant';
                                storyPreference = 'Inductive';
                                dataPreference = 'Objective';
                                fingerprint = 'Director';
                                break;
                            }
                            break;
                          case 2:
                            switch (preferences.question5) {
                              case 1:
                                visualPreference = 'Vibrant';
                                storyPreference = 'Inductive';
                                dataPreference = 'Narrative';
                                fingerprint = 'Navigator';
                                break;
                              case 2:
                                visualPreference = 'Vibrant';
                                storyPreference = 'Inductive';
                                dataPreference = 'Narrative';
                                fingerprint = 'Navigator';
                                break;
                            }
                            break;
                        }
                        break;
                    }
                    break;
                }
                break;
              case 2:
                switch (preferences.question2) {
                  case 1:
                    switch (preferences.question3) {
                      case 1:
                        switch (preferences.question4) {
                          case 1:
                            switch (preferences.question5) {
                              case 1:
                                visualPreference = 'Vibrant';
                                storyPreference = 'Deductive';
                                dataPreference = 'Narrative';
                                fingerprint = 'Performer';
                                break;
                              case 2:
                                visualPreference = 'Vibrant';
                                storyPreference = 'Deductive';
                                dataPreference = 'Objective';
                                fingerprint = 'Scholar';
                                break;
                            }
                            break;
                          case 2:
                            switch (preferences.question5) {
                              case 1:
                                visualPreference = 'Vibrant';
                                storyPreference = 'Deductive';
                                dataPreference = 'Narrative';
                                fingerprint = 'Performer';
                                break;
                              case 2:
                                visualPreference = 'Vibrant';
                                storyPreference = 'Deductive';
                                dataPreference = 'Narrative';
                                fingerprint = 'Performer';
                                break;
                            }
                            break;
                        }
                        break;
                      case 2:
                        switch (preferences.question4) {
                          case 1:
                            switch (preferences.question5) {
                              case 1:
                                visualPreference = 'Vibrant';
                                storyPreference = 'Inductive';
                                dataPreference = 'Narrative';
                                fingerprint = 'Navigator';
                                break;
                              case 2:
                                visualPreference = 'Vibrant';
                                storyPreference = 'Inductive';
                                dataPreference = 'Objective';
                                fingerprint = 'Director';
                                break;
                            }
                            break;
                          case 2:
                            switch (preferences.question5) {
                              case 1:
                                visualPreference = 'Vibrant';
                                storyPreference = 'Inductive';
                                dataPreference = 'Narrative';
                                fingerprint = 'Navigator';
                                break;
                              case 2:
                                visualPreference = 'Vibrant';
                                storyPreference = 'Inductive';
                                dataPreference = 'Narrative';
                                fingerprint = 'Navigator';
                                break;
                            }
                            break;
                        }
                        break;
                    }
                    break;

                  case 2:
                    switch (preferences.question3) {
                      case 1:
                        switch (preferences.question4) {
                          case 1:
                            switch (preferences.question5) {
                              case 1:
                                visualPreference = 'Minimal';
                                storyPreference = 'Deductive';
                                dataPreference = 'Narrative';
                                fingerprint = 'Producer';
                                break;
                              case 2:
                                visualPreference = 'Minimal';
                                storyPreference = 'Deductive';
                                dataPreference = 'Objective';
                                fingerprint = 'Scientist';
                                break;
                            }
                            break;
                          case 2:
                            switch (preferences.question5) {
                              case 1:
                                visualPreference = 'Minimal';
                                storyPreference = 'Deductive';
                                dataPreference = 'Narrative';
                                fingerprint = 'Producer';
                                break;
                              case 2:
                                visualPreference = 'Minimal';
                                storyPreference = 'Deductive';
                                dataPreference = 'Narrative';
                                fingerprint = 'Producer';
                                break;
                            }
                            break;
                        }
                        break;
                      case 2:
                        switch (preferences.question4) {
                          case 1:
                            switch (preferences.question5) {
                              case 1:
                                visualPreference = 'Minimal';
                                storyPreference = 'Inductive';
                                dataPreference = 'Narrative';
                                fingerprint = 'Surgeon';
                                break;
                              case 2:
                                visualPreference = 'Minimal';
                                storyPreference = 'Inductive';
                                dataPreference = 'Objective';
                                fingerprint = 'Architect';
                                break;
                            }
                            break;
                          case 2:
                            switch (preferences.question5) {
                              case 1:
                                visualPreference = 'Minimal';
                                storyPreference = 'Inductive';
                                dataPreference = 'Narrative';
                                fingerprint = 'Surgeon';
                                break;
                              case 2:
                                visualPreference = 'Minimal';
                                storyPreference = 'Inductive';
                                dataPreference = 'Narrative';
                                fingerprint = 'Surgeon';
                                break;
                            }
                            break;
                        }
                        break;
                    }
                    break;
                }
            }
            break;

          case 2:
            switch (preferences.question1) {
              case 1:
                switch (preferences.question2) {
                  case 1:
                    switch (preferences.question3) {
                      case 1:
                        switch (preferences.question4) {
                          case 1:
                            switch (preferences.question5) {
                              case 1:
                                visualPreference = 'Vibrant';
                                storyPreference = 'Deductive';
                                dataPreference = 'Narrative';
                                fingerprint = 'Performer';
                                break;
                              case 2:
                                visualPreference = 'Vibrant';
                                storyPreference = 'Deductive';
                                dataPreference = 'Objective';
                                fingerprint = 'Scholar';
                                break;
                            }
                            break;
                          case 2:
                            switch (preferences.question5) {
                              case 1:
                                visualPreference = 'Vibrant';
                                storyPreference = 'Deductive';
                                dataPreference = 'Narrative';
                                fingerprint = 'Performer';
                                break;
                              case 2:
                                visualPreference = 'Vibrant';
                                storyPreference = 'Deductive';
                                dataPreference = 'Narrative';
                                fingerprint = 'Performer';
                                break;
                            }
                            break;
                        }
                        break;
                      case 2:
                        switch (preferences.question4) {
                          case 1:
                            switch (preferences.question5) {
                              case 1:
                                visualPreference = 'Vibrant';
                                storyPreference = 'Inductive';
                                dataPreference = 'Narrative';
                                fingerprint = 'Navigator';
                                break;
                              case 2:
                                visualPreference = 'Vibrant';
                                storyPreference = 'Inductive';
                                dataPreference = 'Objective';
                                fingerprint = 'Director';
                                break;
                            }
                            break;
                          case 2:
                            switch (preferences.question5) {
                              case 1:
                                visualPreference = 'Vibrant';
                                storyPreference = 'Inductive';
                                dataPreference = 'Narrative';
                                fingerprint = 'Navigator';
                                break;
                              case 2:
                                visualPreference = 'Vibrant';
                                storyPreference = 'Inductive';
                                dataPreference = 'Narrative';
                                fingerprint = 'Navigator';
                                break;
                            }
                            break;
                        }
                        break;
                    }
                    break;

                  case 2:
                    switch (preferences.question3) {
                      case 1:
                        switch (preferences.question4) {
                          case 1:
                            switch (preferences.question5) {
                              case 1:
                                visualPreference = 'Minimal';
                                storyPreference = 'Deductive';
                                dataPreference = 'Narrative';
                                fingerprint = 'Producer';
                                break;
                              case 2:
                                visualPreference = 'Minimal';
                                storyPreference = 'Deductive';
                                dataPreference = 'Objective';
                                fingerprint = 'Scientist';
                                break;
                            }
                            break;
                          case 2:
                            switch (preferences.question5) {
                              case 1:
                                visualPreference = 'Minimal';
                                storyPreference = 'Deductive';
                                dataPreference = 'Narrative';
                                fingerprint = 'Producer';
                                break;
                              case 2:
                                visualPreference = 'Minimal';
                                storyPreference = 'Deductive';
                                dataPreference = 'Narrative';
                                fingerprint = 'Producer';
                                break;
                            }
                            break;
                        }
                        break;
                      case 2:
                        switch (preferences.question4) {
                          case 1:
                            switch (preferences.question5) {
                              case 1:
                                visualPreference = 'Minimal';
                                storyPreference = 'Inductive';
                                dataPreference = 'Narrative';
                                fingerprint = 'Surgeon';
                                break;
                              case 2:
                                visualPreference = 'Minimal';
                                storyPreference = 'Inductive';
                                dataPreference = 'Objective';
                                fingerprint = 'Architect';
                                break;
                            }
                            break;
                          case 2:
                            switch (preferences.question5) {
                              case 1:
                                visualPreference = 'Minimal';
                                storyPreference = 'Inductive';
                                dataPreference = 'Narrative';
                                fingerprint = 'Surgeon';
                                break;
                              case 2:
                                visualPreference = 'Minimal';
                                storyPreference = 'Inductive';
                                dataPreference = 'Narrative';
                                fingerprint = 'Surgeon';
                                break;
                            }
                            break;
                        }
                        break;
                    }
                    break;
                }
                break;
              case 2:
                switch (preferences.question2) {
                  case 1:
                    switch (preferences.question3) {
                      case 1:
                        switch (preferences.question4) {
                          case 1:
                            switch (preferences.question5) {
                              case 1:
                                visualPreference = 'Minimal';
                                storyPreference = 'Deductive';
                                dataPreference = 'Narrative';
                                fingerprint = 'Producer';
                                break;
                              case 2:
                                visualPreference = 'Minimal';
                                storyPreference = 'Deductive';
                                dataPreference = 'Objective';
                                fingerprint = 'Scientist';
                                break;
                            }
                            break;
                          case 2:
                            switch (preferences.question5) {
                              case 1:
                                visualPreference = 'Minimal';
                                storyPreference = 'Deductive';
                                dataPreference = 'Narrative';
                                fingerprint = 'Producer';
                                break;
                              case 2:
                                visualPreference = 'Minimal';
                                storyPreference = 'Deductive';
                                dataPreference = 'Narrative';
                                fingerprint = 'Producer';
                                break;
                            }
                            break;
                        }
                        break;
                      case 2:
                        switch (preferences.question4) {
                          case 1:
                            switch (preferences.question5) {
                              case 1:
                                visualPreference = 'Minimal';
                                storyPreference = 'Inductive';
                                dataPreference = 'Narrative';
                                fingerprint = 'Surgeon';
                                break;
                              case 2:
                                visualPreference = 'Minimal';
                                storyPreference = 'Inductive';
                                dataPreference = 'Objective';
                                fingerprint = 'Architect';
                                break;
                            }
                            break;
                          case 2:
                            switch (preferences.question5) {
                              case 1:
                                visualPreference = 'Minimal';
                                storyPreference = 'Inductive';
                                dataPreference = 'Narrative';
                                fingerprint = 'Surgeon';
                                break;
                              case 2:
                                visualPreference = 'Minimal';
                                storyPreference = 'Inductive';
                                dataPreference = 'Narrative';
                                fingerprint = 'Surgeon';
                                break;
                            }
                            break;
                        }
                        break;
                    }
                    break;

                  case 2:
                    switch (preferences.question3) {
                      case 1:
                        switch (preferences.question4) {
                          case 1:
                            switch (preferences.question5) {
                              case 1:
                                visualPreference = 'Minimal';
                                storyPreference = 'Deductive';
                                dataPreference = 'Narrative';
                                fingerprint = 'Producer';
                                break;
                              case 2:
                                visualPreference = 'Minimal';
                                storyPreference = 'Deductive';
                                dataPreference = 'Objective';
                                fingerprint = 'Scientist';
                                break;
                            }
                            break;
                          case 2:
                            switch (preferences.question5) {
                              case 1:
                                visualPreference = 'Minimal';
                                storyPreference = 'Deductive';
                                dataPreference = 'Narrative';
                                fingerprint = 'Producer';
                                break;
                              case 2:
                                visualPreference = 'Minimal';
                                storyPreference = 'Deductive';
                                dataPreference = 'Narrative';
                                fingerprint = 'Producer';
                                break;
                            }
                            break;
                        }
                        break;
                      case 2:
                        switch (preferences.question4) {
                          case 1:
                            switch (preferences.question5) {
                              case 1:
                                visualPreference = 'Minimal';
                                storyPreference = 'Inductive';
                                dataPreference = 'Narrative';
                                fingerprint = 'Surgeon';
                                break;
                              case 2:
                                visualPreference = 'Minimal';
                                storyPreference = 'Inductive';
                                dataPreference = 'Objective';
                                fingerprint = 'Architect';
                                break;
                            }
                            break;
                          case 2:
                            switch (preferences.question5) {
                              case 1:
                                visualPreference = 'Minimal';
                                storyPreference = 'Inductive';
                                dataPreference = 'Narrative';
                                fingerprint = 'Surgeon';
                                break;
                              case 2:
                                visualPreference = 'Minimal';
                                storyPreference = 'Inductive';
                                dataPreference = 'Narrative';
                                fingerprint = 'Surgeon';
                                break;
                            }
                            break;
                        }
                        break;
                    }
                    break;
                }
            }
        }
        const pref = {
          visualPreference: visualPreference,
          dataPreference,
          storyPreference,
          fingerprint,
          audienceID,
          industrySelected,
          functionSelected,
          groupSelected,
          regionSelected,
          jobTitleSelected,
          fpCompletionDate,
        };
        context.commit('setPrivateAudiencePreferences', { pref, preferences });
        resolve(pref);
      });
      // context.commit("persistCurrentTestAudience");
    },

    setCurrentTestAudienceFingerprint(context, preferences) {
      let fingerprint = 'None';
      let visualPreference = 'None';
      let storyPreference = 'None';
      let dataPreference = 'None';
      switch (preferences.question0) {
        case 1:
          switch (preferences.question1) {
            case 1:
              switch (preferences.question2) {
                case 1:
                  switch (preferences.question3) {
                    case 1:
                      switch (preferences.question4) {
                        case 1:
                          switch (preferences.question5) {
                            case 1:
                              visualPreference = 'Vibrant';
                              storyPreference = 'Deductive';
                              dataPreference = 'Narrative';
                              fingerprint = 'Performer';
                              break;
                            case 2:
                              visualPreference = 'Vibrant';
                              storyPreference = 'Deductive';
                              dataPreference = 'Objective';
                              fingerprint = 'Scholar';
                              break;
                          }
                          break;
                        case 2:
                          switch (preferences.question5) {
                            case 1:
                              visualPreference = 'Vibrant';
                              storyPreference = 'Deductive';
                              dataPreference = 'Narrative';
                              fingerprint = 'Performer';
                              break;
                            case 2:
                              visualPreference = 'Vibrant';
                              storyPreference = 'Deductive';
                              dataPreference = 'Narrative';
                              fingerprint = 'Performer';
                              break;
                          }
                          break;
                      }
                      break;
                    case 2:
                      switch (preferences.question4) {
                        case 1:
                          switch (preferences.question5) {
                            case 1:
                              visualPreference = 'Vibrant';
                              storyPreference = 'Inductive';
                              dataPreference = 'Narrative';
                              fingerprint = 'Navigator';
                              break;
                            case 2:
                              visualPreference = 'Vibrant';
                              storyPreference = 'Inductive';
                              dataPreference = 'Objective';
                              fingerprint = 'Director';
                              break;
                          }
                          break;
                        case 2:
                          switch (preferences.question5) {
                            case 1:
                              visualPreference = 'Vibrant';
                              storyPreference = 'Inductive';
                              dataPreference = 'Narrative';
                              fingerprint = 'Navigator';
                              break;
                            case 2:
                              visualPreference = 'Vibrant';
                              storyPreference = 'Inductive';
                              dataPreference = 'Narrative';
                              fingerprint = 'Navigator';
                              break;
                          }
                          break;
                      }
                      break;
                  }
                  break;
                case 2:
                  switch (preferences.question3) {
                    case 1:
                      switch (preferences.question4) {
                        case 1:
                          switch (preferences.question5) {
                            case 1:
                              visualPreference = 'Vibrant';
                              storyPreference = 'Deductive';
                              dataPreference = 'Narrative';
                              fingerprint = 'Performer';
                              break;
                            case 2:
                              visualPreference = 'Vibrant';
                              storyPreference = 'Deductive';
                              dataPreference = 'Objective';
                              fingerprint = 'Scholar';
                              break;
                          }
                          break;
                        case 2:
                          switch (preferences.question5) {
                            case 1:
                              visualPreference = 'Vibrant';
                              storyPreference = 'Deductive';
                              dataPreference = 'Narrative';
                              fingerprint = 'Performer';
                              break;
                            case 2:
                              visualPreference = 'Vibrant';
                              storyPreference = 'Deductive';
                              dataPreference = 'Narrative';
                              fingerprint = 'Performer';
                              break;
                          }
                          break;
                      }
                      break;
                    case 2:
                      switch (preferences.question4) {
                        case 1:
                          switch (preferences.question5) {
                            case 1:
                              visualPreference = 'Vibrant';
                              storyPreference = 'Inductive';
                              dataPreference = 'Narrative';
                              fingerprint = 'Navigator';
                              break;
                            case 2:
                              visualPreference = 'Vibrant';
                              storyPreference = 'Inductive';
                              dataPreference = 'Objective';
                              fingerprint = 'Director';
                              break;
                          }
                          break;
                        case 2:
                          switch (preferences.question5) {
                            case 1:
                              visualPreference = 'Vibrant';
                              storyPreference = 'Inductive';
                              dataPreference = 'Narrative';
                              fingerprint = 'Navigator';
                              break;
                            case 2:
                              visualPreference = 'Vibrant';
                              storyPreference = 'Inductive';
                              dataPreference = 'Narrative';
                              fingerprint = 'Navigator';
                              break;
                          }
                          break;
                      }
                      break;
                  }
                  break;
              }
              break;
            case 2:
              switch (preferences.question2) {
                case 1:
                  switch (preferences.question3) {
                    case 1:
                      switch (preferences.question4) {
                        case 1:
                          switch (preferences.question5) {
                            case 1:
                              visualPreference = 'Vibrant';
                              storyPreference = 'Deductive';
                              dataPreference = 'Narrative';
                              fingerprint = 'Performer';
                              break;
                            case 2:
                              visualPreference = 'Vibrant';
                              storyPreference = 'Deductive';
                              dataPreference = 'Objective';
                              fingerprint = 'Scholar';
                              break;
                          }
                          break;
                        case 2:
                          switch (preferences.question5) {
                            case 1:
                              visualPreference = 'Vibrant';
                              storyPreference = 'Deductive';
                              dataPreference = 'Narrative';
                              fingerprint = 'Performer';
                              break;
                            case 2:
                              visualPreference = 'Vibrant';
                              storyPreference = 'Deductive';
                              dataPreference = 'Narrative';
                              fingerprint = 'Performer';
                              break;
                          }
                          break;
                      }
                      break;
                    case 2:
                      switch (preferences.question4) {
                        case 1:
                          switch (preferences.question5) {
                            case 1:
                              visualPreference = 'Vibrant';
                              storyPreference = 'Inductive';
                              dataPreference = 'Narrative';
                              fingerprint = 'Navigator';
                              break;
                            case 2:
                              visualPreference = 'Vibrant';
                              storyPreference = 'Inductive';
                              dataPreference = 'Objective';
                              fingerprint = 'Director';
                              break;
                          }
                          break;
                        case 2:
                          switch (preferences.question5) {
                            case 1:
                              visualPreference = 'Vibrant';
                              storyPreference = 'Inductive';
                              dataPreference = 'Narrative';
                              fingerprint = 'Navigator';
                              break;
                            case 2:
                              visualPreference = 'Vibrant';
                              storyPreference = 'Inductive';
                              dataPreference = 'Narrative';
                              fingerprint = 'Navigator';
                              break;
                          }
                          break;
                      }
                      break;
                  }
                  break;

                case 2:
                  switch (preferences.question3) {
                    case 1:
                      switch (preferences.question4) {
                        case 1:
                          switch (preferences.question5) {
                            case 1:
                              visualPreference = 'Minimal';
                              storyPreference = 'Deductive';
                              dataPreference = 'Narrative';
                              fingerprint = 'Producer';
                              break;
                            case 2:
                              visualPreference = 'Minimal';
                              storyPreference = 'Deductive';
                              dataPreference = 'Objective';
                              fingerprint = 'Scientist';
                              break;
                          }
                          break;
                        case 2:
                          switch (preferences.question5) {
                            case 1:
                              visualPreference = 'Minimal';
                              storyPreference = 'Deductive';
                              dataPreference = 'Narrative';
                              fingerprint = 'Producer';
                              break;
                            case 2:
                              visualPreference = 'Minimal';
                              storyPreference = 'Deductive';
                              dataPreference = 'Narrative';
                              fingerprint = 'Producer';
                              break;
                          }
                          break;
                      }
                      break;
                    case 2:
                      switch (preferences.question4) {
                        case 1:
                          switch (preferences.question5) {
                            case 1:
                              visualPreference = 'Minimal';
                              storyPreference = 'Inductive';
                              dataPreference = 'Narrative';
                              fingerprint = 'Surgeon';
                              break;
                            case 2:
                              visualPreference = 'Minimal';
                              storyPreference = 'Inductive';
                              dataPreference = 'Objective';
                              fingerprint = 'Architect';
                              break;
                          }
                          break;
                        case 2:
                          switch (preferences.question5) {
                            case 1:
                              visualPreference = 'Minimal';
                              storyPreference = 'Inductive';
                              dataPreference = 'Narrative';
                              fingerprint = 'Surgeon';
                              break;
                            case 2:
                              visualPreference = 'Minimal';
                              storyPreference = 'Inductive';
                              dataPreference = 'Narrative';
                              fingerprint = 'Surgeon';
                              break;
                          }
                          break;
                      }
                      break;
                  }
                  break;
              }
          }
          break;

        case 2:
          switch (preferences.question1) {
            case 1:
              switch (preferences.question2) {
                case 1:
                  switch (preferences.question3) {
                    case 1:
                      switch (preferences.question4) {
                        case 1:
                          switch (preferences.question5) {
                            case 1:
                              visualPreference = 'Vibrant';
                              storyPreference = 'Deductive';
                              dataPreference = 'Narrative';
                              fingerprint = 'Performer';
                              break;
                            case 2:
                              visualPreference = 'Vibrant';
                              storyPreference = 'Deductive';
                              dataPreference = 'Objective';
                              fingerprint = 'Scholar';
                              break;
                          }
                          break;
                        case 2:
                          switch (preferences.question5) {
                            case 1:
                              visualPreference = 'Vibrant';
                              storyPreference = 'Deductive';
                              dataPreference = 'Narrative';
                              fingerprint = 'Performer';
                              break;
                            case 2:
                              visualPreference = 'Vibrant';
                              storyPreference = 'Deductive';
                              dataPreference = 'Narrative';
                              fingerprint = 'Performer';
                              break;
                          }
                          break;
                      }
                      break;
                    case 2:
                      switch (preferences.question4) {
                        case 1:
                          switch (preferences.question5) {
                            case 1:
                              visualPreference = 'Vibrant';
                              storyPreference = 'Inductive';
                              dataPreference = 'Narrative';
                              fingerprint = 'Navigator';
                              break;
                            case 2:
                              visualPreference = 'Vibrant';
                              storyPreference = 'Inductive';
                              dataPreference = 'Objective';
                              fingerprint = 'Director';
                              break;
                          }
                          break;
                        case 2:
                          switch (preferences.question5) {
                            case 1:
                              visualPreference = 'Vibrant';
                              storyPreference = 'Inductive';
                              dataPreference = 'Narrative';
                              fingerprint = 'Navigator';
                              break;
                            case 2:
                              visualPreference = 'Vibrant';
                              storyPreference = 'Inductive';
                              dataPreference = 'Narrative';
                              fingerprint = 'Navigator';
                              break;
                          }
                          break;
                      }
                      break;
                  }
                  break;

                case 2:
                  switch (preferences.question3) {
                    case 1:
                      switch (preferences.question4) {
                        case 1:
                          switch (preferences.question5) {
                            case 1:
                              visualPreference = 'Minimal';
                              storyPreference = 'Deductive';
                              dataPreference = 'Narrative';
                              fingerprint = 'Producer';
                              break;
                            case 2:
                              visualPreference = 'Minimal';
                              storyPreference = 'Deductive';
                              dataPreference = 'Objective';
                              fingerprint = 'Scientist';
                              break;
                          }
                          break;
                        case 2:
                          switch (preferences.question5) {
                            case 1:
                              visualPreference = 'Minimal';
                              storyPreference = 'Deductive';
                              dataPreference = 'Narrative';
                              fingerprint = 'Producer';
                              break;
                            case 2:
                              visualPreference = 'Minimal';
                              storyPreference = 'Deductive';
                              dataPreference = 'Narrative';
                              fingerprint = 'Producer';
                              break;
                          }
                          break;
                      }
                      break;
                    case 2:
                      switch (preferences.question4) {
                        case 1:
                          switch (preferences.question5) {
                            case 1:
                              visualPreference = 'Minimal';
                              storyPreference = 'Inductive';
                              dataPreference = 'Narrative';
                              fingerprint = 'Surgeon';
                              break;
                            case 2:
                              visualPreference = 'Minimal';
                              storyPreference = 'Inductive';
                              dataPreference = 'Objective';
                              fingerprint = 'Architect';
                              break;
                          }
                          break;
                        case 2:
                          switch (preferences.question5) {
                            case 1:
                              visualPreference = 'Minimal';
                              storyPreference = 'Inductive';
                              dataPreference = 'Narrative';
                              fingerprint = 'Surgeon';
                              break;
                            case 2:
                              visualPreference = 'Minimal';
                              storyPreference = 'Inductive';
                              dataPreference = 'Narrative';
                              fingerprint = 'Surgeon';
                              break;
                          }
                          break;
                      }
                      break;
                  }
                  break;
              }
              break;
            case 2:
              switch (preferences.question2) {
                case 1:
                  switch (preferences.question3) {
                    case 1:
                      switch (preferences.question4) {
                        case 1:
                          switch (preferences.question5) {
                            case 1:
                              visualPreference = 'Minimal';
                              storyPreference = 'Deductive';
                              dataPreference = 'Narrative';
                              fingerprint = 'Producer';
                              break;
                            case 2:
                              visualPreference = 'Minimal';
                              storyPreference = 'Deductive';
                              dataPreference = 'Objective';
                              fingerprint = 'Scientist';
                              break;
                          }
                          break;
                        case 2:
                          switch (preferences.question5) {
                            case 1:
                              visualPreference = 'Minimal';
                              storyPreference = 'Deductive';
                              dataPreference = 'Narrative';
                              fingerprint = 'Producer';
                              break;
                            case 2:
                              visualPreference = 'Minimal';
                              storyPreference = 'Deductive';
                              dataPreference = 'Narrative';
                              fingerprint = 'Producer';
                              break;
                          }
                          break;
                      }
                      break;
                    case 2:
                      switch (preferences.question4) {
                        case 1:
                          switch (preferences.question5) {
                            case 1:
                              visualPreference = 'Minimal';
                              storyPreference = 'Inductive';
                              dataPreference = 'Narrative';
                              fingerprint = 'Surgeon';
                              break;
                            case 2:
                              visualPreference = 'Minimal';
                              storyPreference = 'Inductive';
                              dataPreference = 'Objective';
                              fingerprint = 'Architect';
                              break;
                          }
                          break;
                        case 2:
                          switch (preferences.question5) {
                            case 1:
                              visualPreference = 'Minimal';
                              storyPreference = 'Inductive';
                              dataPreference = 'Narrative';
                              fingerprint = 'Surgeon';
                              break;
                            case 2:
                              visualPreference = 'Minimal';
                              storyPreference = 'Inductive';
                              dataPreference = 'Narrative';
                              fingerprint = 'Surgeon';
                              break;
                          }
                          break;
                      }
                      break;
                  }
                  break;

                case 2:
                  switch (preferences.question3) {
                    case 1:
                      switch (preferences.question4) {
                        case 1:
                          switch (preferences.question5) {
                            case 1:
                              visualPreference = 'Minimal';
                              storyPreference = 'Deductive';
                              dataPreference = 'Narrative';
                              fingerprint = 'Producer';
                              break;
                            case 2:
                              visualPreference = 'Minimal';
                              storyPreference = 'Deductive';
                              dataPreference = 'Objective';
                              fingerprint = 'Scientist';
                              break;
                          }
                          break;
                        case 2:
                          switch (preferences.question5) {
                            case 1:
                              visualPreference = 'Minimal';
                              storyPreference = 'Deductive';
                              dataPreference = 'Narrative';
                              fingerprint = 'Producer';
                              break;
                            case 2:
                              visualPreference = 'Minimal';
                              storyPreference = 'Deductive';
                              dataPreference = 'Narrative';
                              fingerprint = 'Producer';
                              break;
                          }
                          break;
                      }
                      break;
                    case 2:
                      switch (preferences.question4) {
                        case 1:
                          switch (preferences.question5) {
                            case 1:
                              visualPreference = 'Minimal';
                              storyPreference = 'Inductive';
                              dataPreference = 'Narrative';
                              fingerprint = 'Surgeon';
                              break;
                            case 2:
                              visualPreference = 'Minimal';
                              storyPreference = 'Inductive';
                              dataPreference = 'Objective';
                              fingerprint = 'Architect';
                              break;
                          }
                          break;
                        case 2:
                          switch (preferences.question5) {
                            case 1:
                              visualPreference = 'Minimal';
                              storyPreference = 'Inductive';
                              dataPreference = 'Narrative';
                              fingerprint = 'Surgeon';
                              break;
                            case 2:
                              visualPreference = 'Minimal';
                              storyPreference = 'Inductive';
                              dataPreference = 'Narrative';
                              fingerprint = 'Surgeon';
                              break;
                          }
                          break;
                      }
                      break;
                  }
                  break;
              }
          }
      }
      if (!preferences.emailPreview) {
        context.commit(
          'setCurrentTestAudienceVisualpreference',
          visualPreference,
        );
        context.commit(
          'setCurrentTestAudienceStorypreference',
          storyPreference,
        );
        context.commit('setCurrentTestAudienceDatapreference', dataPreference);
        context.commit('setCurrentTestAudienceFingerprint', {
          fingerprint,
        });
      } else {
        return fingerprint;
      }
      // context.commit("persistCurrentTestAudience");
    },

    setCompanyDetails(context, companyInfo) {
      context.commit('SET_COMPANY_DETAILS', companyInfo);
    },
    setSavedQueryPayload(context, savedQueryPayload) {
      context.commit('SET_SAVED_QUERY_PAYLOD', savedQueryPayload);
    },
    setUpgradeBanner(context, showBanner) {
      context.commit('SET_UPGRADE_BANNER', showBanner);
    },
    setBannerActive(context, showBanner) {
      context.commit('SET_BANNER_ACTIVE', showBanner);
    },
  },
  persist: createPersistedState({
    key: 'vuex_users',
    paths: ['users.currentUser', 'users.uploadFlowCTAOrigin'],
  }),
};
