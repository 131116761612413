<template>
  <v-app :class="{ circle: currentIndex === 1 }">
    <!-- <template v-if="currentIndex === 1">
      <div class="circle-top">
        <div class="circle"></div>
        <div class="dot"></div>
      </div>
      <div class="circle-bottom">
        <div class="circle"></div>
        <div class="dot"></div>
      </div>
    </template> -->
    <v-main>
      <div v-if="!loading" class="homrapper">
        <Transition
          v-if="showTransition"
          name="fadeScreen"
          mode="out-in"
          :duration="900"
        >
          <div class="main-wrapper" :key="questionIndex">
            <div class="logo">
              <img
                src="/assets/img/prezent_logo.svg"
                alt
                width="200px"
                height="44px"
              />
            </div>
            <div v-if="firstSectionCompleted">
              <div class="loading-header">
                {{ getLoaderHeading }}
              </div>
              <img
                src="/assets/img/fingerPrintTest/fascinating_preferences.svg"
                alt
              />
            </div>
            <div v-if="personalPrefCompleted">
              <div class="loading-header">
                {{ getLoaderHeadingSecond }}
                <template v-if="!isPrivateAudience">
                  <br />
                  {{ $t('fpTest.yourColleaguesWillBePleased') }}
                </template>
              </div>
              <div class="loading-sub-header">
                {{ getLoaderSubHeadingSecond }}
              </div>
              <img
                src="/assets/img/fingerPrintTest/pleased_colleagues.svg"
                alt
              />
            </div>
            <div v-if="basicDetailCompleted">
              <div class="loading-header">
                <template v-if="isPrivateAudience">
                  {{ $t('fingerprint.fingerprintSharing') }}<br />{{
                    $t('fingerprint.fingerprintSharing2')
                  }}
                </template>
                <template v-else>
                  {{ $t('fingerprint.invitingColleagues') }}<br />{{
                    $t('fingerprint.invitingColleagues2')
                  }}
                </template>
              </div>
              <img
                src="/assets/img/fingerPrintTest/fascinating_preferences.svg"
                alt
              />
            </div>
            <div v-if="inviteColleagureCompleted">
              <div class="loading-header">
                <template>
                  {{ $t('fingerprint.sendingInvitation') }}
                </template>
              </div>
              <img
                src="/assets/img/fingerPrintTest/invitation_on_its_way.svg"
                alt
              />
            </div>
            <div
              v-if="progressScreenIndex !== -1 && !isZenSense"
              class="progress-loader"
            >
              <template v-for="(progress, index) of progressScreensFinal">
                <template>
                  <div v-show="index === progressScreenIndex" :key="index">
                    <div class="loading-header">
                      {{ getProgressHeader }}
                    </div>
                    <img :src="progress.banner" />
                  </div>
                </template>
              </template>
            </div>
            <div
              v-if="progressScreenIndex !== -1 && isZenSense"
              class="progress-loader"
            >
              <clip-loader
                :color="`#21a7e0`"
                :width="'60'"
                :height="'60'"
                :size="'20px'"
                class="cliploader"
              />
              <template v-for="(progress, index) of progressScreens">
                <template>
                  <div v-show="index === progressScreenIndex" :key="index">
                    <div class="header">
                      {{ $t(progress.header) }}
                    </div>
                    <img :src="progress.banner" />
                  </div>
                </template>
              </template>
            </div>
            <template v-else>
              <div
                class="section-wrapper first-screen"
                v-if="currentIndex === 0 && !isTemplateFlow"
              >
                <div class="pa-4 landing-page-content">
                  <div class="name-wrapper">
                    <span>
                      {{ $t('fingerprint.hi') }}
                      <span />
                      {{ getUserName() }},
                    </span>
                  </div>
                  <div
                    v-if="
                      currentUser &&
                      currentUser.user &&
                      currentUser.user.role === 'TrialUser'
                    "
                    class="highlight-normal"
                  >
                    {{ $t('fingerprint.twoWeekTrial') }}
                  </div>
                  <div v-else class="highlight-normal">
                    {{ $t('fingerprint.welcomeText') }}
                  </div>

                  <div class="sub-header">
                    {{ $t('fingerprint.subHeader') }}
                  </div>
                  <img
                    class="section-image"
                    src="/assets/img/fingerprint-test-initial.svg"
                    width="470px"
                    height="340px"
                    alt
                  />
                  <v-btn
                    class="lets-go-button"
                    color="primary"
                    rounded
                    width="110"
                    height="40"
                    @click="handleTemplateShow"
                    id="letsgo"
                  >
                    {{ $t('fingerprint.letsGo') }}
                  </v-btn>
                </div>
              </div>
              <template v-if="isNonBrandedTrialUser && isTemplateFlow">
                <TemplateDesignContainer
                  v-if="templateType === 'custom'"
                  @onTemplateComplete="handleTemplateDone"
                  @onBack="handleBackToTemplate"
                  @backToStd="handleSelectTemplate"
                  @handleSubmitErr="handleSubmitErr"
                ></TemplateDesignContainer>
                <div
                  class="transition-container"
                  v-else-if="templateType === 'transition'"
                  :class="templateType === 'transition' ? 'slide-animate' : ''"
                >
                  <p class="transition-title">
                    {{ $t('templateDesign.uploadTemplate.transtionLoadTitle') }}
                  </p>
                  <div class="img-container">
                    <img
                      :src="'/assets/img/templateDesign/continue-loader.svg'"
                    />
                  </div>
                </div>
                <TemplateType
                  :isTemplateSelected="backFromTmp"
                  @selectTemplateType="handleSelectTemplate"
                  v-else
                ></TemplateType>
              </template>

              <div
                class="section-wrapper second-screen collegue-finger-print-assesment"
                v-if="currentIndex === 1 && !privateAudienceName"
              >
                <div class="pa-4">
                  <div class="header">
                    {{
                      isTDFPFlow
                        ? $t('fingerprint.nonBrandedOpeningText')
                        : $t('fingerprint.openingText')
                    }}
                  </div>
                  <div class="description">
                    {{ $t('fingerprint.tailoringPresentation') }}
                  </div>
                  <div class="items">
                    <div class="item">
                      <div class="welcome-item-title">
                        {{ $t('fingerprint.visualPreference') }}
                      </div>
                      <img
                        src="/assets/img/welcome-item-vibrant.svg"
                        alt
                        class="img-pref"
                      />
                    </div>
                    <div class="item story-choice">
                      <div class="welcome-item-title">
                        {{ $t('fingerprint.storyChoice') }}
                      </div>
                      <img
                        src="/assets/img/welcome-item-deductive.svg"
                        alt
                        class="img-pref"
                      />
                    </div>
                    <div class="item">
                      <div class="welcome-item-title">
                        {{ $t('fingerprint.dataStyle') }}
                      </div>
                      <img
                        src="/assets/img/welcome-item-narrative.svg"
                        alt
                        class="img-pref"
                      />
                    </div>
                  </div>

                  <v-btn
                    class="primary-button"
                    rounded
                    color="#21a7e0"
                    height="48"
                    min-width="134"
                    @click="handleCurrentIndex"
                    id="discover"
                    :disabled="initialLoading"
                  >
                    {{ $t('fingerprint.myFingerprint') }}
                  </v-btn>
                  <div class="action-detail" v-if="!isTDFPFlow">
                    {{ $t('fingerprint.estimatedTime') }}
                  </div>
                  <div
                    class="final-description"
                    :class="isTDFPFlow ? 'space-desc' : ''"
                  >
                    {{ $t('fingerprint.changeAnswerLater') }}
                  </div>
                </div>
              </div>

              <div
                class="second-screen collegue-finger-print-assesment"
                v-if="currentIndex === 1 && privateAudienceName"
              >
                <div class="pa-4">
                  <div class="header">
                    <template v-if="isZenSense">
                      {{
                        $t('fingerprint.createFingerprintUsingZensense', {
                          audienceName: privateAudienceName,
                        })
                      }}
                    </template>
                    <template v-else>
                      {{
                        $t('fingerprint.createFingerprint', {
                          audienceName: privateAudienceName,
                        })
                      }}
                    </template>
                  </div>
                  <div class="sub-header">
                    <template v-if="isZenSense">
                      <div>
                        {{
                          $t('fingerprint.answerSimpleQuestions', {
                            audienceName: privateAudienceName,
                          })
                        }}
                      </div>
                    </template>
                    <template v-else>
                      <div>
                        {{
                          $t(
                            'fingerprint.answerFewQuestionsToBuildFingerprint',
                            {
                              audienceName: privateAudienceName,
                            },
                          )
                        }}
                      </div>
                    </template>
                  </div>
                  <div class="sub-header">
                    {{ $t('fingerprint.weWillUse') }}
                    {{
                      isZenSense
                        ? $t('fingerprint.thisInformation')
                        : $t('fingerprint.theFingerprint')
                    }}
                    {{
                      $t('fingerprint.tailorPrezentations', {
                        audienceName: privateAudienceName,
                      })
                    }}
                  </div>
                  <div class="items">
                    <div class="item">
                      <div class="welcome-item-title">
                        {{ $t('fingerprint.visualPreference') }}
                      </div>
                      <img
                        src="/assets/img/welcome-item-vibrant.svg"
                        alt
                        class="img-pref"
                      />
                    </div>
                    <div class="item">
                      <div class="welcome-item-title">
                        {{ $t('fingerprint.storyChoice') }}
                      </div>
                      <img
                        src="/assets/img/welcome-item-deductive.svg"
                        alt
                        class="img-pref"
                      />
                    </div>
                    <div class="item">
                      <div class="welcome-item-title">
                        {{ $t('fingerprint.dataStyle') }}
                      </div>
                      <img
                        src="/assets/img/welcome-item-narrative.svg"
                        alt
                        class="img-pref"
                      />
                    </div>
                  </div>

                  <v-btn
                    class="primary-button"
                    rounded
                    color="#21a7e0"
                    height="48"
                    min-width="134"
                    @click="handleCurrentIndex"
                  >
                    {{ $t('fingerprint.startFingerprintAssessment') }}
                  </v-btn>
                  <div class="action-detail">
                    {{ $t('fingerprint.estimateTime') }}
                    {{
                      isZenSense
                        ? $t('fingerprint.time1')
                        : $t('fingerprint.time2')
                    }}
                  </div>
                  <div class="final-description">
                    {{ $t('fingerprint.changeAnswersLater') }}
                  </div>
                </div>
              </div>
              <div
                class="question-wrapper"
                v-if="
                  currentIndex === 2 &&
                  !firstSectionCompleted &&
                  !personalPrefCompleted &&
                  !basicDetailCompleted &&
                  progressScreenIndex === -1 &&
                  !encourageShareFingerprint
                "
              >
                <div class="buttonAndHeading">
                  <div
                    class="back-button"
                    @click="handlePreview"
                    v-html="$t(back)"
                  />
                  <div class="question-header" v-if="privateAudienceName">
                    {{ sectionName }}
                  </div>
                  <div class="question-header" v-if="!privateAudienceName">
                    {{ sectionName }}
                  </div>
                </div>
                <div class="progress-wrapper">
                  <v-progress-linear
                    :value="
                      isZenSense ? questionProgressZenSense : questionProgress
                    "
                    :height="8"
                    rounded
                  />
                </div>
                <template v-if="!isZenSense">
                  <div class="question-content">
                    <div class="question-content-header">
                      <div
                        v-if="privateAudienceName"
                        class="header"
                        v-html="$t(questions[questionIndex].headeraud)"
                      />
                      <div
                        v-else
                        class="header"
                        v-html="$t(questions[questionIndex].header)"
                      />
                      <div
                        v-if="privateAudienceName"
                        class="sub-header"
                        :class="
                          questionIndex > 6 && questionIndex < 13
                            ? 'underline'
                            : ''
                        "
                      >
                        <p
                          v-html="$t(questions[questionIndex].subHeaderaud)"
                          @click="showContent = !showContent"
                          v-click-outside="closeContent"
                        ></p>
                      </div>
                      <div
                        v-else
                        class="sub-header"
                        :class="
                          questionIndex > 6 && questionIndex < 13
                            ? 'underline'
                            : ''
                        "
                      >
                        <p
                          v-html="$t(questions[questionIndex].subHeader)"
                          @click="showContent = !showContent"
                          v-click-outside="closeContent"
                        ></p>
                      </div>
                      <div
                        v-if="
                          showContent && questionIndex > 6 && questionIndex < 13
                        "
                        class="why-we-ask-content"
                      >
                        <div v-if="!isPrivateAudience">
                          {{ $t('fingerprint.easierForColleagues') }} <br />
                          {{ $t('fingerprint.findYouOnPrezent') }}<br />
                          {{ $t('fingerprint.personalizeYourExperience') }}
                        </div>
                        <div v-if="isPrivateAudience">
                          {{ $t('fingerprint.easierForColleagues') }} <br />
                          {{
                            $t(
                              'fingerprint.findYouOnPrezentpersonalizeYourExperience',
                            )
                          }}
                        </div>
                      </div>
                    </div>
                    <div class="images-wrapper" v-if="questionIndex < 6">
                      <TemplateCard
                        :src="questions[questionIndex].leftImage"
                        :selected="
                          answers[`question${questionIndex}`] === 1
                            ? true
                            : answers[`question${questionIndex}`] === 2
                            ? false
                            : null
                        "
                        :cardData="`question${questionIndex}`"
                        @selectedCardData="handleFirstCardData"
                      />
                      <TemplateCard
                        :src="questions[questionIndex].rightImage"
                        :selected="
                          answers[`question${questionIndex}`] === 2
                            ? true
                            : answers[`question${questionIndex}`] === 1
                            ? false
                            : null
                        "
                        :cardData="`question${questionIndex}`"
                        @selectedCardData="handleSecondCardData"
                      />
                    </div>
                    <!-- Question 6 is for Selections. All question id's <6 are for image selection-->
                    <div class="selections-wrapper">
                      <div v-if="questionIndex === 6" class="header">
                        {{ $t('fingerprint.selectFromCommonPreferences') }}
                      </div>
                      <div
                        v-if="questionIndex === 6"
                        :key="selectionKey"
                        class="selections"
                      >
                        <div
                          :class="`selection ${getClassName(selection)}`"
                          v-for="(selection, index) in selections"
                          :key="index"
                          @click="
                            handleQuestion(
                              `question${questionIndex}`,
                              selection,
                            )
                          "
                        >
                          <span>
                            <v-img
                              class="mx-4"
                              :src="
                                selection.image
                                  ? selection.image
                                  : 'assets/img/preferences-added_by_user.svg'
                              "
                              height="30px"
                              width="30px"
                            />
                          </span>
                          <v-tooltip top>
                            <template v-slot:activator="{ on }">
                              <span class="selection-title" v-on="on">
                                {{ selection.title }}
                              </span>
                            </template>
                            <span>{{ selection.title }}</span>
                          </v-tooltip>
                        </div>
                      </div>
                      <div
                        v-if="
                          questionIndex > 6 &&
                          questionIndex < 11 &&
                          questionIndex != 9
                        "
                        class="cards-wrapper"
                        :class="questionIndex == 8 ? 'industry' : ''"
                      >
                        <v-hover
                          v-for="item in category"
                          :key="item.name"
                          open-delay="50"
                        >
                          <div
                            class="cards"
                            :class="isSelectedCard(item) ? 'selected-card' : ''"
                            @click="selectItem(item)"
                          >
                            <v-tooltip top>
                              <template v-slot:activator="{ on }">
                                <div class="item-name" v-on="on">
                                  {{ item.name }}
                                </div>
                              </template>
                              <span>{{ item.name }}</span>
                            </v-tooltip>
                            <img
                              :src="
                                item.imagePath
                                  ? item.imagePath
                                  : '/assets/img/fingerPrintTest/custom.svg'
                              "
                              width="96px"
                              height="92px"
                            />
                          </div>
                        </v-hover>
                      </div>
                      <div v-if="questionIndex === 9" class="selections">
                        <div
                          v-for="item in category"
                          :key="item.name"
                          class="selection group-items"
                          :class="isSelectedCard(item) ? 'highlight' : ''"
                          @click="selectItem(item)"
                        >
                          <v-tooltip top>
                            <template v-slot:activator="{ on }">
                              <span class="selection-title" v-on="on">
                                {{ item.name }}
                              </span>
                            </template>
                            <span>{{ item.name }}</span>
                          </v-tooltip>
                        </div>
                      </div>
                      <div
                        v-if="questionIndex === 11"
                        class="job-function-wrapper"
                      >
                        <v-combobox
                          :return-object="false"
                          v-model="jobTitleSelected"
                          :items="jobTitles"
                          :menu-props="{
                            maxHeight: '200',
                            maxWidth: '400',
                            width: '400',
                          }"
                          color="black"
                          item-text="name"
                          item-value="name"
                          :placeholder="$t('fingerprint.jobTitle')"
                          clearable
                        />
                      </div>
                      <div
                        id="addCustomPref"
                        class="write-own-btn"
                        v-if="
                          !isCreating && questionIndex > 5 && questionIndex < 10
                        "
                      >
                        <div class="write-own-header">
                          <span class="pe-2">
                            <v-img
                              v-if="!inputFocused"
                              src="assets/img/preferences-Other.svg"
                              height="30px"
                              width="35px"
                            />
                            <v-img
                              v-else
                              src="assets/img/preferences-other-filled.svg"
                              height="30px"
                              width="35px"
                            />
                          </span>
                          <span>{{ $t('fingerprint.addYourOwn') }}</span>
                        </div>
                        <div class="new-pref-text">
                          <v-textarea
                            v-model="ownPreference"
                            :placeholder="
                              questionIndex === 6
                                ? $t('fingerprint.haveAClearAgenda')
                                : $t('fingerprint.typeYourOwn')
                            "
                            full-width
                            solo
                            two-line
                            @focus="setBackgroundColour"
                            @blur="removeBackgroundColour"
                          />
                        </div>
                        <div
                          v-if="ownPreference.length && isValidString"
                          class="add-pref"
                        >
                          <button @click="onEnter()">
                            <span>
                              <v-icon class="icon" v-text="`mdi-plus`" />
                            </span>
                            <span>{{ $t('fingerprint.add') }}</span>
                          </button>
                        </div>
                      </div>
                      <div class="create-own" v-if="isCreating">
                        <v-text-field
                          v-model="ownPreference"
                          :label="getPrefLabel"
                          single-line
                          color="#000"
                          @keyup.enter="onEnter"
                        />
                      </div>
                    </div>
                  </div>
                  <div v-if="questionIndex > 5" class="question-buttons">
                    <v-btn
                      id="show-fingerprint-for-btn--auto"
                      class="next-button"
                      rounded
                      color="#21a7e0"
                      @click="handleCurrentQuestionIndex"
                      v-if="!encourageShareFingerprint"
                      :disabled="isNextButtonDisabled"
                    >
                      {{ $t('fingerprint.next') }}
                    </v-btn>
                    <div
                      v-if="questionIndex > 6 && questionIndex < 13"
                      class="skip-button"
                      @click="handleSkipClick"
                      v-html="$t(skip)"
                    />
                  </div>
                </template>
                <template v-else>
                  <ZenSenseFingerPrint
                    :name="privateAudienceName"
                    :categories="
                      zensenseIndex === 0
                        ? isGroup
                          ? groupRoles
                          : roles
                        : businessFunctions
                    "
                    :selectedCategory="
                      zensenseIndex === 0
                        ? roleSelected
                        : businessFunctionSelected
                    "
                    :header="
                      zensSenseHeader[isGroup ? 'group' : 'individual'][
                        zensenseIndex
                      ].header
                    "
                    :subHeader="
                      zensSenseHeader[isGroup ? 'group' : 'individual'][
                        zensenseIndex
                      ].subHeader
                    "
                    @handleNext="handleZensenseNext"
                    @handleSelection="handleZensenseSelection"
                  ></ZenSenseFingerPrint>
                </template>
              </div>
              <div
                class="question-wrapper"
                v-if="
                  currentIndex === 2 &&
                  !firstSectionCompleted &&
                  !personalPrefCompleted &&
                  !basicDetailCompleted &&
                  !inviteColleagureCompleted &&
                  progressScreenIndex === -1 &&
                  encourageShareFingerprint
                "
              >
                <div class="buttonAndHeading">
                  <div
                    class="back-button"
                    @click="handlePreview"
                    v-html="$t(back)"
                  />
                  <div class="question-header" v-if="privateAudienceName">
                    {{ sectionName }}
                  </div>
                  <div class="question-header" v-if="!privateAudienceName">
                    {{ sectionName }}
                  </div>
                </div>
                <div class="progress-wrapper">
                  <v-progress-linear
                    :value="
                      isZenSense ? questionProgressZenSense : questionProgress
                    "
                    :height="8"
                    rounded
                  />
                </div>
                <div class="encourage-share-fingerprint">
                  <template v-if="questionIndex === 12 && !privateAudienceName">
                    <div class="encourage-title">
                      {{ $t('fingerprint.fingerprintsYouLikeToSee') }}
                    </div>
                    <div class="encourage-sub-title">
                      {{ $t('fingerprint.enterTheWorkEmail') }}
                    </div>
                    <div class="add-heart__title">
                      <v-icon medium :color="'blue'">{{ heartIcon }}</v-icon>
                      <div class="add-heart__title_subtext">
                        {{ $t('fingerprint.weDonateForEachFingerprint') }}
                        <span class="learn_more" @click="openShareMenu">{{
                          $t('fingerprint.learnMore')
                        }}</span>
                      </div>
                    </div>
                    <div
                      class="encourage-sub-title learn_more"
                      @click="showContent = !showContent"
                      v-click-outside="closeContent"
                    >
                      {{ $t('fingerprint.whyWeAsk') }}
                    </div>
                    <div v-if="showContent" class="why-we-ask-content">
                      <div>
                        {{ $t('fingerprint.whyWeAskContent1') }} <br /><br />
                        {{ $t('fingerprint.whyWeAskContent2') }}
                      </div>
                    </div>
                    <v-form
                      v-model="isNotValid"
                      ref="form"
                      lazy-validation
                      @submit.prevent="submit"
                      class="invite-form"
                    >
                      <v-container class="email-list-container">
                        <v-row
                          v-for="(colleague, index) in colleaguesList"
                          :key="index"
                        >
                          <v-col class="name-col col-3">
                            <v-text-field
                              :rules="firstnameRequired"
                              class="custom-text-field"
                              :label="$t('fingerprint.colleagueFirstName')"
                              :placeholder="
                                $t('fingerprint.colleagueFirstName')
                              "
                              required
                              v-model="colleaguesList[index].firstname"
                              :id="'name-input-' + index"
                            ></v-text-field>
                          </v-col>
                          <v-col class="name-col col-3">
                            <v-text-field
                              :rules="lastnameRequired"
                              :label="$t('fingerprint.colleagueLastName')"
                              :placeholder="$t('fingerprint.colleagueLastName')"
                              required
                              v-model="colleaguesList[index].lastname"
                              :id="'lastname-input-' + index"
                            ></v-text-field>
                          </v-col>
                          <v-col
                            class="email-col col-4"
                            :id="'email-col-' + index"
                          >
                            <v-text-field
                              :rules="emailRules"
                              :label="$t('fingerprint.colleagueWorkEmail')"
                              :placeholder="
                                $t('fingerprint.colleagueWorkEmail')
                              "
                              @keydown.enter="addColleague('enter')"
                              v-model="colleaguesList[index].id"
                              :id="'email-input-' + index"
                              class="label"
                              clearable
                              required
                              :maxlength="80"
                            ></v-text-field>
                          </v-col>
                          <v-col class="close-col col-1">
                            <v-btn
                              v-if="colleaguesList.length > 3"
                              class=""
                              icon
                              @click="removeColleague(index)"
                            >
                              <!-- <v-icon dark> mdi-close </v-icon> -->
                              <img src="/assets/img/cancel_btn.svg" />
                            </v-btn>
                          </v-col>
                        </v-row>
                      </v-container>

                      <div class="add-more">
                        <v-btn
                          text
                          color="primary"
                          class="add-btn"
                          @click="addColleague"
                          :disabled="colleaguesList.length >= 10"
                        >
                          <v-icon dark> mdi-plus </v-icon>
                          {{ $t('fingerprint.addMore') }}
                        </v-btn>
                      </div>
                    </v-form>
                  </template>
                  <template
                    v-if="
                      (questionIndex === 13 &&
                        inviteColleagureCompleted === false) ||
                      zensenseIndex === 1
                    "
                  >
                    <div class="encourage-title">
                      {{ getEncourageShareTitle }}
                    </div>
                    <div class="encourage-sub-title">
                      {{ getEncourageSubShareTitle }}
                    </div>
                    <ShareWithColleagues
                      v-if="audienceInfo && privateAudienceName"
                      :entityID="audienceInfo.uuid"
                      :updateBtnText="'fingerprint.viewFingerprint'"
                      :isPopUp="false"
                      @handleSave="onShareFingerprintSuccess"
                      :origin="'encourageShareFingerPrint'"
                    />
                  </template>
                </div>
                <!-- <EncourageShareFingerPrint
                v-if="audienceInfo && privateAudienceName"
                :entityID="audienceInfo.uuid"
                :share="onShareFingerprintSuccess"
                :updateBtnText="`Show fingerprint`"
              /> -->
                <share-finger-print
                  v-if="!privateAudienceName && questionIndex === 13"
                  type="form"
                  origin="fingerprint"
                  :updateText="$t('fingerprint.viewMyFingerprint')"
                  :loading="shareFingerprintLoading"
                  @updated="onShareFingerprintSuccess"
                  @subModalOpen="openEmailModal"
                />
                <div v-if="questionIndex === 12" class="question-buttons">
                  <v-btn
                    class="next-button"
                    rounded
                    color="#21a7e0"
                    @click="handleCurrentQuestionIndex"
                    :disabled="isNextButtonDisabledOnColleaguesShare"
                  >
                    {{ $t('fingerprint.next') }}
                  </v-btn>
                  <div
                    v-if="
                      questionIndex === 12 &&
                      isNotValid &&
                      colleaguesList.length &&
                      colleaguesList.filter((colleague) => {
                        if (
                          colleague.id &&
                          colleague.id !== '' &&
                          isValidEmail(colleague.id) &&
                          colleague.firstname &&
                          colleague.firstname !== '' &&
                          colleague.lastname &&
                          colleague.lastname !== ''
                        ) {
                          return {
                            ...colleague,
                          };
                        }
                      }).length > 0
                    "
                    @click="openEmailPreviewModal"
                    class="preview-email"
                  >
                    <span class="preview-email-text">
                      {{ $t('fingerprint.previewMail') }}</span
                    >
                    <v-tooltip
                      bottom
                      max-width="250"
                      content-class="tooltip-content"
                      fixed
                      open-delay="500"
                      close-delay="300"
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-icon
                          v-bind="attrs"
                          v-on="on"
                          class="icon"
                          v-text="`mdi-information-outline`"
                        />
                      </template>
                      <span>{{
                        $t(
                          'fingerprint.previewTheEmailThatIsSentToYourConnections',
                        )
                      }}</span>
                    </v-tooltip>
                  </div>
                  <div
                    class="skip-button"
                    @click="handleSkipClick"
                    v-html="$t(skip)"
                  />
                </div>
              </div>
              <div
                v-if="
                  currentIndex === 3 &&
                  audienceInfo &&
                  progressScreenIndex === -1
                "
              >
                <FingerPrint
                  :fingerprint="audienceInfo.fingerPrint"
                  :answers="answers"
                  :audience="audienceInfo"
                  :creator="creatorName"
                  :personalPrefs="personalPrefs"
                  :isPrivateAudience="isPrivateAudience"
                  :isAlreadyShared="isAlreadyShared"
                  :isGroup="isGroup"
                  :isDifferentCompany="isDifferentCompany"
                  :get-common-audience-tracking-data="
                    getCommonAudienceTrackingData
                  "
                  :get-matomo-fingerprint-data="getMatomoFingerprintData"
                  :currentCompany="company"
                  class="pt-10"
                  :origin="matomoFpOrigin"
                  :shareable="
                    isPrivateAudience || audienceInfo.id === currentUser.user.id
                  "
                  @handleClickShare="handleShareFingerPrint"
                  :showfpTest="true"
                  :hideActions="!isRetake"
                />
                <div v-if="privateAudienceName" class="back-to-home">
                  <v-btn
                    class="primary-button"
                    rounded
                    color="#21a7e0"
                    height="48"
                    :min-width="getDestination === 'Home' ? 100 : 134"
                    @click="handleGoHome"
                  >
                    {{ getDestination }}
                  </v-btn>
                  <div class="fingerprint-center-item" @click="gotoRetakeTest">
                    {{ $t('fingerprint.retakeFingerprintTest') }}
                  </div>
                </div>
                <div v-else>
                  <div
                    class="footer-description"
                    v-html="$t('fingerprint.zensenseAiWillUseTheFingerprints')"
                  ></div>
                  <v-btn
                    :class="`primary-button ${isRetake ? '' : 'new-user'}`"
                    color="primary"
                    rounded
                    :min-width="getDestination === 'Home' ? 100 : 134"
                    height="48"
                    @click="handleGoHome"
                  >
                    {{ getDestination }}
                  </v-btn>
                  <div class="fingerprint-center-item" @click="gotoRetakeTest">
                    {{ $t('fingerprint.retakeFingerprintTest') }}
                  </div>
                </div>
              </div>
            </template>
          </div>
        </Transition>
      </div>
      <div v-else class="loader-wrapper">
        <div class="logo-loading">
          <v-img
            src="/assets/img/prezent_stack.svg"
            alt
            width="248px"
            height="150px"
          />
        </div>
        <clip-loader
          v-if="loading"
          :loading="loading"
          :color="`#21a7e0`"
          :width="'60'"
          :height="'60'"
          :size="'50px'"
          class="cliploader"
        />
      </div>
    </v-main>
    <div v-if="!loading" class="privacy-policy-footer">
      <div class="privacy-policy">
        <a href="https://prezent.ai/privacy-policy" target="_blank">{{
          $t('fingerprint.privacyPolicy')
        }}</a>
      </div>
      <div>&#169; {{ $t('fingerprint.prezent') }}</div>
    </div>
  </v-app>
</template>

<script>
import ClipLoader from 'vue-spinner/src/ClipLoader.vue';
import { find, filter, findIndex, chunk, orderBy, snakeCase } from 'lodash';
import { mapState, mapActions, mapGetters } from 'vuex';
import { Auth } from 'aws-amplify';
import { mdiCardsHeart } from '@mdi/js';
import DOMPurify from 'dompurify';
import LearnMorePop from '../components/Main/PrezentWithHeart/LearnMorePop.vue';
import FingerPrint from '../components/Main/FingerPrints/FingerPrint.vue';
// import EncourageShareFingerPrint from '../components/Main/FingerPrints/EncourageShareFingerPrint.vue';
import ZenSenseFingerPrint from '../components/Main/FingerPrints/ZenSenseFingerPrint.vue';
import ShareFingerPrintDetail from '../components/Main/Profile/ShareFingerPrintDetail.vue';
import { AnalyticsHandler } from '@/components/common/Analytics/AnalyticsHandler';
import usersApi from '../API/users-api';
import Ipify from '@/services/ipify';
import {
  getFingerprintSlides,
  getPrivateAudience,
  getZenSenseAudience,
  getUserCompanyInfo,
  getFingerprintShareDetails,
  getDonationDetails,
  updateFingerprintShareDetails,
  getFingerPrintAdditionalData,
} from '../utils/api-helper';
import {
  clearCachedUser,
  capitalizeFirstLetter,
  getIsDifferentCompany,
} from '../utils/common.js';
import { getFileURL } from '@/utils/calyrex';
import { fpQuestions, fpSelections, fpTypes } from '@/mock/fingerprint';
import EmailPreviewModal from '../components/common/EmailPreviewModal.vue';
import ShareWithColleagues from '../components/common/ShareWithColleagues.vue';
import TemplateDesignContainer from './components/TemplateDesigner/TemplateDesignContainer.vue';
import TemplateType from './components/TemplateDesigner/TemplateType.vue';
import TemplateCard from './components/TemplateDesigner/TemplateCard.vue';
import {
  MatomoAnalyticsHandler,
  FP_TEST_WELCOME,
  FP_TEST_RETAKEN,
} from '../components/common/Analytics/MatomoAnalyticsHandler';
import ShareFingerPrint from '../components/Main/FingerPrints/ShareFingerPrint.vue';
import { trackGeneralEvents } from '../components/common/Analytics/GeneralEvents';
import {
  TD_AUDIENCE_ADD_TYPE_ZENSENSE,
  TD_AUDIENCE_TYPE,
  TD_AUD_BUSINESS_FUNCTION,
  TD_AUD_ROLE,
  TD_AUD_EMAIL,
  TD_AUD_FIRST_NAME,
  TD_AUD_ADDITION_TYPE,
  TD_AUDIENCE_TYPE_GROUP,
  TD_AUDIENCE_TYPE_INDIVIDUAL,
  TD_AUD_LAST_NAME,
  TD_AUD_COMPANY_NAME,
  TD_PLACEMENT,
  audiencePlacementMap,
  TD_FINGERPRINT_SHARING_SETTINGS,
  TD_AUDIENCE_ADD_TYPE_COMPLETE,
  TD_AUD_GROUP_SIZE,
  NA,
  TD_FLOW,
  TD_RETAKE_FINGERPRINT_TEST,
  TD_ADD_AUDIENCE_COLLEAGUE_CREATED,
  TD_FIRST_TIME_USER_LOGIN,
  TD_COMMON_COLUMN_NAME,
  TD_COMMON_COLUMN_VALUE,
  TD_FP_TEST,
  TD_REMINDER,
  TD_FP_RETAKE,
  TD_FP_NEW_AUDIENCE,
  TD_FINGERPRINT_INDUSTRY,
  TD_FINGERPRINT_GROUP,
  TD_FINGERPRINT_REGION,
  TD_FINGERPRINT_JOBTITLE,
  TD_ORIGIN_FINGERPRINT,
  TD_SHARE_THE_GIFT_OF_FINGERPRINT,
} from '../components/common/Analytics/MatomoTrackingDataHelper';
import {
  isEmojiExist,
  isOutsideCompanyEmail,
  isEmailValid,
} from '../components/utils';
import TrialApi from '../API/trial-api';
import AudienceAPI from '../API/audience-api';

const EMPTY_COLLEAGUE = {
  type: 'user',
  id: '',
};

const ENTITY_TYPE = 'user';
const MEMBER_REFERRAL_SOURCE = 'MemberReferral';

export default {
  name: 'Welcome',
  components: {
    ClipLoader,
    FingerPrint,
    // EncourageShareFingerPrint,
    ShareWithColleagues,
    ZenSenseFingerPrint,
    ShareFingerPrint,
    TemplateDesignContainer,
    TemplateType,
    TemplateCard,
  },
  watch: {
    currentUser(val) {
      if (val && !this.privateAudienceName) {
        this.audienceInfo = { ...this.currentUser.user };
      }
    },
    questionIndex() {
      this.showContent = false;
      setTimeout(() => {
        window.scroll({
          top: 0,
          left: 0,
          behavior: 'smooth',
        });
      }, 900);
    },
    showContent() {
      if (this.showContent) {
        trackGeneralEvents.fingerPrintTestWhyWeAskClick(this.currentUser, {
          [TD_PLACEMENT]: this.getPlacement(),
          [TD_COMMON_COLUMN_NAME]: TD_FLOW,
          [TD_COMMON_COLUMN_VALUE]: this.getfingerPrintFlow,
        });
      }
    },
  },
  data() {
    return {
      fp_origin_reminder: TD_REMINDER,
      fp_origin_add_audience: TD_FP_NEW_AUDIENCE,
      fp_origin_retake: TD_RETAKE_FINGERPRINT_TEST,
      initialLoading: false,
      agenda: '',
      loading: false,
      audienceInfo: null,
      origin: '',
      privateAudienceName: null,
      email: null,
      inputFocused: false,
      reTake: false,
      selectionKey: 0,
      currentIndex: 0,
      zensenseIndex: 0,
      roleSelected: [],
      businessFunctionSelected: [],
      shareFingerprintLoading: false,
      retakeCount: 0,
      basePath: '',
      question_progress: 0,
      questionIndex: -1,
      isNotValid: true,
      isDisabled: false,
      answers: {
        question6: [],
      },
      back: 'common.backButton',
      ownPreference: '',
      // ownPreferenceCount: 0,
      isCreating: false,
      selections: [],
      questions: [],
      encourageShareFingerprint: false,
      isAlreadyShared: false,
      isZenSense: null,
      progressScreenIndex: -1,
      isTemplateFlow: false,
      templateType: 'standard',
      progressScreens: [
        {
          header: 'fpTest.analyzingSimilarProfiles',
          banner: '/assets/img/transition-analyzing-similar-profiles.svg',
          waitTime: 2000,
        },
        {
          header: 'fpTest.evaluatingAudiencePreferences',
          banner: '/assets/img/transition-evaluating-audience-preferences.svg',
          waitTime: 2000,
        },
        {
          header: 'fpTest.generatingAudienceFingerprint',
          banner: '/assets/img/transition-generating-audience-fingerprint.svg',
          waitTime: 4000,
        },
      ],
      zensSenseHeader: {
        individual: [
          {
            header: 'fpTest.zensenseHeader1',
            subHeader: 'fpTest.zensenseSubHeader1',
          },
          {
            header: 'fpTest.zensenseHeader2',
            subHeader: 'fpTest.zensenseSubHeader2',
          },
        ],
        group: [
          {
            header: 'fpTest.zensenseHeaderAud1',
            subHeader: 'fpTest.zensenseSubHeaderAud1',
          },
          {
            header: 'fpTest.zensenseHeaderAud2',
            subHeader: 'fpTest.zensenseSubHeaderAud2',
          },
        ],
      },

      roles: [
        {
          name: 'Executive or CXO',
          icon: 'assets/img/role-cxo.svg',
        },
        {
          name: 'Manager or Team Lead',
          icon: 'assets/img/role-manager.svg',
        },
        {
          name: 'Individual Contributor',
          icon: 'assets/img/role-individual-contributor.svg',
        },
      ],
      groupRoles: [
        {
          name: 'Small (2-10)',
          icon: 'assets/img/groupSize-Small.svg',
        },
        {
          name: 'Medium (11-100)',
          icon: 'assets/img/groupSize-Medium.svg',
        },
        {
          name: 'Large (100+)',
          icon: 'assets/img/groupSize-Large.svg',
        },
      ],
      businessFunctions: [],
      heartIcon: mdiCardsHeart,
      skip: 'common.skip',
      showContent: false,
      firstSectionCompleted: false,
      personalPrefCompleted: false,
      basicDetailCompleted: false,
      inviteColleagureCompleted: false,
      generatingFingerPrint: false,
      industries: [],
      functions: [],
      groups: [],
      regions: [],
      jobTitles: [],
      collegueEmails: [],
      industrySelected: '',
      functionSelected: '',
      groupSelected: [],
      regionSelected: [],
      jobTitleSelected: '',
      progressScreenIndexFinal: -1,
      progressScreensFinal: [
        {
          banner: '/assets/img/fingerPrintTest/generating_fingerprint_01.svg',
          waitTime: 1000,
        },
        {
          banner: '/assets/img/fingerPrintTest/generating_fingerprint_02.svg',
          waitTime: 1000,
        },
        {
          banner: '/assets/img/fingerPrintTest/generating_fingerprint_03.svg',
          waitTime: 1000,
        },
        {
          banner: '/assets/img/fingerPrintTest/generating_fingerprint_04.svg',
          waitTime: 1000,
        },
      ],
      colleaguesList: [
        { ...EMPTY_COLLEAGUE },
        { ...EMPTY_COLLEAGUE },
        { ...EMPTY_COLLEAGUE },
      ],
      emailRules: [
        (v) => !isEmojiExist(v) || this.$t('warnings.emojisAreNotAllowed'),
        (v) => !!v || this.$t('warnings.emailRequired'),
        (v) => isEmailValid(v) || !v || this.$t('warnings.emailMustBeValid'),
        (v) =>
          isOutsideCompanyEmail(
            this.isEmailOutsideCompanyEnabled,
            this.currentUser.user.id,
            v,
            this.$t('warnings.enterAWorkEmail'),
          ),
        (v) =>
          this.isDuplicateEmail(v) || this.$t('warnings.emailAlreadyEntered'),
      ],
      firstnameRequired: [
        (v) => !!v || this.$t('warnings.firstNameRequired'),
        (v) => (v && v.length <= 50) || this.$t('warnings.inputMustBeLessThan'),
        (v) => !isEmojiExist(v) || this.$t('warnings.emojisAreNotAllowed'),
      ],
      lastnameRequired: [
        (v) => !!v || this.$t('warnings.lastNameRequired'),
        (v) => (v && v.length <= 50) || this.$t('warnings.inputMustBeLessThan'),
        (v) => !isEmojiExist(v) || this.$t('warnings.emojisAreNotAllowed'),
      ],
      matomoFPShareSettings: '',
      backFromTmp: false,
    };
  },
  computed: {
    ...mapState('users', [
      'currentTheme',
      'currentUser',
      'currentPrivateAudience',
      'waitForFP',
      'themes',
      'fingerPrintAdditionalData',
    ]),
    ...mapGetters('users', ['isEmailOutsideCompanyEnabled']),
    defaultColleaguesList() {
      return [
        { ...EMPTY_COLLEAGUE },
        { ...EMPTY_COLLEAGUE },
        { ...EMPTY_COLLEAGUE },
      ];
    },
    isTDFPFlow() {
      return (
        this.currentUser?.user?.role?.toLowerCase() === 'trialuser' &&
        this.currentUser?.company?.isNonBranded &&
        this.currentUser?.subscriptions?.[0]?.daysLeft >= 0 &&
        (this.currentUser?.user?.fingerPrint === null ||
          this.$route.query?.initialIndex === '1') &&
        this.isTDFeatureEnabled
      );
    },
    isNonBrandedTrialUser() {
      return (
        this.currentUser?.user?.role?.toLowerCase() === 'trialuser' &&
        this.currentUser?.company?.isNonBranded &&
        this.currentUser?.subscriptions?.[0]?.daysLeft >= 0 &&
        (this.currentUser?.user?.fingerPrint === null ||
          this.currentUser?.user?.showTemplateDesignerFlow === 0) &&
        this.isTDFeatureEnabled &&
        this.currentUser?.designerTheme?.status !== 'failed' &&
        !this.currentUser?.designerTheme?.jobSubmitted &&
        !this.isTDFPPopulated
      );
    },
    isTDFPPopulated() {
      return (
        this.currentUser?.designerTheme &&
        this.currentUser?.designerTheme?.fingerprintPreferences?.length > 0
      );
    },
    isTDFeatureEnabled() {
      const feature = this.currentUser.features.find(
        (f) => f.feature_name === 'template-designer',
      );
      return feature && feature.enabled;
    },
    isExistingPref() {
      return this.selections.some(
        (pref) =>
          pref?.title === this.ownPreference?.replace(/\s+/g, ' ').trim(),
      );
    },
    isAlreadySelectedPref() {
      return this.personalPrefs.some(
        (pref) => pref === this.ownPreference?.replace(/\s+/g, ' ').trim(),
      );
    },
    isValidString() {
      return this.ownPreference.trim() !== '';
    },
    showTransition() {
      if (!this.loading && this.questionIndex > -1) {
        return true;
      }
      return false;
    },
    getEncourageShareTitle() {
      if (this.isPrivateAudience)
        return this.$t('fpTest.accessToThisFingerprint');
      return this.$t('fingerprint.WhoWouldYouLikeToHaveAccess');
    },
    getEncourageSubShareTitle() {
      if (this.isPrivateAudience)
        return this.$t('fpTest.prezentationsYourAudienceLove');
      return this.$t('fingerprint.buildPresentationYouLove');
    },
    questionProgressZenSense() {
      if (this.encourageShareFingerprint) return 100;
      if (this.zensenseIndex === 0) return 33;
      if (this.zensenseIndex === 1) return 66;
      return 0;
    },
    getfingerPrintFlow() {
      if (this.isPrivateAudience) return TD_ADD_AUDIENCE_COLLEAGUE_CREATED;
      if (!this.isRetake) return TD_FIRST_TIME_USER_LOGIN;
      if (!this.isPrivateAudience) return TD_RETAKE_FINGERPRINT_TEST;
      return '';
    },
    category() {
      if (this.questionIndex === 7) return this.industries;
      if (this.questionIndex === 8) return this.functions;
      if (this.questionIndex === 9) {
        const groupsDefined = this.fingerPrintAdditionalData?.groups.find(
          (item) => item.function === this.functionSelected,
        );
        if (groupsDefined?.groupValues.length) {
          console.log('groupsDefined', groupsDefined?.groupValues);
          const uniqueList = groupsDefined?.groupValues;
          uniqueList.sort((a, b) => {
            if (a.id && b.id) {
              return a.name.localeCompare(b.name);
            }
            if (!a.id && b.id) {
              return 1;
            }
            if (a.id && !b.id) {
              return -1;
            }
            return 0;
          });
          return uniqueList;
        }
        return this.groups;
      }
      if (this.questionIndex === 10) return this.regions;
      return [];
    },
    questionProgress() {
      if (this.questionIndex === 0) return 16;
      if (this.questionIndex === 1) return 32;
      if (this.questionIndex === 2) return 50;
      if (this.questionIndex === 3) return 64;
      if (this.questionIndex === 4) return 80;
      if (this.questionIndex === 5) return 100;
      if (this.questionIndex === 6) return 16;
      if (this.questionIndex === 7) return 32;
      if (this.questionIndex === 8) return 50;
      if (this.questionIndex === 9) return 64;
      if (this.questionIndex === 10) return 80;
      if (this.questionIndex === 11) return 100;
      if (this.questionIndex === 12) return 50;
      if (this.questionIndex === 13) return 100;
      return 0;
    },
    getLoaderHeading() {
      if (this.isPrivateAudience)
        return this.$t('fpTest.preferencesAreQuiteFascinating', {
          name: this.privateAudienceName,
        });
      return this.$t('fpTest.yourPreferencesAreFascinating', {
        name: this.getUserName(),
      });
    },
    getLoaderHeadingSecond() {
      if (this.isPrivateAudience)
        return DOMPurify.sanitize(
          this.$t('fpTest.thanksForSharingPreferences', {
            name: this.privateAudienceName,
          }),
        );
      return DOMPurify.sanitize(
        this.$t('fpTest.thanksForSharingYourPreferences', {
          name: this.getUserName(),
        }),
      );
    },
    getProgressHeader() {
      if (this.isPrivateAudience)
        return this.$t('fpTest.generatingFingerprint');
      return this.$t('fpTest.generatingYourFingerprint');
    },
    getLoaderSubHeadingSecond() {
      if (this.isPrivateAudience)
        return this.$t('fpTest.answerFewQuestionsForAudience', {
          name: this.privateAudienceName,
        });
      return this.$t('fingerprint.personalizeYourPrezentExperience');
    },
    sectionName() {
      if (this.privateAudienceName) {
        if (this.isZenSense && this.encourageShareFingerprint) {
          return this.$t('navbarActions.shareFingerprint');
        }
        if (this.questionIndex < 6) {
          return this.$t('fingerprint.audienceFingerprint', {
            audienceName: this.privateAudienceName,
          });
        }
        if (this.questionIndex > 5 && this.questionIndex < 12) {
          return this.$t('fingerprint.basicDetails');
        }
        return this.$t('navbarActions.shareFingerprint');
      }
      if (!this.privateAudienceName) {
        if (this.questionIndex < 6) {
          return this.$t('profile.fingerprintTabContent.myFingerprint');
        }
        if (this.questionIndex > 5 && this.questionIndex < 12) {
          return this.$t('fingerprint.basicDetails');
        }
        return this.$t('fingerprint.addColleaguesAndShareFingerprint');
      }
      return '';
    },
    isNextButtonDisabledOnColleaguesShare() {
      let errors = 0;
      let validEntries = 0;
      this.colleaguesList.forEach((stf) => {
        if (
          stf.firstname &&
          stf.firstname !== '' &&
          !isEmojiExist(stf.firstname) &&
          stf.lastname &&
          stf.lastname !== '' &&
          !isEmojiExist(stf.lastname) &&
          stf.id &&
          stf.id !== '' &&
          !isEmojiExist(stf.id) &&
          this.isValidEmail(stf.id)
        ) {
          validEntries++;
        } else if (
          (stf.firstname ||
            stf.lastname ||
            (stf.id && this.isValidEmail(stf.id))) &&
          !(
            stf.firstname &&
            stf.lastname &&
            stf.id &&
            this.isValidEmail(stf.id)
          )
        ) {
          errors++;
        }
      });
      return validEntries < 1 || errors > 0;
    },
    isNextButtonDisabled() {
      if (this.questionIndex === 6)
        return this.answers[`question${this.questionIndex}`].length < 1;
      if (this.questionIndex === 7) return !this.industrySelected;
      if (this.questionIndex === 8) return !this.functionSelected;
      if (this.questionIndex === 9) return !this.groupSelected.length;
      if (this.questionIndex === 10) return !this.regionSelected.length;
      if (this.questionIndex === 11) return !this.jobTitleSelected;
      // if (this.questionIndex === 12) {
      //   let isEmpty = false;
      //   this.colleaguesList.forEach((colleague) => {
      //     if (
      //       !(
      //         !colleague.email &&
      //         !colleague.firstname &&
      //         !colleague.lastname
      //       ) &&
      //       !(colleague.email && colleague.firstname && colleague.lastname)
      //     ) {
      //       isEmpty = true;
      //     }
      //   });
      //   if (isEmpty) {
      //     return true;
      //   }
      //   const tempColleague = this.colleaguesList.filter(
      //     (ele) => ele.email && this.isValidEmail(ele.email),
      //   );
      //   if (
      //     tempColleague.length ===
      //       this.colleaguesList.filter((ele) => ele.email).length &&
      //     tempColleague.length !== 0
      //   ) {
      //     return false;
      //   }
      // }
      return true;
    },
    getDestination() {
      if (this.origin && this.origin.toLowerCase() === 'np') {
        return this.$t('fpTest.backToBuild');
      }
      if (this.origin && this.origin.toLowerCase() === 'zentrends') {
        return this.$t('fingerprint.backToFingerprints');
      }
      if (this.origin && this.origin.toLowerCase() === 'slides') {
        return this.$t('slides.backToSlides');
      }
      if (this.origin && this.origin.toLowerCase() === 'prezentations') {
        return this.$t('build.step3.backToPrezentationText');
      }

      if (this.origin && this.origin.toLowerCase() === 'slides') {
        return '';
      }
      if (
        this.origin &&
        this.origin.toLowerCase() === 'overnight-prezentations'
      ) {
        return this.$t('fpTest.backToOp');
      }
      if (this.origin && this.origin.toLowerCase() === 'generate') {
        return this.$t('fpTest.backToGenerate');
      }
      if (this.origin && this.origin.toLowerCase() === 'synthesis') {
        return this.$t('fpTest.backToSynthesis');
      }
      if (this.origin && this.origin.toLowerCase() === 'redesign') {
        return this.$t('fpTest.backToRedesign');
      }
      if (this.reTake) {
        return this.$t('fpTest.backToPrezent');
      }
      return this.$t('fpTest.continueToPrezent');
    },
    isGroup() {
      return this.audienceInfo?.lastName?.toLowerCase() === 'group';
    },
    getPrefLabel() {
      if (this.privateAudienceName) {
        return this.$t('profile.enterPreferenceOfAudience');
      }
      return this.$t('fingerprint.enterYourOwn');
    },
    currentAnimalInfo() {
      return find(this.animals, { id: this.currentAnimal });
    },
    isRetake() {
      return !!this.$route.query.initialIndex;
    },
    personalPrefs() {
      return this.isZenSense
        ? this.audienceInfo.personalPreference
        : this.answers.question6.map((item) => item.title);
    },
    creatorName() {
      if (this.audienceInfo.userID === this.currentUser.user.id) return 'me';
      return this.audienceInfo.userID;
    },
    isPrivateAudience() {
      return this.audienceInfo.id !== this.currentUser.user.id;
    },
    company() {
      if (this.currentUser.company?.displayName) {
        return this.currentUser.company.displayName;
      }
      return capitalizeFirstLetter(this.currentUser.company.name);
    },
    isDifferentCompany() {
      return getIsDifferentCompany(this.audienceInfo, this.currentUser);
    },
    matomoFpOrigin() {
      if (this.$route?.query?.remider) {
        return this.fp_origin_reminder;
      }

      return this.privateAudienceName
        ? this.fp_origin_add_audience
        : this.fp_origin_retake;
    },
  },

  methods: {
    ...mapActions('users', [
      'setCurrentUserFingerprint',
      'setCurrentUserPersonalPreferences',
      'setPrivateAudienceFingerprint',
      'setPrivateAudiencePersonalPreferences',
      'setCurrentUser',
      'setCurrentPrivateAudience',
      'setCurrentTheme',
      'setFingerPrintAdditionalDetails',
    ]),
    // ...mapActions('categories', ['loadCategories']),
    async handleEmailChange(value, index) {
      this.colleaguesList[index].email = value.toLowerCase();
      this.colleaguesList[index].id = value.toLowerCase();
      this.isDisabled = true;
      await this.handleCompany(value, index);
    },
    handleFirstCardData(cardData) {
      this.handleQuestion(cardData, 1);
    },
    handleSecondCardData(cardData) {
      this.handleQuestion(cardData, 2);
    },
    isEmailValid(v) {
      if (v) {
        return (
          this.emailRules[0](v) === true &&
          this.emailRules[1](v) === true &&
          this.emailRules[2](v) === true &&
          this.emailRules[3](v) === true
        );
      }
      return false;
    },
    async handleCompany(value, index) {
      if (this.isEmailValid(value)) {
        const companyDomain = value.substring(value.lastIndexOf('@') + 1);

        try {
          const data = await AudienceAPI.methods.getCompanyNameByDomain(
            companyDomain,
          );
          if (data && data.companyName) {
            this.colleaguesList[index].company = data.companyName;
          } else {
            const cNameArray = companyDomain.split('.');
            const cName = cNameArray && cNameArray.length ? cNameArray[0] : '';

            this.colleaguesList[index].company = capitalizeFirstLetter(cName);
          }
          this.isDisabled = false;
        } catch (e) {
          console.log('error', e);
          this.isDisabled = true;
        }
      }
    },
    verifyCompanyDomain(userEmail, enteredEmail) {
      if (this.isEmailOutsideCompanyEnabled) {
        return true;
      }
      const [, companyDomain] = userEmail.split('@');
      const [, domain] = enteredEmail.split('@');

      if (domain === companyDomain) {
        return true;
      }
      return false;
    },
    isValidEmail(v) {
      if (
        isEmailValid(v) &&
        this.isDuplicateEmail(v) &&
        this.verifyCompanyDomain(this.currentUser.user.id, v)
      ) {
        return true;
      }
      return false;
    },
    closeContent() {
      this.showContent = false;
    },
    isDuplicateEmail(v) {
      if (v) {
        return !(
          this.colleaguesList.filter((item) => item.id === v).length > 1
        );
      }

      return true;
    },
    addColleague(from) {
      if (this.colleaguesList.length < 10) {
        this.colleaguesList.push({ ...EMPTY_COLLEAGUE });

        setTimeout(() => {
          const element = this.$el.querySelector(
            `#name-input-${this.colleaguesList.length - 1}`,
          );

          if (element) {
            if (from === 'enter') {
              element.focus();
            }
            element.scrollIntoView({
              behavior: 'smooth',
            });
          }
        }, 100);
      }
    },
    getGeneralGroups() {
      const generalGroups = this.fingerPrintAdditionalData?.groups.find(
        (item) => item.function === 'General',
      );
      return generalGroups?.groupValues;
    },
    loadProgressScreensFinal(index) {
      if (index === 3) {
        this.progressScreenIndex = -1;
        this.encourageShareFingerprint = false;
        return;
      }
      setTimeout(() => {
        this.loadProgressScreensFinal(this.progressScreenIndex++);
      }, this.progressScreensFinal[this.progressScreenIndex].waitTime);
    },
    handleSkipClick() {
      trackGeneralEvents.fingerPrintTestSkipClick(this.currentUser, {
        [TD_PLACEMENT]: this.getPlacement(),
        [TD_COMMON_COLUMN_NAME]: TD_FLOW,
        [TD_COMMON_COLUMN_VALUE]: this.getfingerPrintFlow,
      });
      if (this.questionIndex === 7) this.industrySelected = '';
      if (this.questionIndex === 8) {
        this.functionSelected = '';
        this.groupSelected = [];
      }
      if (this.questionIndex === 9) this.groupSelected = [];
      if (this.questionIndex === 10) {
        this.regionSelected = [];
        this.handleCurrentQuestionIndex();
        return;
      }
      if (this.questionIndex === 11) {
        this.jobTitleSelected = '';
        this.basicDetailCompleted = true;
        setTimeout(() => {
          this.basicDetailCompleted = false;
        }, 3000);
        this.encourageShareFingerprint = true;
      }
      if (this.questionIndex === 11 && this.privateAudienceName) {
        this.questionIndex++;
      }
      this.questionIndex++;
    },
    selectItem(item) {
      if (this.questionIndex === 7 && this.industrySelected !== item?.name) {
        this.industrySelected = item?.name;
      } else if (this.questionIndex === 7) this.industrySelected = '';
      if (this.questionIndex === 8 && this.functionSelected !== item?.name) {
        this.functionSelected = item?.name;
      } else if (this.questionIndex === 8) this.functionSelected = '';
      if (
        this.questionIndex === 9 &&
        !this.groupSelected.includes(item?.name)
      ) {
        this.groupSelected.push(item?.name);
      } else if (this.questionIndex === 9) {
        const index = this.groupSelected.indexOf(item?.name);
        this.groupSelected.splice(index, 1);
      }
      if (
        this.questionIndex === 10 &&
        !this.regionSelected.includes(item?.name)
      ) {
        this.regionSelected.push(item?.name);
      } else if (this.questionIndex === 10) {
        const index = this.regionSelected.indexOf(item?.name);
        this.regionSelected.splice(index, 1);
      }
      if (this.questionIndex === 11 && this.jobTitleSelected !== item?.name) {
        this.jobTitleSelected = item?.name;
      } else if (this.questionIndex === 11) this.jobTitleSelected = '';
    },
    isSelectedCard(item) {
      if (this.questionIndex === 7) return this.industrySelected === item?.name;
      if (this.questionIndex === 8) return this.functionSelected === item?.name;
      if (this.questionIndex === 9)
        return this.groupSelected.includes(item?.name);
      if (this.questionIndex === 10)
        return this.regionSelected.includes(item?.name);
      if (this.questionIndex === 11)
        return this.jobTitleSelected === item?.name;
      return false;
    },
    async openShareMenu() {
      trackGeneralEvents.sharetrialsIconclickLearnmore(this.currentUser);
      trackGeneralEvents.addnewaudienceLearnMoreV2(this.currentUser, {
        [TD_COMMON_COLUMN_NAME]: TD_FLOW,
        [TD_COMMON_COLUMN_VALUE]: this.getfingerPrintFlow,
        [TD_PLACEMENT]: TD_FP_TEST,
      });
      await getDonationDetails()
        .then(async (res) => {
          if (res.data.contributors && res.data.contributors.length > 0) {
            await res.data.contributors.forEach(async (learner) => {
              learner.profileImageUrl = '';
              if (learner.profileImage && !learner.profileImageUrl) {
                getFileURL(
                  null,
                  `public/${learner.profileImage}`,
                  null,
                  'storage',
                ).then((s3url) => {
                  learner.profileImageUrl = s3url;
                });
              }
            });
          }
          this.contributors = res.data.contributors;
          this.totalCount = Number(res.data.totalCount);
        })
        .catch((err) => console.log(err));
      this.$emit('subModalOpen', false);
      this.$modal.show(
        LearnMorePop,
        {
          contributors: this.contributors,
          totalCount: this.totalCount,
          origin: 'Welcome',
          onVisitSite: () => {
            // trackGeneralEvents.sharetrialsIconclickLearnmoreVisitsite(
            //   this.currentUser,
            // );
            trackGeneralEvents.addnewaudienceLearnMoreVisitSiteV2(
              this.currentUser,
              {
                [TD_COMMON_COLUMN_NAME]: TD_FLOW,
                [TD_COMMON_COLUMN_VALUE]: this.getfingerPrintFlow,
                [TD_PLACEMENT]: TD_FP_TEST,
              },
            );
          },
        },
        {
          name: 'LearnMorePop',
          classes: 'v-application',
          width: '520px',
          height: 'auto',
          styles: {
            borderRadius: '20px',
            padding: '20px',
            'max-height': '800px',
            'min-height': '300px',
            'overflow-y': 'auto',
          },
        },
        // {
        //   'before-close': this.subModalClosed,
        // },
      );
    },
    closeShareMenu() {
      this.showShare = false;
    },
    removeColleague(index) {
      if (this.colleaguesList.length >= 1) {
        this.colleaguesList = this.colleaguesList.filter((_, i) => i !== index);
      }
    },
    handleShareFingerPrint() {
      this.$modal.show(
        ShareFingerPrintDetail,
        {
          entityID: this.audienceInfo.uuid,
          share: this.getSharedData,
          showfpTest: true,
        },
        {
          name: 'ShareFingerPrintDetail',
          width: 700,
          height: 'auto',
          minHeight: 300,
          styles: { borderRadius: '20px' },
        },
      );
    },
    setBackgroundColour() {
      this.inputFocused = true;
      document.getElementById('addCustomPref').style.background = '#f5fcff';
      trackGeneralEvents.fingerPrintTestAddYourOwnClick(this.currentUser, {
        [TD_PLACEMENT]: this.getPlacement(),
        [TD_COMMON_COLUMN_NAME]: TD_FLOW,
        [TD_COMMON_COLUMN_VALUE]: this.getfingerPrintFlow,
      });
    },
    removeBackgroundColour() {
      this.inputFocused = false;
      document.getElementById('addCustomPref').style.background = '#FFFFFF';
    },
    getFingerPrintData(fingerPrint) {
      return fpTypes.find(
        (fprint) => fprint.title === fingerPrint?.toLowerCase(),
      );
    },
    async openEmailModal() {
      const fingerPrint = await this.setCurrentUserFingerprint({
        ...this.answers,
        emailPreview: true,
      });
      const fingerprintData = this.getFingerPrintData(fingerPrint);
      this.$emit('subModalOpen', false);
      this.$modal.show(
        EmailPreviewModal,
        {
          emailApiPayload: {
            // type: 'send-fingerprint-request-new',
            type: 'fingerprint_share_dml',
            meta: {
              firstName: this.audienceInfo.firstName,
              lastName: this.audienceInfo.lastName,
              fingerPrint,
              fingerPrintDescription: fingerprintData.description,
              visualPreferenceImgURL: fingerprintData.preferences[0].logo,
              visualPreference: fingerprintData.preferences[0].preference,
              visualPreferenceText: fingerprintData.preferences[0].oneLine,
              storyPreferenceImgURL: fingerprintData.preferences[1].logo,
              storyPreference: fingerprintData.preferences[1].preference,
              storyPreferenceText: fingerprintData.preferences[1].oneLine,
              dataPreferenceImgURL: fingerprintData.preferences[2].logo,
              dataPreference: fingerprintData.preferences[2].preference,
              dataPreferenceText: fingerprintData.preferences[2].oneLine,
              fingerPrintAvatarURL: fingerprintData.logo,
            },
          },
          heading: 'Fingerprint share email preview',
        },
        {
          name: 'EmailPreviewModal',
          classes: 'v-application',
          width: '850px',
          height: '600px',
          styles: {
            borderRadius: '20px',
            padding: '20px',
          },
        },
        {
          'before-close': this.subModalClosed,
        },
      );
    },
    async getSharedData() {
      await this.checkSharedStatus();
      const audienceTrackingData = {
        ...this.getCommonAudienceTrackingData(),
        ...this.getMatomoFingerprintData(),
        [TD_FINGERPRINT_SHARING_SETTINGS]: this.matomoFPShareSettings,
        [TD_COMMON_COLUMN_NAME]: TD_FLOW,
        [TD_COMMON_COLUMN_VALUE]: this.getfingerPrintFlow,
      };

      trackGeneralEvents.addnewaudienceShowfingerprintShareUpdate(
        this.currentUser,
        audienceTrackingData,
      );
    },
    async checkSharedStatus() {
      try {
        if (this.audienceInfo.uuid) {
          const { data } = await getFingerprintShareDetails(
            this.audienceInfo.uuid,
          );
          this.isAlreadyShared = data.length > 0;
          if (data.length) {
            const types = data.map((entity) => entity.sharedEntityType);
            this.matomoFPShareSettings = types?.toString().replaceAll(',', '/');
          } else {
            this.matomoFPShareSettings = 'private';
          }
        }
      } catch (e) {
        this.isAlreadyShared = false;
      }
    },
    handleZensenseSelection(index) {
      if (this.zensenseIndex === 0) {
        this.roleSelected = [index];
      } else {
        const itemIndex = this.businessFunctionSelected.indexOf(index);
        if (itemIndex === -1) {
          this.businessFunctionSelected.push(index);
        } else {
          this.businessFunctionSelected.splice(itemIndex, 1);
        }
      }
    },
    handleZensenseNext() {
      if (this.zensenseIndex === 0) {
        this.zensenseIndex++;
      } else {
        this.handleCurrentIndex();
      }
      this.question_progress += 50;
    },
    getUserName() {
      if (this.currentUser) {
        return this.currentUser.user.firstName
          ? this.currentUser.user.firstName
          : this.currentUser.user.cognitoID;
      }
      return '';
    },
    onEnter() {
      trackGeneralEvents.fingerPrintTestAddYourOwnAddClick(this.currentUser, {
        [TD_PLACEMENT]: this.getPlacement(),
        [TD_COMMON_COLUMN_NAME]: TD_FLOW,
        [TD_COMMON_COLUMN_VALUE]: this.getfingerPrintFlow,
      });
      if (this.ownPreference) {
        this.ownPreference = this.ownPreference?.replace(/\s+/g, ' ').trim();
      }
      if (this.questionIndex === 7) {
        this.industries.push({
          name: this.ownPreference,
        });
        this.industrySelected = this.ownPreference;
      }
      if (this.questionIndex === 8) {
        this.functions.push({
          name: this.ownPreference,
        });
        this.functionSelected = this.ownPreference;
      }
      if (this.questionIndex === 9) {
        const groupsDefined = this.fingerPrintAdditionalData?.groups.find(
          (item) => item.function === this.functionSelected,
        );
        if (this.functionSelected && groupsDefined) {
          groupsDefined?.groupValues.push({
            icon: 'no-icon',
            name: this.ownPreference,
          });
        } else {
          this.groups.push({
            name: this.ownPreference,
          });
        }
        this.groupSelected.push(this.ownPreference);
      }
      if (this.questionIndex === 6) {
        if (!this.isExistingPref)
          this.selections.push({
            value: this.ownPreference.toLowerCase(),
            title: this.ownPreference,
          });
        if (!this.isAlreadySelectedPref)
          this.handleQuestion('question6', {
            value: this.ownPreference.toLowerCase(),
            title: this.ownPreference,
          });
        this.selectionKey++;
      }
      this.ownPreference = '';
    },
    handlePreview() {
      if (this.encourageShareFingerprint && this.questionIndex === 12) {
        this.encourageShareFingerprint = false;
        this.question_progress -= this.isZenSense ? 50 : 16;
        this.questionIndex--;
        return;
      }
      if (
        this.encourageShareFingerprint &&
        this.questionIndex === 13 &&
        this.isGroup
      ) {
        this.encourageShareFingerprint = false;
        this.questionIndex = 10;
        return;
      }
      if (this.encourageShareFingerprint && this.isZenSense) {
        this.encourageShareFingerprint = false;
        return;
      }
      if (this.encourageShareFingerprint && this.isPrivateAudience) {
        this.encourageShareFingerprint = false;
        this.questionIndex = 11;
        return;
      }
      if (this.zensenseIndex === 1) {
        this.zensenseIndex--;
        return;
      }
      if (this.isZenSense && this.zensenseIndex === 0) {
        this.currentIndex--;
        return;
      }
      // if (this.currentIndex > 0) {
      //   if (this.questionIndex === 0) {
      //     this.currentIndex--;
      //   } else {
      //     this.questionIndex--;
      //     if (this.questionIndex === 6) {
      //       this.question_progress -= 14;
      //     } else {
      //       this.question_progress -= 14;
      //     }
      //   }
      // }
      if (this.currentIndex > 0) {
        if (this.questionIndex === 0) {
          this.currentIndex--;
        } else {
          this.questionIndex--;
        }
      }
    },

    getCommonAudienceTrackingData() {
      try {
        const { firstName, lastName, companyName, id } = this.audienceInfo;
        let zenSenseData = {};
        const audienceType = this.isGroup
          ? TD_AUDIENCE_TYPE_GROUP
          : TD_AUDIENCE_TYPE_INDIVIDUAL;
        let addAudienceType;

        if (this.isZenSense) {
          const role = this.isGroup
            ? this.groupRoles[this.roleSelected[0]].name
            : this.roles[this.roleSelected[0]].name;
          const businessFunction = this.businessFunctions
            .filter((_, index) => this.businessFunctionSelected.includes(index))
            .map((i) => i.name);
          addAudienceType = TD_AUDIENCE_ADD_TYPE_ZENSENSE;
          zenSenseData = {
            [TD_AUD_ROLE]: role.toString().replaceAll(',', '/'),
            [TD_AUD_BUSINESS_FUNCTION]: businessFunction
              .toString()
              .replaceAll(',', '/'),
            [TD_AUD_GROUP_SIZE]: this.isGroup ? role : NA,
          };
        } else if (this.privateAudienceName) {
          addAudienceType = TD_AUDIENCE_ADD_TYPE_COMPLETE;
        }

        return {
          [TD_AUDIENCE_TYPE]: audienceType,
          [TD_AUD_ADDITION_TYPE]: addAudienceType,
          [TD_AUD_FIRST_NAME]: firstName,
          [TD_AUD_LAST_NAME]: lastName,
          [TD_AUD_COMPANY_NAME]: companyName,
          [TD_AUD_EMAIL]: id,
          [TD_PLACEMENT]: audiencePlacementMap[this.origin],
          [TD_FINGERPRINT_SHARING_SETTINGS]: this.matomoFPShareSettings,
          ...zenSenseData,
        };
      } catch (e) {
        console.log(e);
        return {};
      }
    },

    async onShareFingerprintSuccess(shareUpdate) {
      const types = shareUpdate?.sharedEntities?.map((entity) => entity.type);
      this.matomoFPShareSettings =
        types?.toString().replaceAll(',', '/') || 'private';
      if (!this.isPrivateAudience) {
        this.matomoFPShareSettings =
          types?.toString().replaceAll(',', '/') || 'company';
      }
      const audienceTrackingData = {
        ...this.getCommonAudienceTrackingData(),
        [TD_FINGERPRINT_SHARING_SETTINGS]: this.matomoFPShareSettings,
      };
      if (this.privateAudienceName) {
        if (this.isZenSense) {
          const role = this.isGroup
            ? this.groupRoles[this.roleSelected[0]].name
            : this.roles[this.roleSelected[0]].name;
          const businessFunction = this.businessFunctions
            .filter((_, index) => this.businessFunctionSelected.includes(index))
            .map((i) => i.name);
          await this.setAudienceInfo(
            this.isZenSense,
            this.isZenSense && {
              role,
              businessFunction,
            },
          );
          try {
            trackGeneralEvents.addnewaudienceShowfingerprint(this.currentUser, {
              ...audienceTrackingData,
              [TD_COMMON_COLUMN_NAME]: TD_FLOW,
              [TD_COMMON_COLUMN_VALUE]: TD_ADD_AUDIENCE_COLLEAGUE_CREATED,
              ...this.getMatomoFingerprintData(),
            });
          } catch (e) {
            console.log(e);
          }
        } else {
          const payload = {
            id: this.email,
            preferences: this.answers,
            industrySelected: this.industrySelected,
            functionSelected: this.functionSelected,
            groupSelected: this.groupSelected,
            regionSelected: this.regionSelected,
            jobTitleSelected: this.jobTitleSelected,
            fpCompletionDate: new Date().toISOString(),
          };
          await this.setPrivateAudienceFingerprint(payload);
          if (this.answers.question6 && this.answers.question6.length > 0) {
            const pref = {
              id: this.email,
              personalPreference: this.answers.question6,
            };
            this.setPrivateAudiencePersonalPreferences(pref);
          }
          try {
            trackGeneralEvents.addnewaudienceShowfingerprint(this.currentUser, {
              [TD_AUD_ADDITION_TYPE]: TD_AUDIENCE_ADD_TYPE_COMPLETE,
              [TD_COMMON_COLUMN_NAME]: TD_FLOW,
              [TD_COMMON_COLUMN_VALUE]: TD_ADD_AUDIENCE_COLLEAGUE_CREATED,
              ...audienceTrackingData,
              ...this.getMatomoFingerprintData(),
            });
          } catch (e) {
            console.log(e);
          }
        }
      } else {
        this.answers.industrySelected = this.industrySelected;
        this.answers.functionSelected = this.functionSelected;
        this.answers.groupSelected = this.groupSelected;
        this.answers.regionSelected = this.regionSelected;
        this.answers.jobTitleSelected = this.jobTitleSelected;
        this.answers.fpCompletionDate = new Date().toISOString();
        const fingerPrint = await this.setCurrentUserFingerprint(this.answers);
        this.audienceInfo = { ...this.currentUser.user };
        this.audienceInfo.fingerPrint = fingerPrint;
        if (
          this.currentIndex === 2 &&
          this.answers.question6 &&
          this.answers.question6.length > 0
        ) {
          await this.setCurrentUserPersonalPreferences(this.answers.question6);
        }
        if (this.isRetake) {
          trackGeneralEvents.addnewaudienceShowfingerprint(this.currentUser, {
            ...audienceTrackingData,
            ...this.getMatomoFingerprintData(),
            [TD_COMMON_COLUMN_NAME]: TD_FLOW,
            [TD_COMMON_COLUMN_VALUE]: this.getfingerPrintFlow,
            [TD_PLACEMENT]: TD_FP_RETAKE,
          });
        } else {
          trackGeneralEvents.addnewaudienceShowfingerprint(this.currentUser, {
            ...audienceTrackingData,
            ...this.getMatomoFingerprintData(),
            [TD_COMMON_COLUMN_NAME]: TD_FLOW,
            [TD_COMMON_COLUMN_VALUE]: this.getfingerPrintFlow,
            [TD_PLACEMENT]: TD_FP_TEST,
          });
        }
      }
      await updateFingerprintShareDetails(shareUpdate);
      // Removing it as per PM-4365
      /* const inviteColleague = {
          ...shareUpdate,
          ...{ isFingerprintFlow: true },
        };
        const sharedEntities = this.colleaguesList.filter((item) => item.id);
        inviteColleague.sharedEntities = [...sharedEntities];
        console.log(inviteColleague);
        updateFingerprintShareDetails(inviteColleague).then((res) => {
          console.log(res);
       }); */
      if (this.isZenSense) {
        this.progressScreenIndex = 0;
        this.loadProgressScreens();
      }
      if (!this.isZenSense) {
        this.progressScreenIndex = 0;
        this.loadProgressScreensFinal();
      }
      this.encourageShareFingerprint = !this.encourageShareFingerprint;
      this.currentIndex++;
      await this.checkSharedStatus();
    },
    getClassName(selection) {
      return this.personalPrefs.find((p) => p === selection.title)
        ? 'highlight'
        : '';
    },
    loadProgressScreens(index) {
      if (index === 2) {
        this.progressScreenIndex = -1;
        return;
      }
      setTimeout(() => {
        this.loadProgressScreens(this.progressScreenIndex++);
      }, this.progressScreens[this.progressScreenIndex].waitTime);
    },
    /**
     * Store the user selected answer
     * @param question String - question identifier e.g. question1, question2
     * @param item Object - User selected item for the question
     */
    handleQuestion(question, item) {
      if (question === 'question6') {
        // Question 6 i.e. user selections are stored as an Array
        const initialValue = this.answers[question];
        if (findIndex(initialValue, { title: item.title }) > -1) {
          const updatedValue = filter(
            initialValue,
            (initial) => initial.title !== item.title,
          );
          this.answers = { ...this.answers, [question]: updatedValue };
        } else {
          initialValue.push(item);
          this.answers = { ...this.answers, [question]: initialValue };
        }
      } else {
        this.answers = { ...this.answers, [question]: item };
        if (question !== 'question5') {
          this.$nextTick(() => {
            this.questionIndex++;
          });
          // setTimeout(() => {
          // }, 500);
        }
        if (question === 'question5') {
          this.$nextTick(() => {
            this.questionIndex++;
            this.firstSectionCompleted = true;
            setTimeout(() => {
              this.firstSectionCompleted = false;
            }, 3000);
          });
        }
      }
    },
    // To get the origin for matomo events capture
    getPlacement() {
      let placement = '';
      switch (this.questionIndex) {
        case 6:
          placement = 'Top preferences';
          break;
        case 7:
          placement = 'Industry';
          break;
        case 8:
          placement = 'Function';
          break;
        case 9:
          placement = 'Group';
          break;
        case 10:
          placement = 'Region';
          break;
        case 11:
          placement = 'Job Title';
          break;
        case 12:
          placement = 'Audience';
          break;
        default:
          break;
      }
      return placement;
    },
    getMatomoFingerprintData() {
      return {
        [TD_FINGERPRINT_INDUSTRY]: this.industrySelected
          ? this.industrySelected
          : NA,
        [TD_FINGERPRINT_GROUP]: this.groupSelected?.length
          ? this.groupSelected.toString()
          : NA,
        [TD_FINGERPRINT_REGION]: this.regionSelected?.length
          ? this.regionSelected.toString()
          : NA,
        [TD_FINGERPRINT_JOBTITLE]: this.jobTitleSelected
          ? this.jobTitleSelected
          : NA,
      };
    },
    async handleCurrentQuestionIndex() {
      console.log('handleCurrentQuestionIndex');
      this.ownPreference = '';
      trackGeneralEvents.fingerPrintTestNextClick(this.currentUser, {
        [TD_PLACEMENT]: this.getPlacement(),
        [TD_COMMON_COLUMN_NAME]: TD_FLOW,
        [TD_COMMON_COLUMN_VALUE]: this.getfingerPrintFlow,
      });
      if (this.questionIndex === 6) {
        this.personalPrefCompleted = true;
        setTimeout(() => {
          this.personalPrefCompleted = false;
        }, 3000);
        // this.questionIndex++;
        // if (this.questionIndex === 6) {
        //   this.question_progress += 14;
        // } else {
        //   this.question_progress += 14;
        // }
      }
      if (this.questionIndex === 10 && this.isGroup) {
        this.questionIndex = 13;
        this.encourageShareFingerprint = true;
        this.basicDetailCompleted = true;
        setTimeout(() => {
          this.basicDetailCompleted = false;
        }, 3000);
        return;
      }
      if (this.questionIndex === 11) {
        this.basicDetailCompleted = true;
        setTimeout(() => {
          this.basicDetailCompleted = false;
        }, 3000);
      }
      if (this.questionIndex === 11 && this.privateAudienceName) {
        this.questionIndex++;
      }
      if (this.questionIndex === 8) {
        this.groupSelected = [];
      }
      this.questionIndex++;
      if (this.questionIndex === 6) {
        // this.currentIndex++;
        if (
          this.currentIndex === 3 &&
          (!this.currentUser.user.preferences ||
            this.currentUser.user.preferences.length < 1)
        ) {
          // this.$router.push('profile?tab=fingerprint', () => {});
        }
      } else if (this.questionIndex === 12) {
        this.encourageShareFingerprint = true;
      } else if (this.questionIndex === 13) {
        this.inviteColleagureCompleted = true;
        await this.handleShare();
        setTimeout(async () => {
          this.inviteColleagureCompleted = false;
          await this.handleCurrentIndex();
          this.encourageShareFingerprint = true;
        }, 3000);
      }
    },
    async setAudienceInfo(zenSense = false, category = {}) {
      let userinfo;
      if (zenSense) {
        const { id, firstName, lastName, companyName } =
          this.currentPrivateAudience;
        const body = {
          id,
          firstName,
          lastName,
          companyName,
          userID: this.$route.query.requester,
          emailEntered: true, // (optional) default value - True,
          type: this.isGroup ? 'Group' : 'Individual', // Audience or Group
          role: category.role,
          functions: category.businessFunction,
        };
        userinfo = await getZenSenseAudience(body);
        this.setCurrentPrivateAudience(userinfo.data);
        this.audienceInfo = userinfo.data;
      } else {
        userinfo = await getPrivateAudience(this.email);
        this.setCurrentPrivateAudience(userinfo.data);
        this.audienceInfo = userinfo.data;
      }
    },
    handleTemplateShow() {
      if (this.isNonBrandedTrialUser) {
        this.isTemplateFlow = true;
      } else {
        this.handleCurrentIndex();
      }
    },
    updateShowTemplateDesignerFlow() {
      return new Promise((resolve, reject) => {
        if (
          this.currentUser.user.fingerPrint &&
          this.currentUser.user.showTemplateDesignerFlow === 0 &&
          this.currentUser.company.isNonBranded
        ) {
          const userData = {
            performIncrements: {
              showTemplateDesignerFlow: 1,
            },
          };
          usersApi.methods
            .updateUserProfile(userData)
            .then((updatedUser) => {
              if (Object.keys(updatedUser).length > 0) {
                const userInfo = this.currentUser;
                userInfo.user = updatedUser;
                this.setCurrentUser(userInfo);
                resolve();
                this.$router.push('/home', () => {});
              }
              resolve();
            })
            .catch((err) => {
              reject();
              console.log(err);
            });
        } else {
          resolve();
        }
      });
    },
    async handleSelectTemplate(type) {
      if (type === 'standard') {
        await this.updateShowTemplateDesignerFlow();
      }
      this.templateType = 'transition';
      setTimeout(() => {
        this.templateType = type;
        if (type === 'standard') {
          this.isTemplateFlow = false;
          this.handleCurrentIndex();
        }
      }, 3000);
    },
    handleSubmitErr(err) {
      if (err === 'JOB_ERR') {
        this.templateType = 'standard';
        this.isTemplateFlow = false;
        this.handleCurrentIndex();
      }
    },
    handleBackToTemplate(loc) {
      if (loc === 'TMP_BK') {
        this.backFromTmp = true;
        this.templateType = 'standard';
      }
    },
    async handleTemplateDone(previewDeck) {
      await this.updateShowTemplateDesignerFlow();
      this.handleTDImages(previewDeck.fingerprint);
      this.isTemplateFlow = false;
      this.handleCurrentIndex();
    },
    async handleTDImages(fingerprint) {
      this.questions.map(async (question, index) => {
        if (question.leftImage && question.rightImage) {
          const leftImage = await getFileURL(
            this.currentUser.user.cognitoID,
            fingerprint[index * 2].filepath,
            this.currentUser.userIp,
            'IOCUpload',
          );
          const rightImage = await getFileURL(
            this.currentUser.user.cognitoID,
            fingerprint[index * 2 + 1].filepath,
            this.currentUser.userIp,
            'IOCUpload',
          );
          if (leftImage && rightImage) {
            question.leftImage = leftImage;
            question.rightImage = rightImage;
          }
        }
        return question;
      });
    },
    async handleCurrentIndex() {
      // privateAudienceName is present if audience
      if (this.questionIndex && this.questionIndex !== 13) this.questionIndex++;
      if (this.privateAudienceName) {
        if (
          this.currentIndex === 2 &&
          this.email &&
          (!this.currentPrivateAudience ||
            !this.currentPrivateAudience.fingerPrint)
        ) {
          await this.setAudienceInfo();
        } else {
          this.audienceInfo = this.currentPrivateAudience;
        }
      } else {
        if (
          !this.reTake &&
          this.currentUser &&
          this.currentUser.user.fingerPrint
        ) {
          this.$router.push('/home', () => {});
          return;
        }

        // Need to check what the below usecase is i.e. email param is present as a query param in url
        if (
          this.currentIndex === 2 &&
          this.email &&
          (!this.currentUser ||
            !this.currentUser.user.preferences ||
            this.currentUser.user.preferences.length < 1)
        ) {
          usersApi.methods.getUserInfo().then(async (userinfo) => {
            await this.setCurrentUser(userinfo);

            this.audienceInfo = userinfo.user;
          });
        }
      }

      if (this.currentIndex === 2) {
        this.encourageShareFingerprint = true;
        this.question_progress += this.isZenSense ? 0 : 16;
      } else {
        this.currentIndex++;
      }
    },
    goToDestination() {
      if (this.origin && this.origin.toLowerCase() === 'np') {
        this.$router.push('/home/build', () => {});
      } else if (this.origin && this.origin.toLowerCase() === 'zentrends') {
        this.$router.push('home/fingerprint', () => {});
      } else if (this.origin && this.origin.toLowerCase() === 'slides') {
        this.$router.push('/home/slides', () => {});
      } else if (this.origin && this.origin.toLowerCase() === 'prezentations') {
        this.$router.push('/home/prezentations', () => {});
      } else if (this.origin && this.origin.toLowerCase() === 'generate') {
        this.$router.push('/home/generate', () => {});
      } else if (this.origin && this.origin.toLowerCase() === 'synthesis') {
        this.$router.push('/home/synthesis', () => {});
      } else if (this.origin && this.origin.toLowerCase() === 'redesign') {
        this.$router.push('/home/redesign', () => {});
      } else if (
        this.origin &&
        this.origin.toLowerCase() === 'overnight-prezentations'
      ) {
        this.$router.push(
          '/home/overnight-prezentations?tab=newRequest',
          () => {},
        );
      } else {
        this.$router.push('/home', () => {});
      }
    },
    async handleGoHome() {
      if (this.privateAudienceName) {
        this.goToDestination();
      } else {
        const userdata = {
          seenOrSkippedWelcome: true,
        };
        await usersApi.methods
          .updateUserProfile(userdata)
          .then((updatedUserDetails) => {
            const userInfo = this.currentUser;
            userInfo.user = updatedUserDetails;
            this.setCurrentUser(userInfo);
            console.log('Fingerprint completed', updatedUserDetails);
            AnalyticsHandler.userCompletedFingerprint(updatedUserDetails);
          })
          .catch((err) => console.log(err));
        this.goToDestination();
      }
    },
    gotoRetakeTest() {
      if (this.basePath === '') {
        this.basePath = this.$route.fullPath;
      }
      this.retakeCount++;
      this.currentIndex = 2;
      this.questionIndex = 0;
      this.zensenseIndex = 0;
      trackGeneralEvents.addnewaudienceShowfingerprintRetakeTest(
        this.currentUser,
        {
          ...this.getCommonAudienceTrackingData(),
          ...this.getMatomoFingerprintData(),
          [TD_COMMON_COLUMN_NAME]: TD_FLOW,
          [TD_COMMON_COLUMN_VALUE]: this.getfingerPrintFlow,
        },
      );
      this.$router.push(`${this.basePath}&retake=${this.retakeCount}`);
    },
    capitalize(s) {
      if (typeof s !== 'string') return '';
      return s.charAt(0).toUpperCase() + s.slice(1);
    },
    getIpAddress() {
      return Ipify.get();
    },
    handleSignOut() {
      clearCachedUser();
      Auth.signOut();
    },
    async populateQuestions() {
      this.initialLoading = true;
      try {
        const { data } = await getUserCompanyInfo(
          this.currentUser.company.name.toLowerCase(),
        ).catch(() => {
          this.initialLoading = false;
        });
        const { data: fingerprintSlides } = await getFingerprintSlides(
          snakeCase(Object.keys(data)[0]),
        ).catch(() => {
          this.initialLoading = false;
        });
        const sortedSlides = chunk(orderBy(fingerprintSlides, 'unique_id'), 2);
        this.questions = await Promise.all(
          this.questions.map(
            async (question, index) =>
              new Promise((resolve) => {
                if (question.leftImage && question.rightImage) {
                  getFileURL(
                    this.currentUser.user.cognitoID,
                    sortedSlides[index][0].landscape,
                    this.currentUser.userIp,
                  ).then((leftImage) => {
                    getFileURL(
                      this.currentUser.user.cognitoID,
                      sortedSlides[index][1].landscape,
                      this.currentUser.userIp,
                    ).then((rightImage) => {
                      new Image().src = leftImage;
                      new Image().src = rightImage;
                      resolve({
                        ...question,
                        leftImage,
                        rightImage,
                      });
                    });
                  });
                } else {
                  resolve(question);
                }
              }),
          ),
        );
        this.initialLoading = false;
      } catch (e) {
        console.log(e);
        this.initialLoading = false;
      }
    },
    async handleShare() {
      this.colleaguesList = this.colleaguesList.filter(
        (item) => item.id && item.firstname && item.lastname,
      );
      this.isLoading = true;
      const { id, fullName } = this.currentUser.user;
      const payload = this.colleaguesList.map((item) => {
        const { firstname, lastname, company: companyname } = item;
        return {
          email: item?.id?.toLowerCase(),
          firstname,
          lastname,
          companyname,
          requester: id,
          requester_fullname: fullName,
          message: this.message,
          source: MEMBER_REFERRAL_SOURCE,
          type: ENTITY_TYPE,
        };
      });
      try {
        const resp = await TrialApi.methods.trialRegistration(payload);
        if (!resp.error) {
          this.resultData = resp.data;
          this.sentOutInvitesUsersData = this.resultData.filter(
            (result) =>
              !result.userAlreadyExist &&
              result.companyRegistered &&
              result.signUpLink,
          );
          this.existingUsersData = this.resultData.filter(
            (result) => result.userAlreadyExist,
          );

          this.nonRegisteredCompanyUsersData = this.resultData.filter(
            (result) => !result.userAlreadyExist && !result.companyRegistered,
          );
        } else {
          // TODO: error handle
        }
      } catch (error) {
        console.log(error);
      } finally {
        this.isLoading = false;
      }
    },
    subModalClosed() {
      this.$emit('subModalOpen', true);
    },
    getMatomoOtherData() {
      if (this.origin && this.origin === TD_ORIGIN_FINGERPRINT) {
        return {
          [TD_PLACEMENT]: TD_SHARE_THE_GIFT_OF_FINGERPRINT,
        };
      }
      return {};
    },
    openEmailPreviewModal() {
      this.shareTrialLink = `&lt;Unique link&gt;`;
      this.$emit('subModalOpen', false);
      this.$modal.show(
        EmailPreviewModal,
        {
          emailApiPayload: {
            type: 'trial-registration-new',
            meta: {
              toAddress: 'test.trial@prezent.ai',
              signupLink: this.shareTrialLink,
              firstName: this.currentUser.user.firstName,
              lastName: this.currentUser.user.lastName,
            },
          },
          heading: 'Trial invite email preview',
        },
        {
          name: 'EmailPreviewModal',
          classes: 'v-application',
          width: '520px',
          height: '600px',
          styles: {
            borderRadius: '20px',
            padding: '20px',
          },
        },
        {
          'before-close': this.subModalClosed,
        },
      );
    },
    async refreshCurrentUserInfo() {
      const userinfo = await usersApi.methods.getUserInfo();
      this.setCurrentUser(userinfo);
      return userinfo;
    },
  },

  async beforeMount() {
    if (
      Object.keys(this.currentUser).find((key) => key === 'user') === undefined
    ) {
      this.handleSignOut();
    }
  },
  async mounted() {
    this.loading = true;
    // initialize questions and selections
    try {
      const fingerPrintAdditionalData = await getFingerPrintAdditionalData();
      console.log('fingerPrintAdditionalData', fingerPrintAdditionalData);
      this.setFingerPrintAdditionalDetails(fingerPrintAdditionalData);
      this.businessFunctions = fingerPrintAdditionalData.functions;
      this.businessFunctions.forEach((item) => {
        item.icon = item.imagePath;
        return item;
      });
    } catch (err) {
      console.log(err);
    }
    this.selections = [...fpSelections];
    const deepCopiedQuestions = JSON.parse(JSON.stringify(fpQuestions));
    this.questions = [...deepCopiedQuestions];
    this.industries = this.fingerPrintAdditionalData?.industries;
    this.functions = this.fingerPrintAdditionalData?.functions;
    this.groups = this.getGeneralGroups();
    this.regions = this.fingerPrintAdditionalData?.regions;
    this.jobTitles = this.fingerPrintAdditionalData?.jobTitle;
    await this.populateQuestions();

    if (this.$route.query.email) {
      this.email = this.$route.query.email.toLowerCase();
    }

    this.loading = false;
    MatomoAnalyticsHandler.trackFingerprint({
      user: this.currentUser,
      actionName: FP_TEST_WELCOME,
    });

    if (this.$route.query.initialIndex) {
      this.currentIndex = Number(this.$route.query.initialIndex);
      this.reTake = true;

      // Below is only for private audience fingerprint
      await this.setAudienceInfo();
      this.privateAudienceName = this.currentPrivateAudience.firstName;
      this.isZenSense =
        this.$route.query.zensense && this.$route.query.zensense === 'true';
      if (this.privateAudienceName) {
        for (let i = 0; i < this.questions.length; i++) {
          this.questions[i].headeraud = this.questions[i].headeraud?.replace(
            'your audience',
            this.capitalize(this.privateAudienceName),
          );
          this.questions[i].subHeaderaud = this.questions[
            i
          ].subHeaderaud?.replace(
            'your audience',
            this.capitalize(this.privateAudienceName),
          );
        }
        MatomoAnalyticsHandler.trackFingerprint({
          user: this.currentUser,
          actionName: FP_TEST_RETAKEN,
          audience: {
            id: this.email,
          },
        });
      } else {
        MatomoAnalyticsHandler.trackFingerprint({
          user: this.currentUser,
          actionName: FP_TEST_RETAKEN,
        });
      }
      this.origin = this.$route.query.origin;
    }
    this.loading = false;
    this.questionIndex = 0;
    const userIp = await this.getIpAddress();
    await this.setCurrentUser({ ...this.currentUser, userIp });

    await this.checkSharedStatus();
    await this.refreshCurrentUserInfo();
    if (this.isTDFPFlow) {
      if (
        this.isTDFPPopulated &&
        this.currentUser?.designerTheme?.jobSubmitted &&
        this.currentUser?.designerTheme?.status === 'inprogress'
      ) {
        this.handleTDImages(
          this.currentUser?.designerTheme?.fingerprintPreferences,
        );
      } else if (
        this.currentUser?.designerTheme?.status === 'completed' ||
        this.currentUser?.designerTheme?.status === 'failed'
      ) {
        this.templateType = 'standard';
        this.isTemplateFlow = false;
      }
    }
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import '@/scss/variables.scss', '@/scss/app.scss';
.v-application {
  background: linear-gradient(
    0.61deg,
    rgba(149, 208, 234, 0.6) -22.84%,
    rgba(255, 255, 255, 0.048) 38.59%
  );

  // &.circle {
  //   position: relative;
  //   height: 100vh;
  //   overflow: hidden;
  // }
}
// .fadeScreen-enter-active {
//   transition: all 1s ease 0.5s;
//   opacity: 0 !important;
//   height: 0 !important;
//   box-sizing: border-box;
// }
.fadeScreen-leave-active {
  transition: all 0.9s ease 0.4s;
  opacity: 1;
}

.fadeScreen-leave-to {
  transform: translateX(-100%);
  opacity: 1;
}

// .fadeScreen-enter-to {
//   opacity: 0;
// }

.circle-top,
.circle-bottom {
  position: absolute;
  display: flex;
  gap: 25px;
  align-items: center;

  .circle {
    width: 200px;
    height: 200px;
    border: 4px solid #21a7e0;
    border-radius: 50%;
  }

  .dot {
    height: 20px;
    width: 20px;
    background: #21a7e0;
    border-radius: 50%;
  }
}
.circle-top {
  top: 0;
  right: 0;
  transform: translate(40%, -40%) rotate(150deg);
}

.circle-bottom {
  bottom: 0;
  left: 0;
  transform: translate(-35%, 30%) rotate(-40deg);
}
// ::v-deep .v-main__wrap {
//   margin: auto;
//   margin-top: 2% !important;
// }
.loader-wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  gap: 70px;
  text-align: center;
  width: 50%;
  margin: 10% auto 0 auto;
  .logo-loading {
    .v-image {
      margin: 0 auto;
    }
  }
}
.main-wrapper {
  padding: 20px;
  padding-left: 3%;
  padding-right: 3%;
  text-align: center !important;
}
.privacy-policy-footer {
  bottom: 0;
  color: gray !important;
  display: flex;
  justify-content: flex-end;
  padding: 20px 60px;
  right: 0;
  .privacy-policy {
    padding-right: 20px;
    a {
      color: inherit;
      text-decoration: none;
    }
  }
}
.logo {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 20px;
}
::v-deep .highlight {
  color: #29b6f6;
}
.section-wrapper {
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: center !important;
}
.landing-page-content {
  align-items: center;
  display: flex;
  flex-direction: column;
}
.first-screen {
  justify-content: center;
  text-align: center !important;
  .name-wrapper {
    color: rgba(0, 0, 0, 0.8);
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 45px;
    line-height: 52px;
    text-align: center;
  }
  .highlight-normal {
    color: rgba(0, 0, 0, 0.8);
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 45px;
    line-height: 52px;
    text-align: center;
    margin-bottom: 30px;
  }
  .highlight-bold {
    font-weight: bold;
    font-size: 36px;
    font-weight: bold;
    margin-bottom: 44px;
  }

  .job-function-wrapper {
    margin-bottom: 100px;
    width: 400px;
  }
  .sub-header {
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 28px;
    text-align: center;
    color: rgba(0, 0, 0, 0.8);
    margin-bottom: 20px;
  }
  .lets-go-button {
    color: #ffffff;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 600;
    font-size: 18px;
    line-height: 19px;
    letter-spacing: 0;
    text-transform: none;
  }
  .section-image {
    margin-bottom: 40px;
  }
}
.collegue-finger-print-assesment {
  align-items: center;
  display: flex;
  justify-content: space-around;

  // .section-image {
  //   margin-bottom: 40px;
  //   width: 40%;
  // }
  .pa-4 {
    width: 60%;
    .sub-header {
      color: rgba(0, 0, 0, 0.8);
      font-family: 'Lato';
      font-style: normal;
      font-size: 22px;
      font-weight: 400;
      line-height: 28px;
      letter-spacing: 0;
      margin-bottom: 10px;
    }
    .action-detail {
      letter-spacing: 0;
      margin-left: 0px;
    }
  }
}
.second-screen {
  width: 100%;
  margin: 0 auto;
  text-align: center !important;

  .header {
    font-size: 45px;
    font-weight: 400;
    font-stretch: normal;
    font-style: normal;
    line-height: 52px;
    letter-spacing: normal;
    text-align: center;
    margin-bottom: 30px;
  }
  .items {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin: 24px 0;

    .item {
      align-items: center;
      border-radius: 12px;
      box-shadow: 1px 1px 8px 4px rgba(0, 0, 0, 0.02),
        -1px -1px 8px 4px rgba(0, 0, 0, 0.02);
      display: flex;
      flex-direction: column;
      height: 162px;
      justify-content: center;
      width: 240px;

      .welcome-item-title {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 700;
        font-size: 20px;
        line-height: 24px;
        color: #7b7b7b;
        margin-bottom: 15px;
      }
    }
  }
  .description {
    color: rgba(0, 0, 0, 0.8);
    font-size: 22px;
    font-weight: 400;
    line-height: 28px;
    margin-bottom: 24px;
    text-align: center;
  }
  .action-detail {
    font-size: 12px;
    font-weight: normal;
    line-height: 14px;
    margin-bottom: 16px;
  }
  button {
    margin-bottom: 10px;
  }
  .final-description {
    color: #000000;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    text-align: center;
  }
  .space-desc {
    padding-top: 10px;
  }
}
.question-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 90%;
  margin: auto;
  text-align: center !important;
  .preview-email {
    color: #21a7e0;
    cursor: pointer;
    margin: 10px 0px 10px 15px;
    font-size: 1rem;
    .preview-email-text {
      margin-right: 9.7px;
    }
  }
  .buttonAndHeading {
    display: flex;
    align-items: center;
    margin-bottom: 15px;
    position: relative;
    .back-button {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 400;
      font-size: 16px;
      line-height: 19px;
      position: absolute;
      right: calc(100% + 150px);
      width: 50px;
      cursor: pointer;
      color: #29b6f6;
      display: flex;
      align-items: center;
    }
    .question-header {
      color: #000000;
      font-family: 'Lato';
      line-height: 29px;
      font-size: 24px;
      font-style: normal;
      font-weight: 700;
      text-align: center;
    }
  }
  .progress-wrapper {
    width: 15%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 20px;
  }
  .question-index {
    font-size: 12px;
    margin-bottom: 40px;
  }
  .question-content {
    width: 100%;
    .question-content-header {
      margin-bottom: 40px;
      font-size: 20px;
      .header {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 45px;
        line-height: 52px;
        margin-bottom: 15px;
      }
      .sub-header {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 22px;
        line-height: 28px;
        &.underline {
          display: flex;
          color: #29b6f6;
          justify-content: center;
          p {
            cursor: pointer;
            margin-bottom: 0px !important;
            width: 150px;
          }
        }
      }
    }
    @media screen and (min-width: 1024px) {
      .images-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        margin-bottom: 1rem;
        gap: 4rem;
      }
    }

    @media screen and (min-width: 1440px) {
      .images-wrapper {
        gap: 7rem;
      }
    }
    .images-wrapper {
      // margin-bottom: 60px;
      img.question-image {
        background: #ffffff;
        border-radius: 28px;
        box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1),
          -1px -1px 4px rgba(0, 0, 0, 0.1);
        width: 40%;
        height: 20%;
        &.border {
          border: 5px solid #21a7e0;
          box-shadow: 3px -2px 15px 3px #b6b6b6, -3px 2px 15px 3px #b6b6b6;
        }
        &:hover {
          box-shadow: 3px -2px 15px 3px #b6b6b6, -3px 2px 15px 3px #b6b6b6;
        }
        &.blur {
          opacity: 0.35;
        }
      }
    }
    .selections-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      .header {
        font-size: 24px;
        font-weight: bold;
        font-stretch: normal;
        margin-bottom: 20px;
      }
      .selections {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: center;
        width: 100%;
        .selection {
          align-items: center;
          display: flex;
          justify-content: flex-start;
          border-radius: 12px;
          box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1),
            -1px -1px 4px rgba(0, 0, 0, 0.1);
          padding: 8px 15px;
          margin-right: 25px;
          margin-bottom: 25px;
          color: rgba(33, 33, 33, 1);
          font-size: 20px;
          font-weight: 700;
          line-height: 24px;
          cursor: pointer;
          height: 70px;
          width: 25%;
          &.highlight {
            border: 3px solid #29b6f6;
          }
          .selection-title {
            padding-right: 10px;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
        .group-items {
          padding: 8px 25px;
          width: 20%;
        }
      }
      .write-own-btn {
        background: #ffffff;
        border-radius: 12px;
        box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1),
          -1px -1px 4px rgba(0, 0, 0, 0.1);
        width: 40%;
        padding: 8px 25px;
        font-size: 20px;
        font-weight: 700;
        margin: auto;
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        margin-bottom: 60px;
        .write-own-header {
          display: flex;
          margin-top: 10px;
        }
        .new-pref-text {
          background: #ffffff;
          border-radius: 4px;
          font-weight: 400;
          margin: 10px 0px;
          text-align: left;
          width: 100%;
          ::v-deep .v-input__slot {
            margin-bottom: 0px !important;
          }
        }
        .add-pref {
          color: #29b6f6;
          margin-bottom: 10px;
          text-align: center;
          width: 100%;
          .v-icon.v-icon {
            color: #29b6f6;
          }
        }
      }
      .create-own {
        margin-bottom: 60px;
      }
    }
  }
}

.question-buttons {
  display: flex;
  align-items: center;
  text-align: center !important;
  justify-content: center;
  flex-direction: column;
  .next-button {
    width: 100px;
    height: 40px;
    background: #21a7e0;
    border-radius: 25px;
    ::v-deep .v-btn__content {
      color: #ffffff;
      font-family: 'Lato';
      font-style: normal;
      font-weight: 600 !important;
      font-size: 18px !important;
      letter-spacing: normal;
      line-height: 19px !important;
      text-transform: none;
    }
  }
  .skip-button {
    color: #21a7e0;
    cursor: pointer;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    margin: 20px 0;
  }
}

sup {
  top: -0.75em;
  font-size: 50%;
}
.v-input--radio-group--row {
  width: 100%;
}
.text-logo {
  transform: translateX(-20px);
  margin-bottom: -7px;
  margin-left: 14px;
  height: 40px;
  width: 200px;
}
.fingerprint-center-item {
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: $zen-blue-default;
  margin-top: 16px;
  margin-bottom: 30px;
  width: 100%;
  cursor: pointer;

  &:hover {
    text-decoration: underline;
  }
}
.footer-description {
  margin-bottom: 16px;
}
.img-pref {
  height: 60px;
  width: 86px;
}

.progress-loader {
  .header {
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 24px;
    line-height: 29px;
    text-align: center;
    letter-spacing: -0.01em;
    margin: 10px 0;
  }
}
.encourage-share-fingerprint {
  align-items: center;
  display: flex;
  flex-direction: column;

  .encourage-title {
    color: #000000;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 45px;
    line-height: 52px;
    text-align: center;
  }

  .encourage-sub-title {
    color: #000000;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 28px;
    margin-bottom: 5px;
    margin-top: 15px;
    text-align: center;
  }

  .add-heart__title {
    color: #000000;
    display: flex;
    font-style: normal;
    font-weight: 400;
    font-size: 22px;
    line-height: 28px;
    margin-bottom: 14px;
    .add-heart__title_subtext {
      color: #000000;
      font-style: normal;
      font-weight: 400;
      font-size: 22px;
      line-height: 28px;
      height: 28px;
      text-align: center;
      margin-left: 5px;
    }
  }
  .learn_more {
    cursor: pointer;
    color: rgb(0, 149, 255);
  }

  .main-wrapper {
    margin-top: 20px;
    // width: 60%;
    ::v-deep .audience-item {
      .text-name {
        text-align: start;
      }
    }
  }
}
.cards-wrapper {
  display: flex;
  gap: 30px;
  flex-wrap: wrap;
  justify-content: center;
  margin: auto;
  margin-bottom: 30px;
  width: 840px;
  &.industry {
    width: 100%;
    .cards {
      max-width: 20% !important;
    }
  }
}
.cards {
  background: #ffffff;
  box-shadow: 1px 1px 4px rgb(0 0 0 / 10%), -1px -1px 4px rgb(0 0 0 / 10%);
  width: 240px;
  height: 162px;
  border-radius: 12px;
  height: 180px;
  max-width: 260px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  flex: calc(33.33% - 20px);
  .item-name {
    color: #212121;
    font-family: 'Lato';
    font-style: normal;
    font-weight: 700;
    font-size: 20px;
    line-height: 24px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 200px;
  }
  > div {
    font-weight: bold;
    margin-bottom: 10px;
    font-size: 18px;
  }

  > img {
    height: 100px;
    width: 120px;
  }

  &.selected-card {
    border: 3px solid #21a7e0;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1), -1px -1px 4px rgba(0, 0, 0, 0.1);
  }
}
.why-we-ask-content {
  margin: 0 auto;
  position: relative;
  width: 320px;
  z-index: 5;
  div {
    background: #ffffff;
    border-radius: 4px;
    box-shadow: 1px 1px 4px rgba(0, 0, 0, 0.1), -1px -1px 4px rgba(0, 0, 0, 0.1);
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    left: 0;
    padding: 10px;
    position: absolute;
    right: 0;
  }
}
.invite-form {
  width: 100%;
}
.email-list-container {
  max-height: 300px;
  overflow-y: scroll;
  margin-top: 50px;
  margin-bottom: 20px;

  .name-col {
    margin-right: 20px;
    padding: 0;
    .custom-text-field .v-label {
      color: #191c1e;
    }

    .message-label {
      margin-bottom: 20px;
      font-size: 16px;
      font-weight: bold;
    }
  }

  .email-col {
    margin-right: 20px;
    padding: 0px;

    .message-label {
      margin-bottom: 8px;
      font-size: 16px;
      font-weight: bold;
    }
  }

  .close-col {
    min-width: 4%;
  }
}
.add-more {
  align-items: center;
  margin-bottom: 58px;
  .add-btn {
    font-size: 14px;
    text-transform: none;
    letter-spacing: normal;
    text-align: center;
  }
}
.loading-header {
  color: rgba(0, 0, 0, 0.8);
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 45px;
  line-height: 52px;
  text-align: center;
  margin-bottom: 30px;
}
.loading-sub-header {
  color: rgba(0, 0, 0, 0.8);
  font-family: 'Lato';
  font-style: normal;
  font-weight: 400;
  font-size: 22px;
  line-height: 28px;
  text-align: center;
  margin-bottom: 30px;
}
.job-function-wrapper {
  margin-bottom: 200px;
  width: 400px;
}
@keyframes slide-out {
  0% {
    transform: translateX(0%);
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  100% {
    transform: translateX(-100%);
    opacity: 0;
  }
}
.transition-container {
  &.slide-animate {
    animation: 1s ease-out slide-out;
    animation-delay: 2500ms;
  }
  .transition-title {
    font-family: 'Lato';
    font-size: 45px;
    font-weight: 400;
    line-height: 52px;
    letter-spacing: 0em;
    text-align: center;
    margin-bottom: 70px;
  }
  .img-container {
    padding-top: 30px;
  }
}
</style>
