import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
// import appStore from "./modules/appStore";
import { contextSelector } from './modules/contextSelector';
// import { recommended } from "./modules/recommended";
import { users } from './modules/users';
import dialogStore from './modules/dialogStore';
import { prezentations } from './modules/prezentations';
import { synonyms } from './modules/synonyms';
import tourStore from './modules/tourStore';
import { learndata } from './modules/learndata';
// import { metrics } from './modules/metrics';
import { categories } from './modules/categories';
import { replaceImageStore } from './modules/replaceImageStore';
import { replaceIconStore } from './modules/replaceIconStore';
import { pendoStore } from './modules/pendoStore';
import featureReleaseInfo from './modules/featureReleaseInfoStore';
import knowStore from './modules/knowStore';
import { paymentMethods } from './modules/paymentMethods';
import { slidesCollection } from './modules/collection';
import { slidesStore } from './modules/slidesStore';
import { search } from './modules/search';
import { generateSlides } from './modules/generateSlides';
import { compliance } from './modules/compliance';
import { bestPracticesExamples } from './modules/bestPracticesExamples';
import complyModule from './modules/complyModule';
import { upload } from './modules/upload';
import prezentationDetails from '../components/Main/PrezentationDetails/store';
import { advancedFilterStore } from './modules/advancedFilterStore';
import { commonDownloads } from './modules/commonDownloads';

const storeModules = {
  contextSelector,
  users,
  dialogStore,
  prezentations,
  synonyms,
  tourStore,
  learndata,
  categories,
  replaceImageStore,
  pendoStore,
  featureReleaseInfo,
  knowStore,
  slidesCollection,
  paymentMethods,
  slidesStore,
  search,
  generateSlides,
  compliance,
  bestPracticesExamples,
  comply: complyModule,
  upload,
  replaceIconStore,
  prezentationDetails,
  advancedFilterStore,
  commonDownloads,
};

const initialState = Object.keys(storeModules).reduce((prev, key) => {
  return {
    ...prev,
    [key]: storeModules[key].state,
  };
}, {});

const store = new Vuex.Store({
  modules: {
    // appStore,
    contextSelector,
    users,
    dialogStore,
    prezentations,
    synonyms,
    tourStore,
    learndata,
    // metrics,
    categories,
    replaceImageStore,
    pendoStore,
    featureReleaseInfo,
    knowStore,
    slidesCollection,
    paymentMethods,
    generateSlides,
    slidesStore,
    search,
    compliance,
    bestPracticesExamples,
    comply: complyModule,
    upload,
    replaceIconStore,
    prezentationDetails,
    advancedFilterStore,
    commonDownloads,
  },
  actions: {
    reset({ commit }) {
      commit('reset');
    },
  },
  mutations: {
    reset(state) {
      Object.keys(state).forEach((key) => {
        Object.assign(state[key], initialState[key]);
      });
    },
  },
  plugins: Object.keys(storeModules).map((key) => {
    if (storeModules[key].persist) {
      return storeModules[key].persist;
    }
    return createPersistedState({
      key: `vuex_${key}`,
      paths: [key],
    });
  }),
});

export default store;
