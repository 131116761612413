import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import { convertPdfToImage } from '../../utils/pdf-to-png';
import { getThumbnail } from './helper';

Vue.use(Vuex);

export const collectionInitialState = {
  collections: [],
  downloadStatus: false,
  downloadSet: [],
  tailorID: null,
  nodeCountChanged: false,
  collectionUpdateProgress: false, // when adding or deleting slides to collection
};

export const slidesCollection = {
  namespaced: true,
  state: {
    ...collectionInitialState,
  },
  getters: {
    getCollectionUpdateProgress: (state) => state.collectionUpdateProgress,
    getAllCollections: (state, getters, rootContext) => {
      if (rootContext.users.currentTheme) {
        const collectionData = [...state.collections];
        const currentThemeID = rootContext.users.currentTheme.id;
        collectionData.sort((a, b) => {
          const nameA = a.templateName.toUpperCase();
          const nameB = b.templateName.toUpperCase();
          if (nameA < nameB) {
            return -1;
          }
          if (nameA > nameB) {
            return 1;
          }
          return 0;
        });
        const currentThemeIndex = collectionData.findIndex(
          (collection) => collection.templateID === currentThemeID,
        );
        if (currentThemeIndex !== -1) {
          collectionData.unshift(
            collectionData.splice(currentThemeIndex, 1)[0],
          );
        }
        return collectionData;
      }
    },
    getAddedCollections: (state, getters, rootContext) => {
      const currentCollection = state.collections.find(
        (i) => i.templateID === rootContext?.users?.currentTheme?.id,
      );
      if (currentCollection) {
        return currentCollection.slides
          .filter((i) => !i.isImageReplaced && !i.isIconReplaced)
          .map((i) => i.slideID);
      } else {
        return [];
      }
    },
    getAllAddedCollections: (state, getters, rootContext) => {
      const currentCollection = state.collections.find(
        (i) => i.templateID === rootContext?.users?.currentTheme?.id,
      );
      if (currentCollection) {
        return currentCollection.slides.map((i) => i.slideID);
      } else {
        return [];
      }
    },
    isCollectionLimitReached: (state, getters) => {
      return getters.getAllAddedCollections.length >= 5;
    },
    getDownloadCompletedStatus: (state) => {
      return state.downloadSet.every(
        (i) => i.status === 'complete' || i.status === 'canceled',
      );
    },
  },
  mutations: {
    SET_COLLECTION_DATA(state, { collectionData, user }) {
      collectionData.forEach((template) => {
        template.slides.forEach((slide) => {
          slide.thumbnail = '';
          getThumbnail(
            slide.slideThumbnail,
            user.user.id,
            user.userIp,
            slide.source === 'uploaded' || slide.source === 'generated' || slide.source === 'synthesis'
              ? 'IOCUpload'
              : undefined,
          ).then(async (thumbnail) => {
            if (slide.source === 'uploaded') {
              slide.thumbnail = await convertPdfToImage(thumbnail);
            } else {
              slide.thumbnail = thumbnail;
            }
          });
        });
      });
      state.collections = collectionData;
    },
    RESET_STATE(state) {
      state = {
        ...collectionInitialState,
      };
    },
    SET_DOWNLOAD_STATUS(state, status) {
      state.downloadStatus = status;
    },
    SET_DOWNLOAD_SET(state, set) {
      if (set.length) {
        state.downloadSet = [...state.downloadSet, ...set];
      } else {
        state.downloadSet = [];
      }
    },
    UPDATE_SET_STATUS(state, { index, status }) {
      if (state.downloadSet[index].status !== 'canceled') {
        state.downloadSet[index].status = status;
      }
    },
    SET_TAILOR_ID(state, id) {
      state.tailorID = id;
    },
    SET_NODE_COUNT_CHANGED(state, changed) {
      state.nodeCountChanged = changed;
    },
    SET_COLLECTION_UPDATE_PROGRESS(state, status) {
      state.collectionUpdateProgress = status;
    },
  },
  actions: {
    setCollectionData({ commit, rootState }, collectionData) {
      commit('SET_COLLECTION_DATA', {
        collectionData,
        user: rootState.users.currentUser,
      });
    },
    resetState({ commit }) {
      commit('RESET_DATA', null);
    },
    setDownloadStatus({ commit, getters, state }, status) {
      if (!status && !getters.getDownloadCompletedStatus) {
        return;
      }
      if (!status && getters.getDownloadCompletedStatus) {
        commit('SET_DOWNLOAD_SET', []);
      }
      commit('SET_DOWNLOAD_STATUS', status);
    },
    setDownloadSet({ commit }, set) {
      commit('SET_DOWNLOAD_SET', set);
    },
    updateDownloadSetStatus({ commit }, { index, status }) {
      commit('UPDATE_SET_STATUS', { index, status });
    },
    setTailorID({ commit }, id) {
      commit('SET_TAILOR_ID', id);
    },
    setNodeCountChange({ commit }, isChanged) {
      commit('SET_NODE_COUNT_CHANGED', isChanged);
    },
    setCollectionUpdateProgress({ commit }, status) {
      commit('SET_COLLECTION_UPDATE_PROGRESS', status);
    },
  },
  persist: createPersistedState({
    key: 'vuex_slidesCollection',
    paths: [],
  }),
};
