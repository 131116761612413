<template>
  <div
    class="transition-container"
    :class="isSlideAnimate ? 'title-slide-animate' : ''"
    v-if="showTransition"
  >
    <v-progress-circular indeterminate color="primary"></v-progress-circular>
    <p class="transition-title">
      {{ $t('templateDesign.uploadTemplate.nextTransition') }}
    </p>
    <div class="img-container">
      <img :src="'/assets/img/templateDesign/prepare-transition.svg'" />
    </div>
  </div>
  <div v-else class="upload-content">
    <div class="upload-content-header">
      <div
        class="header"
        v-html="$t('templateDesign.uploadTemplate.title')"
      ></div>
      <div class="sub-header">
        <p v-html="$t('templateDesign.uploadTemplate.subText')"></p>
      </div>
    </div>
    <div
      class="retry-error"
      v-if="error === 'CONVERSION_ERR' || error === 'UPLOAD_ERR'"
    >
      <div class="err-img">
        <img :src="'/assets/img/templateDesign/upload-failed.svg'" />
      </div>

      <div class="bottom-section">
        <div class="button-container">
          <v-btn rounded class="retry-btn" elevation="0" @click="clearFiles">
            {{ $t('templateDesign.uploadTemplate.retry') }}
          </v-btn>
          <v-btn
            elevation="2"
            class="pri-button"
            rounded
            color="primary"
            @click="backToStd"
          >
            {{ $t('templateDesign.uploadTemplate.useStd') }}
          </v-btn>
        </div>
        <p>
          {{ $t('templateDesign.needHelp') }}
          <a @click="getInTouch">{{ $t('templateDesign.getInTouch') }}</a>
          {{ $t('templateDesign.designTeamNow') }}
        </p>
      </div>
    </div>
    <div v-else class="file-upload-container">
      <div class="file-upload-wrap">
        <div class="file-upload-input">
          <div class="upload-section">
            <div class="upload-bar" @click="onUploadDivClick()">
              <p v-if="!fileNameType">
                {{ $t('templateDesign.uploadTemplate.uploadPlaceholder') }}
              </p>
              <p class="filename-text" v-if="fileNameType">
                {{ fileNameType }}
              </p>
              <input
                type="file"
                ref="hiddenInput"
                :accept="`${allowedUploadTypes.join(', ')}`"
                @change="handleOnDivClickFileSelection"
                style="display: none"
              />
            </div>
            <div class="icon-container">
              <div v-if="!fileNameType">
                <v-tooltip bottom open-delay="500" close-delay="300">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      depressed
                      x-small
                      icon
                      color="#41484D"
                      v-bind="attrs"
                      v-on="on"
                      @click="onUploadDivClick()"
                    >
                      <v-icon> mdi-tray-arrow-up </v-icon>
                    </v-btn>
                    <input
                      type="file"
                      ref="hiddenInput"
                      :accept="`${allowedUploadTypes.join(', ')}`"
                      @change="handleOnDivClickFileSelection"
                      style="display: none"
                    />
                  </template>
                  <span>{{ $t('templateDesign.uploadTemplate.upload') }}</span>
                </v-tooltip>
              </div>
              <div v-if="fileNameType">
                <v-tooltip bottom open-delay="500" close-delay="300">
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      depressed
                      x-small
                      icon
                      color="#41484D"
                      v-bind="attrs"
                      v-on="on"
                      @click="clearFiles()"
                    >
                      <v-icon> mdi-close-circle-outline</v-icon>
                    </v-btn>
                  </template>
                  <span>{{ $t('templateDesign.uploadTemplate.cancel') }}</span>
                </v-tooltip>
              </div>
            </div>
          </div>
        </div>
        <div
          class="what-to-upload"
          @click="openWhatToUpload()"
          v-html="$t('templateDesign.whatToUpload')"
        ></div>
      </div>
      <div v-if="error === 'SIZE_ERR'" class="error-wrapper">
        <img :src="'/assets/img/templateDesign/file-size-error.svg'" />
      </div>
      <div v-else class="template-attributes">
        <div class="loader-container" v-if="showUploadTransition">
          <img :src="'/assets/img/templateDesign/upload-transition-v2.gif'" />
        </div>
        <div class="dimension" v-if="attributeFlagArray[0]">
          <div
            class="text-load"
            :class="uploadAnimation ? 'ease-in-animation' : ''"
          >
            <div class="progress-spinner">
              <v-progress-circular
                v-if="!orientation"
                indeterminate
                color="primary"
              ></v-progress-circular>
              <v-icon v-else color="#21A7E0">mdi-check</v-icon>
            </div>
            <p class="text-value">{{ getDimensionText }}</p>
          </div>
          <p
            class="display-text"
            :class="uploadAnimation ? 'ease-in-animation' : ''"
            v-if="orientation"
          >
            {{ orientation }}
          </p>
        </div>
        <div class="color-palette-wrap" v-if="attributeFlagArray[1]">
          <div
            class="text-load"
            :class="uploadAnimation ? 'ease-in-animation' : ''"
          >
            <div class="progress-spinner">
              <v-progress-circular
                v-if="!isPaletteFilled"
                indeterminate
                color="primary"
              ></v-progress-circular>
              <v-icon v-else color="#21A7E0">mdi-check</v-icon>
            </div>
            <p>{{ getColorPaletteText }}</p>
          </div>

          <div
            class="palette-container"
            :class="uploadAnimation ? 'slide-animate' : ''"
          >
            <div
              class="palette-color"
              v-for="(selectedColor, i) in colorPaletteFill"
              :key="i"
              :id="'color' + i"
              :style="{ background: `#${selectedColor}` }"
            ></div>
          </div>
        </div>
        <div class="font-style-wrap" v-if="attributeFlagArray[2]">
          <div
            class="text-load"
            :class="uploadAnimation ? 'ease-in-animation' : ''"
          >
            <div class="progress-spinner">
              <v-progress-circular
                v-if="!fontStyle"
                indeterminate
                color="primary"
              ></v-progress-circular>
              <v-icon v-else color="#21A7E0">mdi-check</v-icon>
            </div>

            <p
              class="text-value"
              :class="uploadAnimation ? 'ease-in-animation' : ''"
            >
              {{ getFontStyleText }}
            </p>
          </div>

          <p
            :style="{ 'font-family': fontStyle }"
            class="display-text"
            :class="uploadAnimation ? 'ease-in-animation' : ''"
            v-if="fontStyle"
          >
            {{ fontStyle }}
          </p>
        </div>
      </div>
      <div class="bottom-section">
        <div class="button-container">
          <v-btn
            class="pri-button"
            rounded
            color="primary"
            :disabled="isNextDisabled"
            @click="handleNext"
          >
            {{ $t('templateDesign.nextBtn') }}
          </v-btn>
        </div>
        <p>
          {{ $t('templateDesign.needHelp') }}
          <a @click="getInTouch">{{ $t('templateDesign.getInTouch') }}</a>
          {{ $t('templateDesign.designTeamNow') }}
        </p>
        <div class="gs-privacy">
          <div>
            {{ $t('overnightPresentations.confidentiality') }}
            <a
              href="https://www.prezent.ai/security"
              target="_blank"
              class="primary--text"
              >{{ $t('fingerprint.learnMore') }}</a
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { v4 as uuidv4 } from 'uuid';
import { mapState } from 'vuex';
import moment from 'moment-timezone';
import { removeSpecialCharacters } from '../../../components/utils';
import { TemplateDesignerService } from './TemplateDesignerService';
import {
  uploadChunks,
  uploadOPFile,
  sendEmail,
} from '../../../utils/api-helper';
import WhatToUpload from './WhatToUpload.vue';
import ContactSoonModal from '../../../components/common/ContactSoonModal.vue';
import { getFileURL } from '@/utils/calyrex';

export default {
  name: 'UploadTemplate',
  data() {
    return {
      allowedUploadTypes: ['.pptx'],
      filesToUpload: [],
      showUploadTransition: false,
      fileName: '',
      isNextDisabled: true,
      orientation: '',
      fontStyle: '',
      colorPaletteFill: [],
      uploadAnimation: false,
      attributeFlagArray: [false, false, false],
      isPaletteFilled: false,
      extractedTargetFilters: {},
      isExtractionDone: false,
      uploadId: '',
      featureKey: 'comply',
      error: '',
      fileNameType: '',
      showTransition: false,
      isSlideAnimate: false,
      isNextClicked: false,
    };
  },
  props: {
    fileData: {},
  },
  mounted() {
    this.fileNameType = this.fileData?.fileNameType || '';
    this.orientation = this.fileData?.orientation || '';
    this.colorPaletteFill = this.fileData?.colorPaletteFill || [];
    this.fontStyle = this.fileData?.fontStyle || '';
    this.uploadId = this.fileData?.uploadId || '';
    if (this.fileNameType) {
      this.attributeFlagArray = [true, true, true];
      this.isPaletteFilled = true;
      this.isNextDisabled = false;
      this.isExtractionDone = true;
      this.isNextClicked = false;
      this.isSlideAnimate = false;
    }
  },
  computed: {
    ...mapState('users', ['currentUser']),
    getDimensionText() {
      return this.orientation
        ? this.$t('templateDesign.uploadTemplate.loaded.dimensions')
        : this.$t('templateDesign.uploadTemplate.loading.dimensions');
    },
    getColorPaletteText() {
      return this.isPaletteFilled || this.fileData?.colorPaletteFill?.length
        ? this.$t('templateDesign.uploadTemplate.loaded.colorPalette')
        : this.$t('templateDesign.uploadTemplate.loading.colorPalette');
    },
    getFontStyleText() {
      return this.fontStyle
        ? this.$t('templateDesign.uploadTemplate.loaded.fontStyle')
        : this.$t('templateDesign.uploadTemplate.loading.fontStyle');
    },

    templateDesignerService() {
      return new TemplateDesignerService();
    },
  },
  methods: {
    async getImgPath(filePath) {
      return await getFileURL(
        this.currentUser.user.id,
        filePath,
        this.currentUser.userIp,
        'IOCUpload',
      );
    },
    handleNext() {
      this.isNextClicked = true;
      if (this.isExtractionDone) {
        this.showTransition = false;
        this.handleSlideData();
      } else {
        this.showTransition = true;
      }
      this.$emit('handleTransition', this.showTransition);
    },
    backToStd() {
      this.$emit('backToStd', this.error);
    },
    clearFiles() {
      this.templateDesignerService.cancel();
      this.filesToUpload = [];
      this.fileName = '';
      this.fileNameType = '';
      this.orientation = '';
      this.fontStyle = '';
      this.colorPaletteFill = [];
      this.isNextDisabled = true;
      this.attributeFlagArray = [false, false, false];
      this.isPaletteFilled = false;
      this.$emit('setFileData', {});
      this.uploadAnimation = false;
      this.showUploadTransition = false;
      this.showTransition = false;
      this.isExtractionDone = false;
      this.isNextClicked = false;
      this.isSlideAnimate = false;
      this.extractedTargetFilters = {};
      this.error = '';
    },
    onUploadDivClick() {
      this.$refs.hiddenInput.click();
    },
    handleOnDivClickFileSelection(event) {
      if (event.target.files.length) {
        this.previewFiles(event.target.files);
      }
    },
    async previewFiles(event) {
      // Check file type
      const file = event[0];
      if (!['pptx'].includes(file.name.split('.').pop())) {
        this.error = 'FORMAT_ERR';
        return;
      }
      this.error = '';
      this.filesToUpload = [...event];
      this.filesToUpload = this.filesToUpload.map((i) => ({
        file: i,
        name: i.name,
        status: 'Processing',
        progress: 0,
        path: `${this.getOutputPath()}/${removeSpecialCharacters(i.name)}`,
        processingCompleted: false,
        requestIdentifier: uuidv4(),
      }));
      await this.uploadFiles();
    },
    getOutputPath() {
      const dt = new Date();
      const outputPath = `${this.currentUser.company.id}/${this.featureKey}/${
        dt.getFullYear() +
        String(dt.getMonth() + 1).padStart(2, '0') +
        String(dt.getDate()).padStart(2, '0')
      }/${dt.getHours()}${dt.getMinutes()}${dt.getSeconds()}`;
      return outputPath;
    },
    async uploadFiles() {
      if (this.filesToUpload.length > 0) {
        try {
          this.showUploadTransition = true;
          const { name } = this.filesToUpload[0];
          this.fileNameType = name;
          this.fileName = name && name.split('.')[0];
          if (this.filesToUpload[0].file.size / 1024 / 1024 > 40) {
            this.error = 'SIZE_ERR';
            this.templateDesignerService.cancel();
          } else {
            let fileSize = '';
            if (this.filesToUpload[0].file.size / 1024 / 1024 > 1) {
              fileSize = `${Math.round(
                this.filesToUpload[0].file.size / 1024 / 1024,
              )}MB`;
            } else {
              fileSize = `${Math.round(
                this.filesToUpload[0].file.size / 1024,
              )}KB`;
            }

            // TODO: Clear upload chunks on cancel?
            // Check why it is called multiple times randomnly
            const preProcessResp = await uploadChunks(
              this.filesToUpload[0],
              this.featureKey,
            );
            if (preProcessResp && preProcessResp === 'success') {
              const postProcessUpload = await uploadOPFile(
                this.filesToUpload[0].requestIdentifier,
                this.fileNameType,
                this.featureKey,
                true,
              );

              if (postProcessUpload && postProcessUpload.status === 'success') {
                const uploadedData =
                  await this.templateDesignerService.getUploadId({
                    s3: postProcessUpload.data.s3Bucket,
                    filepath: postProcessUpload.data.s3Prefix,
                    filename: this.fileNameType,
                    filesize: fileSize,
                  });

                if (uploadedData && uploadedData?.status === 'success') {
                  this.uploadId = uploadedData?.data?.upload_id;
                  if (this.uploadId) {
                    this.templateDesignerService
                      .fetchBrandPref(this.uploadId, (interval) => {
                        this.templateDesignerService.intervals.push(interval);
                      })
                      .then((preferencesResult) => {
                        if (preferencesResult) {
                          setTimeout(() => {
                            if (this.fileNameType) {
                              this.showUploadTransition = false;
                              this.attributeFlagArray[0] = true;
                              this.uploadAnimation = true;

                              setTimeout(() => {
                                if (
                                  preferencesResult?.slide_dimensions &&
                                  this.fileNameType
                                ) {
                                  this.orientation =
                                    preferencesResult?.slide_dimensions?.orientation;
                                  this.attributeFlagArray[1] = true;

                                  if (preferencesResult?.color_palette) {
                                    const palette = [];
                                    preferencesResult?.color_palette.forEach(
                                      (ele) => {
                                        if (ele.color_name) {
                                          palette.push(ele.color_name);
                                        }
                                      },
                                    );
                                    setTimeout(() => {
                                      if (
                                        palette.length &&
                                        this.colorPaletteFill.length <
                                          palette.length &&
                                        this.fileNameType
                                      ) {
                                        palette.forEach(() => {
                                          this.colorPaletteFill.push('DDE1E6');
                                        });
                                        setTimeout(() => {
                                          if (this.fileNameType) {
                                            const fillColorPallete = (
                                              fillIndex,
                                            ) => {
                                              // On clearing file
                                              if (!this.fileNameType) {
                                                this.colorPaletteFill = [];
                                                return null;
                                              }
                                              if (
                                                fillIndex === palette.length
                                              ) {
                                                this.attributeFlagArray[2] = true;
                                                this.isPaletteFilled = true;
                                                setTimeout(() => {
                                                  if (
                                                    preferencesResult?.style &&
                                                    this.fileNameType
                                                  ) {
                                                    this.fontStyle =
                                                      preferencesResult?.style?.font_style;
                                                    this.isNextDisabled = false;
                                                    if (
                                                      this.isExtractionDone &&
                                                      this.isNextClicked
                                                    ) {
                                                      this.isSlideAnimate = true;
                                                      setTimeout(() => {
                                                        this.handleSlideData();
                                                      }, 800);
                                                    }
                                                  }
                                                }, 5000);
                                                return null;
                                              }
                                              const elem =
                                                document.getElementById(
                                                  `color${fillIndex}`,
                                                );
                                              if (elem) {
                                                elem.style.backgroundColor = `#${palette[
                                                  fillIndex
                                                ].substring(2)}`;
                                                this.colorPaletteFill[
                                                  fillIndex
                                                ] =
                                                  palette[fillIndex].substring(
                                                    2,
                                                  );
                                                return setTimeout(
                                                  () =>
                                                    fillColorPallete(
                                                      fillIndex + 1,
                                                    ),
                                                  1000,
                                                );
                                              }
                                              return null;
                                            };
                                            fillColorPallete(0);
                                          }
                                        }, 1000);
                                      }
                                    }, 5000);
                                  }
                                }
                              }, 5000);
                            }
                          }, 20000);

                          this.templateDesignerService
                            .extractTargetFilters(
                              this.uploadId,
                              (interval) => {
                                this.templateDesignerService.intervals.push(
                                  interval,
                                );
                              },
                              'color_scheme_filter,font_color_filter',
                            )
                            .then((extractedFilter) => {
                              if (
                                extractedFilter &&
                                extractedFilter.status === 'success'
                              ) {
                                const filterKeys = Object.keys(
                                  extractedFilter?.data,
                                );
                                filterKeys.forEach(async (filter, index) => {
                                  this.extractedTargetFilters[filter] = {
                                    first_option: await this.getImgPath(
                                      extractedFilter?.data[filter]
                                        ?.first_option?.filepath,
                                    ),
                                    second_option: await this.getImgPath(
                                      extractedFilter?.data[filter]
                                        ?.second_option?.filepath,
                                    ),
                                  };
                                  if (index === filterKeys.length - 1) {
                                    this.isExtractionDone = true;
                                    if (this.isNextClicked) {
                                      this.isSlideAnimate = true;
                                    }
                                    setTimeout(() => {
                                      if (this.isNextClicked) {
                                        this.handleSlideData();
                                      }
                                      this.showTransition = false;
                                      this.$emit(
                                        'handleTransition',
                                        this.showTransition,
                                      );
                                    }, 800);
                                  }
                                });
                              }
                            })
                            .catch(() => {
                              this.isSlideAnimate = true;
                              setTimeout(() => {
                                this.showTransition = false;
                              }, 800);
                              this.error = 'CONVERSION_ERR';
                            });
                        }
                      })
                      .catch(() => {
                        this.error = 'UPLOAD_ERR';
                      });
                  }
                } else {
                  this.error = 'UPLOAD_ERR';
                }
              } else {
                this.error = 'UPLOAD_ERR';
              }
            } else {
              this.error = 'UPLOAD_ERR';
            }
          }
        } catch {
          // Failure cases
          // this.error = 'UPLOAD_ERR';
        }
      }
    },
    handleSlideData() {
      this.$emit('handleNext', this.extractedTargetFilters);
      this.$emit('setFileData', {
        uploadId: this.uploadId,
        fileNameType: this.fileNameType,
        orientation: this.orientation,
        colorPaletteFill: this.colorPaletteFill,
        fontStyle: this.fontStyle,
      });
    },
    getInTouch() {
      try {
        const params = {
          type: 'template-designer-request',
          meta: {
            firstName: `${this.currentUser?.user?.firstName}`,
            trialDate: `${moment(new Date()).format('MM/DD/YYYY')}`,
          },
        };
        sendEmail(params)
          .then((response) => console.log(response))
          .catch((err) => console.log(err));
      } catch (err) {
        console.log(err);
      }
      this.$modal.show(
        ContactSoonModal,
        {},
        {
          name: 'ContactSoonModal',
          width: '449px',
          height: '503px',
          styles: {
            borderRadius: '12px',
          },
        },
      );
    },
    openWhatToUpload() {
      this.$modal.show(
        WhatToUpload,
        {},
        {
          name: 'WhatToUpload',
          classes: 'v-application',
          height: 'auto',
          styles: {
            borderRadius: '20px',
            padding: '20px',
            'max-height': '800px',
            'min-height': '300px',
            'overflow-y': 'auto',
          },
        },
      );
    },
  },
  beforeDestroy() {
    this.templateDesignerService.cancel();
  },
};
</script>

<style lang="scss" scoped>
.upload-content {
  @keyframes fadeInAnimation {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
  @keyframes slide-left {
    from {
      margin-left: 25%;
    }
    to {
      margin-left: 0%;
    }
  }
  .upload-content-header {
    margin-bottom: 40px;
    font-size: 20px;
    .header {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 400;
      font-size: 45px;
      line-height: 52px;
      margin-bottom: 15px;
    }
    .sub-header {
      font-family: 'Lato';
      font-style: normal;
      font-weight: 400;
      font-size: 22px;
      line-height: 28px;
    }
  }
  .file-upload-container {
    display: grid;
    justify-items: center;
    grid-template-rows: 70px 260px auto;
    .file-upload-wrap {
      .file-upload-input {
        .upload-section {
          display: flex;
          align-items: center;
          border-bottom: 1px solid rgba(25, 28, 30, 1);
          padding: 0 10px;
          width: 380px;
          .upload-bar {
            cursor: pointer;
            flex-basis: calc(100% - 28px);
            p {
              padding-top: 12px;
              margin-top: 5px;
              text-align: left;
              font-size: 16px;
              margin-bottom: 10px;
            }
          }
          .icon-container {
            margin-top: 10px;
            cursor: pointer;
          }
        }
      }
      .what-to-upload {
        color: #21a7e0;
        font-size: 11px;
        text-align: start;
        width: 380px;
        padding: 3px 0px 0px 10px;
        font-family: Lato;
        cursor: pointer;
      }
    }

    .bottom-section {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin-top: 10px;
      .button-container {
        margin-bottom: 16px;
      }
      p {
        font-size: 14px;
      }
      .gs-privacy {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 17px;
        letter-spacing: 0.01em;
        color: #000000;

        a {
          color: #21a7e0;
          cursor: pointer;
        }
      }
    }

    .template-attributes {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      font-size: 16px;
      padding: 20px;
      .dimension,
      .font-style-wrap {
        margin-bottom: 20px;
        .display-text {
          margin-bottom: 0;
        }
      }
      .text-value {
        margin-bottom: 0;
      }
      .text-load {
        display: flex;
        justify-content: center;
        gap: 10px;
        p {
          margin-bottom: 10px;
        }
        &.ease-in-animation {
          animation: fadeInAnimation ease-in 1s;
          animation-iteration-count: 1;
          animation-fill-mode: forwards;
        }

        .progress-spinner {
          width: 18px;
          height: 18px;
          display: flex;
          margin-top: 2px;
          align-items: center;
        }
      }
      .color-palette-wrap {
        margin-bottom: 20px;
        .palette-container {
          display: flex;
          &.slide-animate {
            animation: 2s ease-in slide-left;
            animation-delay: 4s;
          }

          .palette-color {
            min-height: 32px;
            min-width: 32px;
            border: 1px solid rgba(0, 0, 0, 0.2);
            border-left: none;
            &:first-child {
              border: 1px solid rgba(0, 0, 0, 0.2);
            }
            background-color: #dde1e6;
            transition: all 0.5s ease;
          }
        }
      }
    }
    .error-wrapper {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      font-size: 16px;
      padding: 20px;
    }
  }
}
.loader-container {
  animation: fadeInAnimation ease-in 1s;
  opacity: 1;
  padding: 10px;
  img {
    height: 200px;
  }
}
.retry-error {
  .err-img {
    padding: 10px;
  }
  .button-container {
    display: flex;
    justify-content: center;
    gap: 30px;
    margin-bottom: 20px;
  }
}
.pri-button {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 22px;
  gap: 8px;

  width: auto;
  height: 32px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  text-transform: initial;
  letter-spacing: normal;
  line-height: 19px;

  /* Color Styles (Old)/White */

  color: #ffffff;

  background: #21a7e0;
  border-radius: 25px;
}
.retry-btn {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 6px 16px;
  gap: 8px;
  height: 32px;
  font-family: 'Lato';
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  text-transform: capitalize;
  letter-spacing: normal;
  line-height: 19px;

  /* Color Styles (Old)/White */

  color: #21a7e0;

  background: #ffffff;
  border: 1px solid #21a7e0;
  border-radius: 25px;

  &.v-btn--disabled {
    border: none;
  }
}
.filename-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  width: 332px;
}
@keyframes slide-out {
  0% {
    transform: translateX(0%);
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  100% {
    transform: translateX(-100%);
    opacity: 0;
  }
}
.transition-container {
  &.title-slide-animate {
    animation: 1s ease-out slide-out;
  }
  .transition-title {
    font-family: 'Lato';
    font-size: 45px;
    font-weight: 400;
    line-height: 52px;
    letter-spacing: 0em;
    text-align: center;
    margin-bottom: 70px;
  }
}
</style>
