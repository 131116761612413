<template>
  <v-app>
    <left-sidebar
      :update-content="updateCurrentSectionName"
      :mini="mini"
      :is-trial-expired="isTrialExpired"
      @expanded="updateMini"
      v-if="!fullScreenMode"
    />
    <Header
      :update-mini="handleMini"
      :minimize="minimize"
      :update-content="updateCurrentSectionName"
      :mini="mini"
      v-if="!fullScreenMode"
    />
    <v-main>
      <v-container fluid>
        <!-- <TrialAndSubscriptionBanner /> -->
        <BannersCarousel />
        <router-view />
      </v-container>
    </v-main>

    <!-- !-->
    <div class="snackbarsContainer">
      <!-- <LibraryConfirmationPopup v-if="getIsAddedToLibraryDownload" /> -->
      <CommonDownloadSnackbar v-if="getShowDownloadSnackbar" />
    </div>
    <!-- !-->

    <div class="tour-dialog" id="tour-dialog">
      <tour :content="tourContents[currentTour]" :tour-type="currentTour" />
    </div>
    <v-tooltip
      left
      max-width="200"
      content-class="tooltip-content"
      fixed
      open-delay="500"
      close-delay="300"
    >
      <template v-slot:activator="{ on, attrs }">
        <v-icon
          id="support"
          class="icon-title support"
          :color="showHelp ? 'primary' : 'black'"
          size="30"
          v-bind="attrs"
          v-on="on"
          @click="openHelpMenu"
        >
          mdi-help-circle-outline
        </v-icon>
      </template>
      <span>{{ $t('help.help') }}</span>
    </v-tooltip>
    <div class="confetti-container" v-if="getConfettiFlag">
      <img
        class="confetti-img slide-down"
        :src="'/assets/img/templateDesign/confetti.png'"
      />
    </div>

    <!-- added to library or favorites snackbar attached to bottom of page !-->
    <AddedToLibOrFavSnackbars
      v-if="
        getAddedToLibOrFavSnackbar &&
        Object.keys(getAddedToLibOrFavSnackbar).length > 0
      "
    />
  </v-app>
</template>

<script>
import Vue from 'vue';
import moment from 'moment';
import { mapState, mapActions, mapGetters } from 'vuex';
import { Auth } from 'aws-amplify';
import { notificationWorker } from '../workers/notification-worker/notificationWorker';
import { workerApp } from '../workers/worker-app/workerApp';
import SubscriptionExpired from '../components/common/SubscriptionExpired.vue';
import Header from '../components/Header.vue';
import LeftSidebar from '../components/LeftSidebar.vue';
// import SupportDetail from '../components/common/SupportDetail';
import HelpMenu from '../components/Main/Help/HelpMenu.vue';
import Tour from '../components/common/Tour';
// import TrialAndSubscriptionBanner from '../components/TrialAndSubscriptionBanner.vue';
import BannersCarousel from '../components/common/BannersCarousel.vue';
import CommonDownloadSnackbar from '../components/common/CommonDownloadSnackbar.vue';
// import LibraryConfirmationPopup from '../components/common/LibraryConfimationPopup.vue';
import AddedToLibOrFavSnackbars from '../components/common/AddedToLibOrFavSnackbars.vue';

// import LoginSubscribeModal from '../components/common/LoginSubscribeModal.vue';
// import GreatDecisionModal from '../components/common/GreatDecisionModal.vue';
// import ContactSoonModal from '../components/common/ContactSoonModal.vue';
// import PaymentDeclinedModal from "../components/common/PaymentDeclinedModal.vue";
// import { notificationsForUser } from '../graphql/queries';
import { AnalyticsHandler } from '../components/common/Analytics/AnalyticsHandler';
import { trackHelpEvents } from '../components/common/Analytics/HelpEvents';
import { tourService } from '../services/tourServie';
import {
  getCompanies,
  getNotificationsForUser,
  getKnowCategories,
  getFingerPrintAdditionalData,
  getOpPssEntity,
  getFirstSignin,
} from '../utils/api-helper';
import { getLoggedInUserToken } from '../utils/api';
import AudienceApi from '../API/audience-api';
import commonApi from '../API/common-api';
import usersApi from '../API/users-api';
import Ipify from '@/services/ipify';
import { clearCachedUser } from '../utils/common.js';
import { TypeSenseServer } from './components/TypeSenseInit';
import BrandedTemplatePopup from '../components/common/BrandedTemplatePopup.vue';
import { premiumFeatures, premiumPlans } from '../components/utils';

export default {
  name: 'Home',
  components: {
    Header,
    LeftSidebar,
    Tour,
    // TrialAndSubscriptionBanner,
    BannersCarousel,
    CommonDownloadSnackbar,
    // LibraryConfirmationPopup,
    AddedToLibOrFavSnackbars,
  },
  mixins: [tourService],
  data() {
    return {
      currentSectionName: 'FingerPrints',
      mini: true,
      fullScreenMode: false,
      isFirstVisit: true,
      newFeaturePopup: {},
      showHelp: false,
      subFeaturePopupNames: [
        'New feature popup - Add Context',
        'New feature popup -[Synthesis] Modify Data Style',
        'New feature popup -[Template Converter] Deck Level Changes',
        'New feature popup - Prompt Suggestion',
        'New feature popup -[Template Converter](4.1 Release) 100% edit functionality',
        'New feature popup - Auto Generator(Graph Support)',
      ],
      subFeatureIds: [
        'autoGen_add_context_cta',
        'syntesis_modify_data_style',
        'comply_edit_color',
        'autoGen_ag_live_prompts',
        'autoGen_graph_support',
        'comply_color_suggestion',
      ],
      notADataSensitiveFeatureNames: [
        'default_download_settings',
        'slide_library',
        'best_practice_library',
      ],
      tourContents: {
        'new-presentation':
          "Build a new personalized presentation shell such as 'business plan' or 'project update', that you can fill in with your ideas.",
        'my-presentation':
          'Review the presentation shells that you have built or your colleagues have shared with you.',
        slides:
          'Search and download slide templates in your company brand and fill in with your ideas.',
        // vault:
        //   '<span class="highlight">Coming Soon!</span> Quickly access presentations and slides uploaded by you or your company colleagues.',
        fingerprints:
          'Discover the unique visual, story and data preferences of your colleagues.',
        learn:
          'Learn how you want, when you want with bite-sized, gamified learning modules on business communication',
        practice:
          'Complete practice exercises to enhance your business communication.',
        // overnight:
        //   '<span class="highlight">Coming Soon!</span> Submit your slides and get designer quality presentations overnight.',
        notifications:
          'Receive notifications on updates from your colleagues or Prezent.',
        profile:
          'Manage your profile, view your team activity or submit feature requests!',
        support: 'Learn quick tips or get support.',
        audience: 'Add individual colleagues or groups as audience members.',
        share: 'Invite your colleagues to a free trial!',
        newNotification: [],
      },
      types: {
        'new-presentation': {
          left: '230px',
          top: '65px',
          right: 'auto',
          bottom: 'auto',
        },
        'my-presentation': {
          left: '230px',
          top: '140px',
          right: 'auto',
          bottom: 'auto',
        },
        slides: {
          left: '230px',
          top: '210px',
          right: 'auto',
          bottom: 'auto',
        },
        // vault: {
        //   left: "230px",
        //   top: "290px",
        //   right: "auto",
        //   bottom: "auto",
        // },
        // fingerprints: {
        //   left: "230px",
        //   top: "290px",
        //   right: "auto",
        //   bottom: "auto",
        // },
        learn: {
          left: '230px',
          top: '315px',
          right: 'auto',
          bottom: 'auto',
        },
        // overnight: {
        //   left: "230px",
        //   top: "460px",
        //   right: "auto",
        //   bottom: "auto",
        // },
        // overnight: {
        //   left: "230px",
        //   top: "540px",
        //   right: "auto",
        //   bottom: "auto",
        // },
        notifications: {
          left: 'auto',
          right: '81px',
          top: '35px',
          bottom: 'auto',
        },
        newNotification: {
          left: 'auto',
          right: '81px',
          top: '35px',
          bottom: 'auto',
        },
        profile: {
          left: 'auto',
          right: '4px',
          top: '35px',
          bottom: 'auto',
        },
        audience: {
          left: 'auto',
          right: '126px',
          top: '35px',
          bottom: 'auto',
        },
        share: {
          left: 'auto',
          right: '33px',
          top: '35px',
          bottom: 'auto',
        },
        support: {
          left: 'auto',
          right: '87px',
          top: 'auto',
          bottom: '6px',
        },
        noNotification: {
          left: 'auto',
          right: '40px',
          top: '35px',
          bottom: 'auto',
        },
      },
      currentTour: 'initial',
      audiences: [],
      welcomeTourGuideId: 'bVJOKc7saLh9qQWvNC7kt-3dB9s',
      notifWorker: null,
    };
  },
  async beforeMount() {
    if (
      Object.keys(this.currentUser).find((key) => key === 'user') === undefined
    ) {
      this.handleSignOut();
    }
    this.setStartLoadingSlidesFlag(false);
    performance.mark('start');
    this.loadFeatureReleaseInfo();
    const userIp = await this.getIpAddress();
    const userData = await this.refreshCurrentUserInfo();
    if (this.getOPAccessLevel !== 'no_access') {
      getOpPssEntity(userData.company.id).then((resp) => {
        if (resp) {
          this.setOPLimtedBanner(resp.context);
        } else {
          this.setOPLimtedBanner(null);
        }
      });
    } else {
      this.setOPLimtedBanner(null);
    }
    await this.setCurrentUser({ ...userData, userIp });
    // Display modal for trial users
    if (this.isTrialExpired) {
      this.displayTrialExpiredModal();
    }
    if (this.currentUser?.user?.showBrandedCompanyPopUp === 0) {
      this.showConfetti();
      this.displayBrandedCompanyPopup();
    }
    const themes = await commonApi.methods.getThemesList();
    const filteredThemes = themes.filter(
      (item) => item.companyID === this.currentUser.user.companyID,
    );
    this.setFilteredThemes(filteredThemes);
    const currentTheme = this.currentUser.theme;
    if (currentTheme) {
      this.setCurrentTheme(currentTheme);
    }

    try {
      const typesenseUser = await AudienceApi.methods.getTypesenseUserAudience(
        userData.company.id,
        userData.user.id,
      );

      const typesenseRealtimeAudiences =
        await AudienceApi.methods.getRealtimeAudiences(
          userData.company.id,
          userData.user.id,
        );
      this.setTypesenseRealtimeAudiences({
        audiences: typesenseRealtimeAudiences,
        self: typesenseUser,
      });
    } catch (err) {
      console.error(err);
    }

    this.setStartLoadingSlidesFlag(true);
    performance.mark('end');
    console.log(
      performance.measure('duration', 'start', 'end').duration,
      '---------load aud duration--------',
    );
    try {
      const fingerPrintAdditionalData = await getFingerPrintAdditionalData();
      this.setFingerPrintAdditionalDetails(fingerPrintAdditionalData);
    } catch (err) {
      console.log(err);
    }
    await TypeSenseServer.setUpClient();
    await this.loadAvailablePlans(userData); // load available plans has dependency on load current subscription
    this.loadCurrentSubscription(userData);
    this.resetKnow();
    try {
      const resp = await getCompanies();
      const domains = JSON.parse(
        JSON.stringify(resp?.data?.getCompanies?.domains),
      );
      await this.setCompanyDetails(resp?.data?.getCompanies);
      this.addCompanyDomain(domains);
      const companyData = JSON.parse(JSON.stringify(resp?.data?.getCompanies));
      this.addCompanyData(companyData);
    } catch (err) {
      console.log(err);
    }
    if (this.isKnowEnabled) {
      try {
        const resp = await getKnowCategories();
        this.setKnowCategories(resp?.data);
      } catch (err) {
        console.log(err);
      }
    }
    this.initializeNotifications();
    if (this.notifWorker !== null) {
      this.stopNotificationWorker();
    }
    this.startNotificationWithWorker();

    this.cacheUserdata(this.currentUser);

    const trialDaysLeftLastShown = localStorage.getItem(
      'trialDaysLeftLastShown',
    );
    if (
      (this.trialDaysLeft === 2 || this.trialDaysLeft === 7) &&
      trialDaysLeftLastShown !== this.trialDaysLeft &&
      this.currentUser.user.role === 'TrialUser'
    ) {
      this.displayTrialExpiredModal();
    }
  },
  computed: {
    ...mapState('featureReleaseInfo', ['featureReleaseInfo']),
    ...mapState('users', [
      'currentUser',
      'isTrialExpired',
      'themes',
      'trialDaysLeft',
      'currentActiveSubscription',
      'showBrandedCompanyPopUp',
    ]),
    hasOPAccess() {
      return this.getOPAccessLevel && this.getOPAccessLevel !== 'no_access';
    },
    ...mapState('tourStore', ['notifications']),
    ...mapGetters('pendoStore', ['getGuides']),
    ...mapGetters('pendoStore', ['isGuidesLoaded']),
    ...mapGetters('users', [
      'isKnowEnabled',
      'getOPAccessLevel',
      'getConfettiFlag',
      'getFeatureAccessDate',
      'getSubFeatureReleaseDate',
      'isUploadFullAccess',
      'isGenerateFullAccess',
      'isComplyFullAccess',
      'isGenerateLivePromptsFullAccess',
      'isSynthesisFullAccess',
      'isRedesignFullAccess',
      'isKnowEnabled',
    ]),
    ...mapGetters('commonDownloads', [
      'getShowDownloadSnackbar',
      'getIsAddedToLibraryDownload',
      'getAddedToLibOrFavSnackbar',
    ]),
    welcomeTourGuide() {
      return this.getGuides.find((g) => g.id === this.welcomeTourGuideId);
    },
    allowReplaceIconOverall() {
      const repIconOverallFeature = this.currentUser?.features?.find(
        (ft) => ft.feature_name === 'replace_icon_overall',
      );
      return repIconOverallFeature && repIconOverallFeature.enabled;
    },
    isProfessionalOrPremiumUser() {
      const planIds = ['12347', '12349'];
      return planIds.includes(this.currentActiveSubscription?.planID);
    },
    isEnabledForEveryone() {
      return true;
    },
  },
  methods: {
    /*   ...mapActions("recommended", [
      "setAssetsNextToken",
      "setAssetsStartIndex",
      "fetchCurrentUserFavorites",
    ]), */
    ...mapActions('featureReleaseInfo', ['loadFeatureReleaseInfo']),
    ...mapActions('knowStore', ['setKnowCategories', 'resetKnow']),
    ...mapActions('tourStore', [
      'setTour',
      'setNotifications',
      'setLoadingNotificationsFlag',
    ]),
    ...mapActions('users', [
      'fetchCurrentUserFavorites',
      'setCurrentUser',
      'setOPLimtedBanner',
      'loadCurrentSubscription',
      'setFilteredThemes',
      'setCurrentTheme',
      'addCompanyDomain',
      'addCompanyData',
      'setCompanyDetails',
      'setStartLoadingSlidesFlag',
      'loadAvailablePlans',
      'setTypesenseRealtimeAudiences',
      'setFingerPrintAdditionalDetails',
      'showConfetti',
    ]),
    handleVisibility() {
      if (document.visibilityState === 'hidden') {
        if (this.notifWorker !== null) {
          this.stopNotificationWorker();
        }
        console.log('Navigated away, worker closed');
      } else {
        if (this.notifWorker !== null) {
          this.stopNotificationWorker();
        }
        this.startNotificationWithWorker();
        console.log('user active, worker started');
      }
    },

    async getFeatureAndSubFeaturePopups(feature) {
      let firstSignIn = null;
      await getFirstSignin(this.currentUser?.user.id).then((timestamp) => {
        firstSignIn = moment(timestamp);
      });
      const currentDate = moment().utc();
      console.log(currentDate);
      const accessDate = moment(this.getFeatureAccessDate(feature)).utc();
      const alreadyExecuted = this.newFeaturePopup[feature];
      switch (feature) {
        case 'slide-generate': {
          let staticDate = moment(
            this.getSubFeatureReleaseDate(feature, 'add-context'),
          ).utc();
          let staticDateGraph = moment(
            this.getSubFeatureReleaseDate(feature, 'graph_support'),
          ).utc();
          staticDateGraph = staticDateGraph.subtract(1, 'days');
          const featuresList = [];
          staticDate = staticDate.subtract(1, 'days');
          const livePromptAccessDate = moment(
            this.getFeatureAccessDate('ag_live_prompts'),
          ).utc();
          if (
            (firstSignIn.isBefore(currentDate) &&
              firstSignIn.isBefore(livePromptAccessDate)) ||
            (accessDate.isAfter(livePromptAccessDate) && alreadyExecuted)
          ) {
            featuresList.push({
              pendoId: 'KOHN_vfxE8wUY0rrq5Od3jupQKM',
              featureName: 'ag_live_prompts',
              featureAccessProp: 'isGenerateLivePromptsFullAccess',
              module: 'generate',
              subFeatureName: 'autoGen_ag_live_prompts',
            });
          }
          if (
            (firstSignIn.isBefore(currentDate) &&
              firstSignIn.isBefore(staticDateGraph)) ||
            (accessDate.isAfter(staticDateGraph) && alreadyExecuted)
          ) {
            featuresList.push({
              pendoId: 'poJw7DpG8_d3Iv9sRBehjpd_Ns4',
              featureName: 'slide-generate',
              subFeatureName: 'autoGen_graph_support',
              featureAccessProp: 'isGenerateFullAccess',
              module: 'generate',
            });
          }
          if (
            (firstSignIn.isBefore(currentDate) &&
              firstSignIn.isBefore(staticDate)) ||
            (accessDate.isAfter(staticDate) && alreadyExecuted)
          ) {
            featuresList.push({
              pendoId: 'aWZ1IH8fwrCaUU3-inMTLv_wJVw',
              featureName: 'slide-generate',
              subFeatureName: 'autoGen_add_context_cta',
              featureAccessProp: 'isGenerateFullAccess',
              module: 'generate',
            });
          }
          if (featuresList.length) {
            return featuresList;
          }
          return [
            {
              pendoId: 'QIgrHB9LBEYf2C-fSLr_DKvCh_c',
              featureName: 'slide-generate',
              featureAccessProp: 'isGenerateFullAccess',
              module: 'generate',
            },
          ];
        }
        case 'synthesis': {
          let staticDate = moment(
            this.getSubFeatureReleaseDate(feature, 'synthesis_modify_data'),
          ).utc();
          staticDate = staticDate.subtract(1, 'days');
          if (
            (firstSignIn.isBefore(currentDate) &&
              firstSignIn.isBefore(staticDate)) ||
            (accessDate.isAfter(staticDate) && alreadyExecuted)
          ) {
            return {
              pendoId: 'h9-W-9HlML2mXfG08BPNIcTqAuA',
              featureName: 'synthesis',
              subFeatureName: 'syntesis_modify_data_style',
              featureAccessProp: 'isSynthesisFullAccess',
              module: 'synthesis',
            };
          }
          return {
            pendoId: 'edqhxD_dIGOgQMYDX9Te8cKbn3w',
            featureName: 'synthesis',
            featureAccessProp: 'isSynthesisFullAccess',
            module: 'synthesis',
          };
        }
        case 'comply': {
          let staticDate = moment(
            this.getSubFeatureReleaseDate(feature, 'comply_edit_color'),
          ).utc();
          staticDate = staticDate.subtract(1, 'days');
          let staticDateColor = moment(
            this.getSubFeatureReleaseDate(feature, 'comply_color_suggestion'),
          ).utc();
          staticDateColor = staticDateColor.subtract(1, 'days');
          const featuresList = [];
          if (
            (firstSignIn.isBefore(currentDate) &&
              firstSignIn.isBefore(staticDateColor)) ||
            (accessDate.isAfter(staticDateColor) && alreadyExecuted)
          ) {
            featuresList.push({
              pendoId: 'ueUvP84iIzZMfy5kxDi1M_BgDI4',
              featureName: 'comply',
              subFeatureName: 'comply_color_suggestion',
              featureAccessProp: 'isComplyFullAccess',
              module: 'comply',
            });
          }
          if (
            (firstSignIn.isBefore(currentDate) &&
              firstSignIn.isBefore(staticDate)) ||
            (accessDate.isAfter(staticDate) && alreadyExecuted)
          ) {
            featuresList.push({
              pendoId: 'xzpjjzs0EwFCV4Zvugf8zFsVtpM',
              featureName: 'comply',
              subFeatureName: 'comply_edit_color',
              featureAccessProp: 'isComplyFullAccess',
              module: 'comply',
            });
          }
          if (featuresList.length) {
            return featuresList;
          }
          return [
            {
              pendoId: 'quq7pK67Qf3RsaJu5SuvJtw4kyo',
              featureName: 'comply',
              featureAccessProp: 'isComplyFullAccess',
              module: 'comply',
            },
          ];
        }
        default:
          return null;
      }
    },
    async activateNewFeaturePopups() {
      const newFeaturePopupConfig = [
        {
          pendoId: 'yVtz_iP59_ZNqf-hyZTKvJpwVJo',
          featureName: 'overnight-prezentations',
          featureAccessProp: 'hasOPAccess',
          module: 'op',
        },
        {
          pendoId: 'pBo1g1DPFWYbDaBZINkzN2SQo8c',
          featureName: 'user-prezentation-upload',
          featureAccessProp: 'isUploadFullAccess',
          module: 'upload',
        },
        ...(await this.getFeatureAndSubFeaturePopups('slide-generate')),
        {
          ...(await this.getFeatureAndSubFeaturePopups('synthesis')),
        },
        ...(await this.getFeatureAndSubFeaturePopups('comply')),
        {
          pendoId: '6ONDopkFdgxWd4METGBTJeGTwzU',
          featureName: 'redesign',
          featureAccessProp: 'isRedesignFullAccess',
          module: 'redesign',
        },
        {
          pendoId: 'kSQJGmbT9f7Km3NgV6Cm2ubfWVQ',
          featureName: 'replace_icon_overall',
          featureAccessProp: 'allowReplaceIconOverall',
          module: 'replace_icon',
        },
        {
          pendoId: 'ttZjHI7dTRJWOIO1doch1iAunnw',
          featureName: 'default_download_settings',
          featureAccessProp: 'isEnabledForEveryone',
          module: 'download_settings',
        },
        {
          pendoId: 'NSn68AHwUaBh1ByUZPzehbNjRHI',
          featureName: 'slide_library',
          featureAccessProp: 'isEnabledForEveryone',
          module: 'slide_library',
        },
        {
          pendoId: 'RZY5qcF5IJ_PglHhLL5NnUju7qw',
          featureName: 'best_practice_library',
          featureAccessProp: 'isKnowEnabled',
          module: 'best-practice-library',
        },
      ].filter((itm) => Object.keys(itm).length > 0);

      console.log('newFeaturePopupConfig', newFeaturePopupConfig);

      newFeaturePopupConfig.forEach((popup) => {
        const featureAccess = this[popup.featureAccessProp];
        const alreadyExecuted =
          this.newFeaturePopup[popup.subFeatureName || popup.featureName];
        const premiumUserAndFeature =
          premiumFeatures.indexOf(popup.module) >= 0 &&
          premiumPlans.indexOf(this.currentActiveSubscription.planID) >= 0;

        if (
          featureAccess &&
          !alreadyExecuted &&
          ((popup.subFeatureName &&
            this.subFeatureIds.includes(popup.subFeatureName)) ||
            !premiumUserAndFeature)
        ) {
          const guides = window.pendo.guides || [];

          if (guides.length) {
            const guide = guides.find((g) => g.id === popup.pendoId);
            this.$set(
              this.newFeaturePopup,
              popup.subFeatureName || popup.featureName,
              true,
            );

            console.log(
              'guide',
              guide?.name,
              guide?.hasBeenSeen(),
              guide?.isGuideSnoozed(),
            );

            if (
              guide &&
              this.subFeaturePopupNames.includes(guide.name) &&
              !guide.hasBeenSeen() &&
              !guide.isGuideSnoozed()
            ) {
              guide.show({ reason: 'user-seen' });
            } else if (
              guide &&
              !this.subFeaturePopupNames.includes(guide.name) &&
              !guide.hasBeenSeen() &&
              !guide.isGuideSnoozed()
            ) {
              getFirstSignin(this.currentUser?.user.id).then((timestamp) => {
                const accessDate = this.getFeatureAccessDate(popup.featureName);
                if (timestamp && accessDate) {
                  const firstSignin = moment(timestamp);
                  const accessDateObj = moment(accessDate);
                  if (firstSignin.isBefore(accessDateObj)) {
                    guide.show({ reason: 'user-seen' });
                  }
                } else if (
                  moment(timestamp).isBefore(
                    this.featureReleaseInfo.release_4100[0].release_date,
                  ) &&
                  this.notADataSensitiveFeatureNames.includes(popup.featureName)
                ) {
                  guide.show({ reason: 'user-seen' });
                }
              });
            }
          }
        }
      });
    },
    stopNotificationWorker() {
      this.notifWorker.terminate();
    },
    async startNotificationWithWorker() {
      if (window.Worker) {
        this.notifWorker = workerApp(notificationWorker);
        const loggedInUserToken = await getLoggedInUserToken();
        this.notifWorker.postMessage({
          token: loggedInUserToken,
        });
        this.notifWorker.addEventListener('message', (e) => {
          if (e.data === 'worker unauthorized') {
            this.stopNotificationWorker();
            window.location = '/signin/10001';
          }
          this.setNotifications([...e.data]);
        });
      }
    },
    initializeNotifications() {
      getNotificationsForUser()
        .then((resp) => {
          this.tourContents.newNotification = resp.data;
          this.setNotifications([...this.tourContents.newNotification]);
          this.setLoadingNotificationsFlag(false);
        })
        .catch((err) => {
          console.log(err);
          this.setLoadingNotificationsFlag(false);
        });
    },
    setFullScreen(fullScreenMode) {
      this.fullScreenMode = fullScreenMode;
    },
    updateMini() {
      this.mini = false;
    },
    displayBrandedCompanyPopup() {
      this.$modal.show(
        BrandedTemplatePopup,
        {},
        {
          name: 'BrandedTemplatePopup',
          width: '400px',
          height: '426px',
          styles: {
            borderRadius: '12px',
          },
        },
      );
    },
    displayTrialExpiredModal() {
      this.$modal.show(
        SubscriptionExpired,
        {
          trialDaysLeft: 0,
          isTrialUser: this.currentUser.user.role === 'TrialUser',
        },
        {
          name: 'SubscriptionExpired',
          clickToClose: false,
          width: '600px',
          height: 'auto',
          styles: { borderRadius: '20px' },
        },
        {
          'before-close': () => {},
        },
      );
    },
    beforeClose(event) {
      if (!event.params?.close) {
        event.cancel();
      }
    },
    updateCurrentSectionName(sectionName) {
      this.show = false;
      this.currentSectionName = sectionName;
      Vue.nextTick(() => {
        this.show = true;
      });
      this.componentKey += 1;
    },
    minimize() {
      this.mini = true;
    },
    handleMini() {
      this.mini = !this.mini;
    },
    getIpAddress() {
      return Ipify.get();
    },
    openHelpMenu(event, openSupport = false) {
      trackHelpEvents.helpIconClick(this.currentUser);
      this.showHelp = true;

      this.$modal.show(
        HelpMenu,
        { openSupport },
        {
          name: 'HelpMenu',
          width: '468px',
          height: '680px',
          adaptive: true,
          shiftY: 0.97,
          shiftX: 0.96,
          styles: {
            borderRadius: '16px',
            'overflow-y': scroll,
          },
        },
        {
          closed: () => {
            this.showHelp = false;
          },
        },
      );
    },

    async cacheUserdata(data) {
      const userdata = { ...data };
      userdata.preferences = [];
    },
    handleSignOut() {
      clearCachedUser();
      Auth.signOut();
    },
    setTourData(val) {
      if (val) {
        this.currentTour = val;
        const tourWrapper = document.querySelector('#tour-dialog');
        if (this.currentTour === 'initial') {
          tourWrapper.style.display = 'none';
          this.mini = true;
        } else {
          tourWrapper.style.display = 'block';
          tourWrapper.style.left = this.types[this.currentTour].left;
          tourWrapper.style.top = this.types[this.currentTour].top;
          tourWrapper.style.right = this.types[this.currentTour].right;
          tourWrapper.style.bottom = this.types[this.currentTour].bottom;
        }
        if (
          this.currentTour !== 'initial' &&
          this.currentTour !== 'notifications' &&
          this.currentTour !== 'newNotification' &&
          this.currentTour !== 'noNotification' &&
          this.currentTour !== 'profile' &&
          this.currentTour !== 'support' &&
          this.currentTour !== 'audience' &&
          this.currentTour !== 'share'
        ) {
          tourWrapper.classList.remove('right-arrow');
          tourWrapper.classList.remove('top-arrow');
          tourWrapper.classList.add('left-arrow');
        } else if (
          this.currentTour === 'notifications' ||
          this.currentTour === 'newNotification' ||
          this.currentTour === 'noNotification' ||
          this.currentTour === 'profile' ||
          this.currentTour === 'share' ||
          this.currentTour === 'audience'
        ) {
          tourWrapper.classList.remove('right-arrow');
          tourWrapper.classList.remove('left-arrow');
          tourWrapper.classList.add('top-arrow');
        } else {
          tourWrapper.classList.remove('top-arrow');
          tourWrapper.classList.remove('left-arrow');
          tourWrapper.classList.add('right-arrow');
        }
      }
    },

    showWelcomeTourGuide(welcomeTourGuide) {
      if (!this.currentUser.user.takenOrSkippedTour) {
        if (
          welcomeTourGuide &&
          welcomeTourGuide.show &&
          !welcomeTourGuide.hasBeenSeen
        ) {
          welcomeTourGuide.show();
        } else if (welcomeTourGuide?.show) {
          const userdata = {
            takenOrSkippedTour: true,
          };
          usersApi.methods
            .updateUserProfile(userdata)
            .then((updatedUser) => {
              const userInfo = this.currentUser;
              userInfo.user = updatedUser;
              this.setCurrentUser(this.currentUser);
            })
            .catch((err) => console.log('error while updating skip tour', err));
        }
      }
    },
    noOfDays(ratedDate) {
      const fromDate = new Date(ratedDate);
      const threeMonthAfterDate = fromDate;
      threeMonthAfterDate.setMonth(threeMonthAfterDate.getMonth() + 3);
      const days = Math.floor(
        (threeMonthAfterDate - Date.parse(ratedDate)) / (1000 * 60 * 60 * 24),
      );
      return days;
    },
    daysAgo(ratedDate) {
      const days = Math.floor(
        (new Date() - Date.parse(ratedDate)) / (1000 * 60 * 60 * 24),
      );
      return days;
    },
    async refreshCurrentUserInfo() {
      const userinfo = await usersApi.methods.getUserInfo();
      return userinfo;
    },
    async updateTemplateCompletePopup() {
      const userData = {
        performIncrements: {
          designerPopupSeen: 1,
        },
      };
      await usersApi.methods
        .updateUserProfile(userData)
        .then((updatedUser) => {
          const userInfo = this.currentUser;
          userInfo.user = updatedUser;
          this.setCurrentUser(userInfo);
        })
        .catch((err) => console.log(err));
    },
  },
  watch: {
    welcomeTourGuide(newVal) {
      this.showWelcomeTourGuide(newVal);
    },
    isGuidesLoaded() {
      this.activateNewFeaturePopups();
    },
    notifications(newVal) {
      /* The default of designerPopupSeen is 10, and is 0 on complete. We are dependent on the notification and this flag
         to show the confetti once and incrementing by 1 so that the Popup (in Main) is still shown. 
         Show confetti on values < 1, increment by 1 to still show popup.
       */
      const isDesignerNotif = newVal.find(
        (item) => item?.use_case === 'designer_completion',
      );
      if (
        this.currentUser?.user?.designerPopupSeen < 1 &&
        !window.location.href.includes('/home/main') &&
        isDesignerNotif
      ) {
        this.showConfetti();
        this.updateTemplateCompletePopup();
      }
    },
  },
  mounted() {
    // this.mini = false;
    this.mini = !this.currentUser.user.navbarExpanded;
    document.addEventListener('visibilitychange', this.handleVisibility, false);
    this.setNotifications([]);
    this.setLoadingNotificationsFlag(true);
    AnalyticsHandler.loadedHome(this.currentUser);
    AnalyticsHandler.logUserStats(this.currentUser);
    this.showWelcomeTourGuide(this.welcomeTourGuide);
    this.$root.$on('openHelpMenuRequest', () => {
      this.openHelpMenu(null, true);
    });
    this.$root.$on('openHelpMenuPopUp', () => {
      this.openHelpMenu(null, false);
    });
    this.$root.$on('FULL_SCREEN', this.setFullScreen);
  },
  created() {
    /** TODO(Srikanth) - need to find the best of detecting the change using vuex
    store management. as it is a high priority fix, introduced rxjs with out memory leaks  */
    this.subscription = tourService.getTourType().subscribe((tourType) => {
      if (tourType) {
        this.setTourData(tourType);
      }
    });
  },
  beforeDestroy() {
    // unsubscribe to ensure no memory leaks
    if (this.notifWorker !== null) {
      this.stopNotificationWorker();
    }
    document.removeEventListener(
      'visibilitychange',
      this.handleVisibility,
      false,
    );
    this.subscription.unsubscribe();
    this.$root.$off('openHelpMenuRequest');
    this.$root.$off('openHelpMenuPopUp');
    this.$root.$off('FULL_SCREEN', this.setFullScreen);
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@keyframes slide-down {
  0% {
    transform: translateY(-50%);
    opacity: 1;
  }
  98% {
    opacity: 1;
  }
  100% {
    transform: translateY(150%);
    opacity: 0;
  }
}
.slide-down {
  animation: 3.2s linear slide-down;
  //animation-delay: 200ms;
}
.snackbarsContainer {
  position: fixed;
  bottom: 6px;
  left: 6%;
  z-index: 1001;
  width: 400px;
  border-radius: 5px 5px 0 0;
  display: flex;
  flex-direction: column;
  gap: 16px;
}
.confetti-container {
  position: fixed;
  overflow: hidden;
  height: 100vh;
  width: 100%;
  z-index: 10000;
  top: 0;
  left: 0;
  pointer-events: none;
  .confetti-img {
    position: absolute;
    width: 100%;
    transform: translateY(-100%);
    top: 0;
    left: 0;
  }
}
::v-deep .v-application--wrap {
  flex-direction: row;
  .container.container--fluid {
    height: 100%;
    padding: 0px;
  }
}
::v-deep .v-toolbar__content {
  background: white;
}

.v-main {
  overflow: hidden;
}

.support {
  align-items: center;
  border-radius: 50%;
  bottom: 30px;
  cursor: pointer;
  display: flex;
  height: 30px;
  justify-content: center;
  position: fixed;
  right: 22px;
  width: 30px;
  z-index: 11;
}
.tour-dialog {
  border-radius: 20px;
  display: none;
  position: fixed;
  width: 269px;
  height: fit-content;
  z-index: 6;
  transition: all ease 0.3s;
  box-shadow: 1px -1px 4px 0 rgba(0, 0, 0, 0.15),
    -1px 1px 4px 2px rgba(0, 0, 0, 0.15);
  &.top-arrow {
    margin-top: 30px;
    &:after {
      content: ' ';
      position: absolute;
      right: 16px;
      top: 4px;
      border-top: 5px solid transparent;
      border-right: 15px solid white;
      border-left: none;
      border-bottom: 15px solid transparent;
      box-sizing: border-box;
      border-color: transparent transparent #ffffff #ffffff;
      transform-origin: 0 0;
      transform: rotate(135deg);
      box-shadow: -2px 4px 4px 0 rgba(0, 0, 0, 0.2);
    }
  }
  &.left-arrow {
    &:after {
      content: ' ';
      position: absolute;
      left: 4px;
      top: 15px;
      border-top: 5px solid transparent;
      border-right: 15px solid white;
      border-left: none;
      border-bottom: 15px solid transparent;
      box-sizing: border-box;
      border-color: transparent transparent #ffffff #ffffff;
      transform-origin: 0 0;
      transform: rotate(45deg);
      box-shadow: -2px 4px 4px 0 rgba(0, 0, 0, 0.2);
    }
  }
  &.right-arrow {
    &:after {
      content: ' ';
      position: absolute;
      right: -11px;
      bottom: 3px;
      border-top: 5px solid transparent;
      border-right: 15px solid white;
      border-left: none;
      border-bottom: 15px solid transparent;
      box-sizing: border-box;
      border-color: transparent transparent #ffffff #ffffff;
      transform-origin: 0 0;
      transform: rotate(-135deg);
      box-shadow: -2px 4px 4px 0 rgba(0, 0, 0, 0.2);
    }
  }
}
</style>
