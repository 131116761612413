<template>
  <div class="template-designer-wrap">
    <div
      class="final-transition-container"
      v-if="showFinalTransition"
      :class="showSuccessTransition ? 'success-slide-animate' : ''"
    >
      <p
        class="loading-text"
        :class="showSuccessTransition ? 'smooth-text' : ''"
      >
        {{
          showSuccessTransition
            ? $t('templateDesign.successCustomization')
            : $t('templateDesign.loadingCustomization')
        }}
      </p>

      <div class="video-container">
        <video
          autoplay
          muted
          playsinline
          :loop="loopTransition"
          ref="video"
          @ended="onEnd()"
        >
          <source
            :src="'/assets/img/templateDesign/slide-transition.mp4'"
            type="video/mp4"
          />
        </video>
      </div>
    </div>

    <div v-else class="template-design-container" id="add-slide-transition">
      <div class="progress-container" v-if="stepper <= 3 && !showTransition">
        <div class="title-container">
          <div
            class="back-button"
            @click="goBack()"
            v-html="$t('templateDesign.backBtn')"
          ></div>
          <div class="progress-title">
            {{ $t('templateDesign.progressTitle') }}
          </div>
        </div>
        <div class="progress-wrapper">
          <v-progress-linear :value="(stepper + 1) * 20" :height="8" rounded />
        </div>
      </div>
      <UploadTemplate
        v-if="stepper === 0"
        :fileData="uploadedFileData"
        @handleNext="handleUploadNext"
        @setFileData="setFileData"
        @backToStd="backToStd"
        @handleTransition="handleTransition"
      ></UploadTemplate>
      <template v-else>
        <div v-for="(item, i) in filterMap" :key="i">
          <TemplateAttributeSelector
            v-if="i === stepper - 1"
            :attributeData="item"
            @handleSelected="onSelect"
          ></TemplateAttributeSelector>
        </div>
      </template>
    </div>
    <div class="confetti-container" v-if="showConfetti">
      <img
        class="confetti-img slide-down"
        :src="'/assets/img/templateDesign/confetti.png'"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import TemplateAttributeSelector from './TemplateAttributeSelector.vue';
import UploadTemplate from './UploadTemplate.vue';
import { TemplateDesignerService } from './TemplateDesignerService';

export default {
  name: 'TemplateDesignContainer',
  data() {
    return {
      error: '',
      uploadedFileData: {},
      showFinalTransition: false,
      loopTransition: true,
      showSuccessTransition: false,
      showConfetti: false,
      filterPayload: {},
      previewDeck: null,
      showTransition: false,
      stepper: 0,
      filterMap: [
        {
          filter: 'color_scheme_filter',
          text: this.$t('templateDesign.templateAttributes.colorSchemes.title'),
          subText: this.$t(
            'templateDesign.templateAttributes.colorSchemes.subText',
          ),
          img: {
            first_option: '',
            second_option: '',
          },
          selected: null,
        },
        {
          filter: 'font_color_filter',
          text: this.$t('templateDesign.templateAttributes.fontColors.title'),
          subText: this.$t(
            'templateDesign.templateAttributes.fontColors.subText',
          ),
          img: {
            first_option: '',
            second_option: '',
          },
          selected: null,
        },
        {
          filter: 'brand_images_filter',
          text: this.$t('templateDesign.templateAttributes.brandImages.title'),
          subText: this.$t(
            'templateDesign.templateAttributes.brandImages.subText',
          ),
          img: {
            first_option: '/assets/img/templateDesign/healthcare-collage.png',
            second_option:
              '/assets/img/templateDesign/non-healthcare-collage.png',
          },
          selected: null,
        },
      ],
    };
  },
  mounted() {},
  methods: {
    backToStd(error) {
      if (error) {
        this.$emit('backToStd', 'standard');
      }
    },
    handleTransition(showTransition) {
      this.showTransition = showTransition;
    },
    handleUploadNext(data) {
      if (Object.keys(data).length) {
        this.filterMap.map((item) => {
          if (data?.[item.filter]) {
            item.img = {
              first_option: data?.[item.filter]?.first_option,
              second_option: data?.[item.filter]?.second_option,
            };
          }
          return item;
        });
      }
      this.goNext();
    },
    onSelect(selected) {
      this.toogleAnimation('add');
      if (this.stepper < 4) {
        this.filterMap[this.stepper - 1].selected = selected;
      }
      setTimeout(() => {
        this.goNext();
        this.toogleAnimation('remove');
        if (this.stepper > 3) {
          this.onTemplateComplete();
        }
      }, 1400);
    },
    goNext() {
      this.stepper += 1;
    },
    onEnd() {
      if (!this.loopTransition) {
        this.showSuccessTransition = true;
        this.showConfetti = true;
        setTimeout(() => {
          // To change animation time, modify this settimeout, slide-down & slide-out
          this.showConfetti = false;
          this.showFinalTransition = false;
          this.showSuccessTransition = false;
          if (this.error === 'JOB_ERR') {
            this.$emit('handleSubmitErr', this.error);
          } else {
            this.$emit('onTemplateComplete', this.previewDeck);
          }
        }, 6000);
      }
    },
    goBack() {
      if (this.stepper === 0) {
        this.fileData = {};
        this.$emit('onBack', 'TMP_BK');
      } else {
        this.toogleAnimation('add');
        setTimeout(() => {
          this.stepper -= 1;
          this.toogleAnimation('remove');
        }, 1000);
      }
    },
    toogleAnimation(type) {
      const td = document.getElementById('add-slide-transition');
      if (td && type === 'add') {
        td.classList.add('slide-animate');
      } else if (td && type === 'remove') {
        td.classList.remove('slide-animate');
      }
    },
    setFileData(fileData) {
      this.uploadedFileData = fileData;
      if (!Object.keys(fileData).length) {
        this.filterMap.map((item) => {
          item.selected = null;
          item.img = {
            first_option: '',
            second_option: '',
          };
          return item;
        });
      }
    },
    async onTemplateComplete() {
      this.showFinalTransition = true;
      this.templateDesignerService
        .submitTemplateConversion(this.getSubmitPayload())
        .then((resp) => {
          if (resp && resp.status === 'success') {
            // if (
            //   this.currentUser.user.showTemplateDesignerFlow === 0 &&
            //   this.currentUser.company.isNonBranded
            // ) {
            //   this.loopTransition = false;
            //   this.$refs.video.currentTime = this.$refs.video.duration;
            // } else {
            this.templateDesignerService
              .fetchPreviewDeck(this.uploadedFileData?.uploadId, (interval) => {
                this.templateDesignerService.intervals.push(interval);
              })
              .then((response) => {
                if (response.status === 'success') {
                  this.previewDeck = response?.data;
                  this.loopTransition = false;
                  this.$refs.video.currentTime = this.$refs.video.duration;
                }
              })
              .catch(() => {
                this.error = 'JOB_ERR';
                this.loopTransition = false;
                this.$refs.video.currentTime = this.$refs.video.duration;
              });
            // }
          }
        })
        .catch(() => {
          this.error = 'JOB_ERR';
          this.loopTransition = false;
          this.$refs.video.currentTime = this.$refs.video.duration;
        });
    },
    getSubmitPayload() {
      const payload = { upload_id: this.uploadedFileData?.uploadId };
      this.filterMap.forEach((item) => {
        if (item.filter === 'brand_images_filter') {
          payload[item.filter] =
            item.selected === 'first_option' ? 'Healthcare' : 'Non-healthcare';
        } else {
          payload[item.filter] = item.selected;
        }
      });
      return payload;
    },
  },
  beforeDestroy() {
    this.templateDesignerService.cancel();
  },
  computed: {
    ...mapState('users', ['currentUser']),
    templateDesignerService() {
      return new TemplateDesignerService();
    },
  },
  components: { UploadTemplate, TemplateAttributeSelector },
};
</script>

<style lang="scss" scoped>
@keyframes slide-out {
  0% {
    transform: translateX(0%);
    opacity: 1;
  }
  75% {
    opacity: 1;
  }
  100% {
    transform: translateX(-100%);
    opacity: 0;
  }
}
@keyframes slide-down {
  0% {
    transform: translateY(-100%);
    opacity: 1;
  }
  50% {
    transform: translateY(70%);
  }
  75% {
    opacity: 1;
    transform: translateY(90%);
  }
  100% {
    transform: translateY(100%);
    opacity: 0;
  }
}
.template-design-container {
  display: flex;
  flex-direction: column;

  &.slide-animate {
    animation: 1s ease-out slide-out;
    transition: all 0.3s ease-out;
    animation-delay: 500ms;
  }
  .progress-container {
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    margin-bottom: 10px;
    .title-container {
      display: flex;
      align-items: center;
      margin-bottom: 15px;
      position: relative;
      .back-button {
        font-family: 'Lato';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 29px;
        position: absolute;
        right: calc(100% + 200px);
        width: 54px;
        cursor: pointer;
        color: #29b6f6;
        display: flex;
        align-items: center;
      }
      .progress-title {
        color: #000000;
        font-family: 'Lato';
        line-height: 29px;
        font-size: 20px;
        font-style: normal;
        font-weight: 700;
        text-align: center;
      }
    }
    .progress-wrapper {
      width: 15%;
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-bottom: 20px;
    }
  }
}

.slide-down {
  animation: 6.2s ease-out slide-down;
  //animation-delay: 200ms;
}
.confetti-container {
  position: absolute;
  overflow: hidden;
  height: 100vh;
  width: 100%;
  z-index: 2;
  top: 0;
  left: 0;
  pointer-events: none;
  .confetti-img {
    position: absolute;
    width: 100%;
    top: 0;
    left: 0;
  }
}

.final-transition-container {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  gap: 36px;
  width: 100%;

  .loading-text {
    font-family: Lato;
    font-size: 45px;
    font-weight: 400;
    line-height: 52px;
    letter-spacing: 0em;
    text-align: center;
    margin-top: 32px;
  }

  .video-container {
    display: flex;
    align-items: center;
    justify-content: center;
    video {
      height: 100%;
      aspect-ratio: 16/9;
      width: 750px;
    }
  }
}
.hide-success-transition {
  visibility: hidden;
}
.success-slide-animate {
  animation: 1s ease-out slide-out;
  transition: all 0.3s ease-out;
  animation-delay: 5500ms;
}
@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

.smooth-text {
  animation: fadeInAnimation ease-in 1s;
}
</style>
