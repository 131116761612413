import { Storage } from 'aws-amplify';
import {
  uploadOPFileBatchChunk,
  uploadOPFile,
  applyCompliance,
  deckFullCompliance,
  getSuggestionsTotalCount,
} from '../../utils/api-helper';
import { getLoggedInUserToken } from '../../utils/api';
import { v4 as uuidv4 } from 'uuid';
import EventBus from '../../components/common/event-bus';
import { TypeSenseServer } from '../../pages/components/TypeSenseInit';
import { getFileURL } from '../../utils/calyrex';
import {
  checkCallbackCompletion,
  fetchSlidesData,
  fetchSlidesInfo,
  fetchSplitPPTx,
  fetchSuggestionDataFromS3,
  fetchTCTemplates,
  getMatchingSuggestionMapping,
  getSlideImagesTC,
  initialComplianceCheck,
  reviewDetails,
  uploadAsyncDeck,
  getTemplatesListTC,
  retryLimitExceededTC,
} from '../../components/Main/ComplianceChecker/utils/comply-helper.utils';

const categoriesData = [
  { text: 'Colors', key: 'color', avatarColor: '#075689' },
  { text: 'Font', key: 'font', avatarColor: '#AF4FB3' },
  { text: 'Images', key: 'images', avatarColor: '#5C00D8' },
  // { text: 'Icons', key: 'icons', avatarColor: '#2AA08A' },
  { text: 'Template', key: 'template', avatarColor: '#EB7224' },
];

// function getDeckComplianceScore(slideInfo) {
//   const slideImages = slideInfo.slide_images;

//   // if (!slideImages || Object.keys(slideImages).length === 0) {
//   //   return 0;
//   // }

//   // let totalComplianceScore = 0;

//   // // Object.values(slideImages).forEach((slide) => {
//   // //   totalComplianceScore += getSlideComplianceScore(slide);
//   // // });

//   // return totalComplianceScore / Object.keys(slideImages).length;
//   return 100;
// }

const getDefaultState = () => {
  return {
    loading: false,
    loaderType: null,
    hasBrandImages: true,
    slideIndex: 0,
    sharedHeight: 'auto',
    rightPanelComponent: null,
    isSmartToolsOpen: false,
    templatesInfo: null,
    selectedSlides: [],
    selectedTemplate: '',
    fileDetails: null,
    deckComplianceScore: 0,
    placement: null,
    totalSuggestions: 0,
    // Loaders
    isTemplateUrlLoading: false,
    blurElements: false,
    //Core
    slidesInfo: {
      slide_images: [],
    },
    compliancesInfo: [],
    selectedSuggestion: null,
    processReviewDetailsFailed: false,
    isTemplateConverted: false,
    replaceImageSuggestionState: {
      suggestionNumber: 0,
      current: '',
      suggested: '',
      panelOpen: false,
      color: '',
      suggestedOnLoad: '',
      category: null,
      suggestion: null,
      itemIndex: 0,
      imageFromLib: null,
    },
    colorPickerState: {
      suggestionNumber: 0,
      current: '',
      suggested: '',
      panelOpen: false,
      color: '',
      suggestedOnLoad: '',
      category: null,
      suggestion: null,
      itemIndex: 0,
    },
    deckFullComplianceInfo: false,
    isFileUploadLoading: false,
    selectedTemplateThumbnailUrl: '',
    currentTemplateThumbnailUrl: '',
    isColorPickerVisible: false,
    selectedMapping: null,
    selectedColor: null,
    hasBrandImagesComply: false,
    slidesListOnFirstStep: null,
    intervals: [],
    includeImageConversion: null,
    abortController: new AbortController(),
    activeSuggestionView: null,
    isDeckFullCompliantFlow: false, 
    errorCode: '',
  };
};

const getSuggestionCountDeckLevel = (slidesInfo, category) => {
  switch (category) {
    case 'color':
      return slidesInfo.color_suggestions_count
    case 'font':
      return slidesInfo.font_suggestions_count
    case 'images':
      return slidesInfo.image_suggestions_count
    default:
      return 0;
  }
}
const getCategoryDescriptionDeckLevel = (slidesInfo, category) => {
  switch (category) {
    case 'color':
      return slidesInfo.color_suggestions_count > 0 ? `Use template colors in ${slidesInfo.color_suggestions_count} elements across your prezentation` : `Your presentation follows all brand guidelines on colors`
    case 'font':
      return slidesInfo.font_suggestions_count > 0
        ? ` Apply template font styles to ${slidesInfo.font_suggestions_count} elements across your prezentation`
        : `Your presentation follows all brand guidelines on font styles`
    case 'images':
      return slidesInfo.image_suggestions_count > 0
        ? `Utilize brand images for ${slidesInfo.image_suggestions_count} slides in your prezentation`
        : `Your presentation follows all brand guidelines on brand images`
    case 'template':
      return 'Your prezentation follows all brand guidelines on master template'
    default:
      return '';
  }
}


const getters = {
  slideComplianceScore: (state) => {
    const originalScore =
      state.slidesInfo?.slide_images[state.slideIndex]?.suggestions?.data
        ?.slide_data?.format_properties?.format_metrics?.compliance_score;
    return originalScore;
  },
  getSignal: (state) => state.abortController.signal,
  deckComplianceScore: (state) => state.deckComplianceScore,
  totalSuggestions: (state) => state.totalSuggestions,

  slideId: (state) => state.slideIndex || 0,

  selectedSlide: (state) => state.slidesInfo?.slide_images[state.slideIndex],

  sharedHeight: (state) => state.sharedHeight,

  isActionLoading: (state) => state.loading,

  complySlidesDetails: (state) => {
    if (state.slidesInfo.slide_images) {
      if (typeof state.slidesInfo.slide_images === 'object') {
        return Object.keys(state.slidesInfo.slide_images)
          .sort((a, b) => a - b)
          .map((key) => state.slidesInfo.slide_images[key]);
      } else {
        return state.slidesInfo.slide_images || [];
      }
    } else {
      return [];
    }
  },

  getComplianceScore: (state) => {
    return state.slidesInfo.compliance_score || null;
  },

  getSuggestionCount: (state) => {
    return state.slidesInfo.intial_suggestion_count || null;
  },

  getSlidesInfo: (state) => {
    return state.slidesInfo || null;
  },

  totalSlides: (state) => {
    return Object.keys(state.slidesInfo.slide_images || {}).length;
  },

  getFileS3Details: (state) => {
    return {
      s3: state.fileDetails.s3Bucket,
      filepath: state.fileDetails.s3Prefix,
      filename: state.fileDetails.name,
      sizeKb: state.fileDetails.sizeKb,
      noOfSlidesDeck: state.fileDetails.numOfPages,
    };
  },
  getFileDetails: (state) => state.fileDetails,
  getSelectedSlideImages: (state) => {
    let originalFormatList = {};
    let selectedSlidesList = state.selectedSlides.sort((a, b) => {
      return a.key - b.key;
    });
    selectedSlidesList.forEach((slide, index) => {
      let { key, ...others } = slide;
      let formatSlide = {
        [`${index}`]: {
          png: {
            s3_path: others.thumbnail_jpg.s3_path,
            s3_bucket: state.fileDetails.s3Bucket,
          },
          pptx: {
            s3_path: others.pptx.s3_path,
            s3_bucket: state.fileDetails.s3Bucket,
          },
        },
      };
      originalFormatList = { ...originalFormatList, ...formatSlide };
    });
    return originalFormatList;
  },
  getSelectedReferenceTemplateName: (state) => state.selectedTemplate,
  getTemplatesData: (state) => state.templatesInfo,
  getUserSelectedSlidesList: (state) => state.selectedSlides,
  getCurrentSlideSuggestions: (state) => {
    if (!state.slidesInfo?.slide_images) {
      return [];
    }
    const slide = state.slidesInfo.slide_images[state.slideIndex];
    if (slide && slide.suggestions?.data) {
      const slideLevelCategorySuggestions = categoriesData.map((c, cindex) => {
        let resolvedSuggestionCount = 0;
        const category =
          slide.suggestions.data.slide_data.format_properties
            .format_suggestions[c.key] || [];
        const slideProps =
          slide.suggestions.data.slide_data.slide_properties.size_props;
        const slideObj = {
          text: c.text,
          key: c.key,
          avatarColor: c.avatarColor,
          type: 'format',
          totalSuggestions: getSuggestionsTotalCount(category) || 0,
          suggestions: [],
          resolvedSuggestions: 0,
          index: cindex,
        };
        if (category) {
          if (Array.isArray(category) && category.length !== 0) {
            slideObj['suggestions'] = category
              .flatMap((categoryItem, csindex) => {
                if (categoryItem.mapping) {
                  return categoryItem.mapping.map((suggestion, mindex) => {
                    if (
                      suggestion.status !== undefined &&
                      ['accepted', 'rejected'].indexOf(
                        suggestion.status.toLowerCase(),
                      ) > -1
                    ) {
                      resolvedSuggestionCount += 1;
                    }
                    return {
                      ...suggestion,
                      ...categoryItem,
                      avatarColor: c.avatarColor,
                      x:
                        suggestion.extents &&
                          (suggestion.extents.left ||
                            suggestion.extents.left === 0) &&
                          slideProps.width
                          ? (
                            (suggestion.extents.left / slideProps.width) *
                            100
                          ).toFixed(2)
                          : null,
                      y:
                        suggestion.extents &&
                          (suggestion.extents.top ||
                            suggestion.extents.top === 0) &&
                          slideProps.height
                          ? (
                            (suggestion.extents.top / slideProps.height) *
                            100
                          ).toFixed(2)
                          : null,
                      status: suggestion.status
                        ? suggestion.status.toLowerCase()
                        : null,
                      mappingIndex: mindex,
                      categoryIndex: cindex,
                      categorySubIndex: csindex,
                      category: c.key,
                    };
                  });
                } else {
                  return [];
                }
              })
              .map((item, index) => {
                return {
                  ...item,
                  index: index + 1,
                };
              });
            slideObj['resolvedSuggestions'] = resolvedSuggestionCount;
          } else if (
            (c.key === 'template' && category.current_template_info) ||
            category.selected_template_info
          ) {
            let currentTemplate = {};
            let selectedTemplate = {};

            try {
              currentTemplate =
                JSON.parse(category.current_template_info) || {};
              selectedTemplate =
                JSON.parse(category.selected_template_info) || {};
            } catch (error) {
              console.error('Error parsing template info', error);
              return slideObj;
            }
            slideObj['suggestions'] = [
              {
                name: 'Slide template',
                currentTemplate,
                selectedTemplate,
                avatarColor: c.avatarColor,
                category: c.key,
                mappingIndex: 0,
                categoryIndex: 3,
                categorySubIndex: 0,
                index: 1,
              },
            ];
          } else {
            slideObj['suggestions'] = [];
          }
        } else {
          slideObj['suggestions'] = [];
        }
        return slideObj;
      });
      const fontColorGroup = [];
      const iconColorGroup = [];
      slideLevelCategorySuggestions.filter((sugg) => sugg.key === 'color').forEach((suggestionInfo) => {
        suggestionInfo.suggestions.map((suggestion, index) => {
          suggestion['isGrouped'] = false;
          if (["Fill Color - Icon", "Outline Color - Icon"].indexOf(suggestion.name) > -1) {
            suggestion['isGrouped'] = true;
            iconColorGroup.push(suggestion);
          } else if (['Font Color - Shape', 'Font Color - Table', 'Font Color - Chart', 'Fill Color - Bulletpoints'].indexOf(suggestion.name) > -1) {
            suggestion['isGrouped'] = true;
            fontColorGroup.push(suggestion)
          }
        })
        suggestionInfo.suggestions = suggestionInfo.suggestions.filter((sug) => sug.isGrouped === false);
      })
      const colorCategory = slideLevelCategorySuggestions.find((sugg) => sugg.key === 'color');
      if (fontColorGroup.length) {
        const isAcccepted = fontColorGroup.filter((indSuggestion) => indSuggestion.status && indSuggestion.status.toLowerCase() === 'accepted').length === fontColorGroup.length ? 'accepted' : null;
        const isRejected = fontColorGroup.filter((indSuggestion) => indSuggestion.status && indSuggestion.status.toLowerCase() === 'rejected').length === fontColorGroup.length ? 'rejected' : null;
        let index = 0;
        let categorySubIndex = 0;
        if (colorCategory.suggestions.length) {
          index = colorCategory.suggestions[colorCategory.suggestions.length - 1].index;
          categorySubIndex = colorCategory.suggestions[colorCategory.suggestions.length - 1].categorySubIndex;
        }
        const fontColorGroupSuggestion = {
          name: 'Font Color',
          description: (isAcccepted || isRejected) ? `Font color has been automatically adjusted based on the background color.` :
            `Font color will be adjusted based on background color across your prezentation.`,
          isGroupSuggestion: true,
          suggestionsList: [...fontColorGroup],
          status: (isAcccepted || isRejected) || null,
          type: 'group',
          index: index > 0 ? index + 1 : 1,
          category: "color",
          categoryIndex: 0,
          categorySubIndex: categorySubIndex > 0 ? categorySubIndex + 1 : 0
        }
        colorCategory.suggestions.push(fontColorGroupSuggestion)
      }
      if (iconColorGroup.length) {
        const isAcccepted = iconColorGroup.filter((indSuggestion) => indSuggestion.status && indSuggestion.status.toLowerCase() === 'accepted').length === iconColorGroup.length ? 'accepted' : null;
        const isRejected = iconColorGroup.filter((indSuggestion) => indSuggestion.status && indSuggestion.status.toLowerCase() === 'rejected').length === iconColorGroup.length ? 'rejected' : null;
        let index = 0;
        let categorySubIndex = 0;
        if (colorCategory.suggestions.length) {
          index = colorCategory.suggestions[colorCategory.suggestions.length - 1].index;
          categorySubIndex = colorCategory.suggestions[colorCategory.suggestions.length - 1].categorySubIndex;
        }
        const iconColorGroupSuggestion = {
          name: 'Icon Color',
          description: (isAcccepted || isRejected) ? `Icon color has been automatically adjusted based on the background color.` :
            `Icon color will be adjusted based on background color across your prezentation.`,
          isGroupSuggestion: true,
          suggestionsList: [...iconColorGroup],
          status: (isAcccepted || isRejected) || null,
          type: 'group',
          index: index > 0 ? index + 2 : 1,
          category: "color",
          categoryIndex: 0,
          categorySubIndex: categorySubIndex > 0 ? categorySubIndex + 2 : 0
        }
        colorCategory.suggestions.push(iconColorGroupSuggestion)
      }
      return slideLevelCategorySuggestions;
    }
    return [];
  },
  getCurrentSlideCompleted: (state, getters) => {
    return getters.getCurrentSlideSuggestions
      .filter((category) => category.key !== 'template')
      .every((category) => {
        return category.suggestions.every((suggestion) => suggestion.status);
      });
  },
  isTemplateConvertedCompleted: (state) => state.isTemplateConverted,
  colorPickerState: (state) => state.colorPickerState,
  getReplaceImageSuggestionState: (state) => state.replaceImageSuggestionState,
  getDeckFullComplianceInfo: (state) => state.deckFullComplianceInfo,
  getDeckLevelCategorySuggestions: (state, getters) => {
    if (!state.slidesInfo?.format_suggestions) {
      return [];
    }
    const deckLevelCategorySuggestions = categoriesData.map((c, cindex) => {
      let resolvedSuggestionCount = 0;
      const category =
        state.slidesInfo?.format_suggestions[c.key] || [];
      // const slideProps =
      //   slide.suggestions.data.slide_data.slide_properties.size_props;
      const slideObj = {
        text: c.text,
        key: c.key,
        avatarColor: c.avatarColor,
        type: 'format',
        suggestionsCount: getSuggestionCountDeckLevel(state.slidesInfo, c.key) || 0,
        suggestions: [],
        description: getCategoryDescriptionDeckLevel(state.slidesInfo, c.key),
        totalSuggestions: getSuggestionsTotalCount(category) || 0,
        resolvedSuggestions: 0,
        index: cindex,
      };
      if (Object.keys(category).length > 0) {
        if (Array.isArray(category) && category.length !== 0) {
          slideObj['suggestions'] = category
            .flatMap((categoryItem, csindex) => {
              categoryItem['isGroupSuggestion'] = false;
              if (categoryItem.mapping) {
                return categoryItem.mapping.map((suggestion, mindex) => {
                  if (
                    suggestion.status !== undefined &&
                    ['accepted', 'rejected'].indexOf(
                      suggestion.status.toLowerCase(),
                    ) > -1
                  ) {
                    resolvedSuggestionCount += 1;
                  }
                  return {
                    ...suggestion,
                    ...categoryItem,
                    avatarColor: c.avatarColor,
                    // x:
                    //   suggestion.extents &&
                    //   (suggestion.extents.left ||
                    //     suggestion.extents.left === 0) &&
                    //   slideProps.width
                    //     ? (
                    //         (suggestion.extents.left / slideProps.width) *
                    //         100
                    //       ).toFixed(2)
                    //     : null,
                    // y:
                    //   suggestion.extents &&
                    //   (suggestion.extents.top ||
                    //     suggestion.extents.top === 0) &&
                    //   slideProps.height
                    //     ? (
                    //         (suggestion.extents.top / slideProps.height) *
                    //         100
                    //       ).toFixed(2)
                    //     : null,
                    status: suggestion.status
                      ? suggestion.status.toLowerCase()
                      : null,
                    mappingIndex: mindex,
                    categoryIndex: cindex,
                    categorySubIndex: csindex,
                    category: c.key,
                  };
                });
              } else {
                return [];
              }
            })
            .map((item, index) => {
              return {
                ...item,
                index: index + 1,
              };
            });
          slideObj['resolvedSuggestions'] = resolvedSuggestionCount;
        } else if (
          (c.key === 'template' && category.current_template_info) ||
          category.selected_template_info
        ) {
          let currentTemplate = {};
          let selectedTemplate = {};

          try {
            currentTemplate =
              JSON.parse(category.current_template_info) || {};
            selectedTemplate =
              JSON.parse(category.selected_template_info) || {};
          } catch (error) {
            console.error('Error parsing template info', error);
            return slideObj;
          }
          slideObj['suggestions'] = [
            {
              name: 'Slide template',
              currentTemplate,
              selectedTemplate,
              avatarColor: c.avatarColor,
              category: c.key,
              mappingIndex: 0,
              categoryIndex: 3,
              categorySubIndex: 0,
              index: 1,
            },
          ];
        } else {
          slideObj['suggestions'] = [];
        }
      } else {
        slideObj['suggestions'] = [];
      }
      return slideObj;
    });
    const fontColorGroup = [];
    const iconColorGroup = [];
    console.log('deck level categories', deckLevelCategorySuggestions);
    deckLevelCategorySuggestions.filter((sugg) => sugg.key === 'color').forEach((suggestionInfo) => {
      const updatedSuggestions = [];
      suggestionInfo.suggestions.map((suggestion) => {
        suggestion['isGrouped'] = false;
        if (["Fill Color - Icon", "Outline Color - Icon"].indexOf(suggestion.name) > -1) {
          suggestion['isGrouped'] = true;
          iconColorGroup.push(suggestion);
        }
        if (['Font Color - Shape', 'Font Color - Table', 'Font Color - Chart', 'Fill Color - Bulletpoints'].indexOf(suggestion.name) > -1) {
          suggestion['isGrouped'] = true;
          fontColorGroup.push(suggestion)
        }
      })
      suggestionInfo.suggestions = suggestionInfo.suggestions.filter((sug) => sug.isGrouped === false);
    })
    const colorCategory = deckLevelCategorySuggestions.find((sugg) => sugg.key === 'color');
    if (fontColorGroup.length) {
      const acceptedSuggestionsList = fontColorGroup.filter((indSuggestion) => indSuggestion.status && indSuggestion.status.toLowerCase() === 'accepted');
      const rejectedSuggestionsList = fontColorGroup.filter((indSuggestion) => indSuggestion.status && indSuggestion.status.toLowerCase() === 'rejected');
      let index = 0;
      let categorySubIndex = 0;
      if (colorCategory.suggestions.length) {
        index = colorCategory.suggestions[colorCategory.suggestions.length - 1].index;
        categorySubIndex = colorCategory.suggestions[colorCategory.suggestions.length - 1].categorySubIndex;
      }
      const isAcccepted = acceptedSuggestionsList.length === fontColorGroup.length ? 'accepted' : null;
      const isRejected = rejectedSuggestionsList.length === fontColorGroup.length ? 'rejected' : null;
      const fontColorGroupSuggestion = {
        name: 'Font Color',
        description: (isAcccepted || isRejected) ? `Font color has been automatically adjusted based on the background color.` :
          `Font color will be adjusted based on background color across your prezentation.`,
        isGroupSuggestion: true,
        suggestionsList: [...fontColorGroup],
        status: (isAcccepted || isRejected) || null,
        type: 'group',
        index: index > 0 ? index + 1 : 0,
        category: "color",
        categoryIndex: 0,
        categorySubIndex: categorySubIndex > 0 ? categorySubIndex + 1 : 0
      }
      colorCategory.suggestions.push(fontColorGroupSuggestion)
    }
    if (iconColorGroup.length) {
      const acceptedSuggestionsList = iconColorGroup.filter((indSuggestion) => indSuggestion.status && indSuggestion.status.toLowerCase() === 'accepted');
      const rejectedSuggestionsList = iconColorGroup.filter((indSuggestion) => indSuggestion.status && indSuggestion.status.toLowerCase() === 'rejected');
      const isAcccepted = acceptedSuggestionsList.length === iconColorGroup.length ? 'accepted' : null;
      const isRejected = rejectedSuggestionsList.length === iconColorGroup.length ? 'rejected' : null;
      let index = 0;
      let categorySubIndex = 0;
      if (colorCategory.suggestions.length) {
        index = colorCategory.suggestions[colorCategory.suggestions.length - 1].index;
        categorySubIndex = colorCategory.suggestions[colorCategory.suggestions.length - 1].categorySubIndex;
      }
      const totalSuggestionsToResolve = iconColorGroup.length - (acceptedSuggestionsList.length + rejectedSuggestionsList.length)
      const iconColorGroupSuggestion = {
        name: 'Icon Color',
        description: (isAcccepted || isRejected) ? `Icon color has been automatically adjusted based on the background color.` :
          `Icon color will be adjusted based on background color across your prezentation.`,
        isGroupSuggestion: true,
        suggestionsList: [...iconColorGroup],
        status: (isAcccepted || isRejected) || null,
        type: 'group',
        index: index > 0 ? index + 2 : 0,
        category: "color",
        categoryIndex: 0,
        categorySubIndex: categorySubIndex > 0 ? categorySubIndex + 2 : 0
      }
      colorCategory.suggestions.push(iconColorGroupSuggestion)
    }
    return deckLevelCategorySuggestions;
  },
  getIncludeImageConversion: (state) => state.includeImageConversion,
  getCurrentSuggestionView: (state) => state.activeSuggestionView,
};
const mutations = {
  // Loaders
  SET_LOADING(state, {type, val}) {
    console.log('setting loader', type, val);
      state.loaderType = type;
      state.loading = val;
  },
  //end loaders
  SET_SLIDE_ID(state, slideIndex) {
    if (state.activeSuggestionView !== 'deck') state.selectedSuggestion = null;
    state.slideIndex = slideIndex;
  },

  RESET_STATE(state) {
    Object.assign(state, {
      slidesInfo: {
        slide_images: [],
      },
    });
  },

  SET_PLACEMENT(state, position) {
    state.placement = position;
  },

  RESET(state) {
    Object.assign(state, getDefaultState());
  },

  SET_SLIDES_INFO(state, slidesData) {
    state.slidesInfo = { ...state.slidesInfo, ...slidesData };
  },

  // SET_DECK_SCORE(state, slideData){
  //   console.log(slideData, state.slidesInfo);
  //   state.slidesInfo = {}
  // },
  SET_SELECTED_SUGGESTION(state, suggestion) {
    state.selectedSuggestion = suggestion;
  },
  SET_HAS_BRAND_IMAGES(state, value) {
    state.hasBrandImages = value;
  },
  // SET_SLIDE_COMPLIANCE_SCORE(state, slideComplianceScore, slideIndex) {
  //   const slideInfo = { ...state.slidesInfo };
  //   slideInfo.slide_images[slideIndex].complianceScore = slideComplianceScore;
  //   console.log('slides with compliance score', state.slidesInfo);
  // },
  SET_FILE(state, file) {
    state.fileDetails = file;
  },
  RESET_FILE(state) {
    state.fileDetails = null;
  },
  SET_RIGHT_PANEL_COMPONENT(state, component) {
    setTimeout(() => {
      EventBus.$emit('MINIMIZE_LEFTNAV');
    }, 50);
    if (component === state.rightPanelComponent) {
      state.rightPanelComponent = null;
    } else {
      state.rightPanelComponent = component;
      state.isSmartToolsOpen = true;
    }
  },
  openSlideSuggestions(state) {
    state.rightPanelComponent = true;
  },
  openSmartTools(state, value) {
    state.isSmartToolsOpen = value;
    if (!value) {
      state.rightPanelComponent = null;
    }
  },
  SET_SELECTED_SLIDES(state, slidesList) {
    state.selectedSlides = slidesList;
  },
  SET_SELECTED_TEMPLATE(state, templateName) {
    state.selectedTemplate = templateName;
  },
  RESET_SELECTED_TEMPLATE(state) {
    state.selectedTemplate = '';
  },

  SET_SLIDE_COMPLIANCE_SCORE(state, { slideIndex, score }) {
    if (state.slidesInfo?.slide_images[slideIndex]) {
      state.slidesInfo.slide_images[slideIndex].complianceScore = score;
    }

    // if (slide) {
    //   slide.suggestions.data.slide_data.format_properties.format_metrics.compliance_score =
    //     score;
    // }
  },
  //   SET_SLIDE_COMPLIANCE_SCORE(state, slideComplianceScore, slideIndex) {
  //   const slideInfo = { ...state.slidesInfo };
  //   slideInfo.slide_images[slideIndex].complianceScore = slideComplianceScore;
  //   console.log('slides with compliance score', state.slidesInfo);
  // },

  // SET_DECK_COMPLIANCE_SCORE(state, score) {
  //   state.deckComplianceScore = score;
  // },

  RESET_COMPLIANCE_DATA(state) {
    state.slidesInfo = {};
    state.deckFullComplianceInfo = false;
    // state.selectedSlides = [];
    // state.selectedTemplate = '';
  },

  SET_TEMPLATES_LIST(state, templates) {
    state.templatesInfo = templates;
  },

  SET_IS_TEMPLATE_CONVERTED(state, val) {
    state.isTemplateConverted = val;
  },

  SET_REVIEW_FAILED_FLAG(state, val) {
    state.processReviewDetailsFailed = val;
  },
  SET_REPLACE_IMAGE_SUGGESTION_STATE(state, val) {
    const existingState = { ...state.replaceImageSuggestionState };
    state.replaceImageSuggestionState = { ...existingState, ...val };
  },
  SET_COLOR_PICKER_STATE(state, val) {
    const existingState = { ...state.colorPickerState };
    state.colorPickerState = { ...existingState, ...val };
  },
  UPDATE_DECK_LEVEL_FULL_COMPLIANCE(state, info) {
    state.deckFullComplianceInfo = info;
    state.rightPanelComponent = 'complySlideDetails';
  },

  SET_FILE_UPLOAD_LOADING(state, status) {
    state.isFileUploadLoading = status;
  },

  SET_SELECTED_TEMPLATE_THUMBNAIL_URL(state, url) {
    state.selectedTemplateThumbnailUrl = url;
  },

  SET_CURRENT_TEMPLATE_THUMBNAIL_URL(state, url) {
    state.currentTemplateThumbnailUrl = url;
  },
  SET_TEMPLATE_URL_LOADING(state, isTemplateUrlLoading) {
    state.isTemplateUrlLoading = isTemplateUrlLoading;
  },

  setToggleColorPicker(state, isVisible) {
    state.isColorPickerVisible = isVisible;
  },
  setSelectedMapping(state, payload) {
    state.selectedMapping = payload.mapping;
  },
  SET_SELECTED_COLOR(state, color) {
    state.selectedColor = color;
  },
  SET_HAS_BRAND_IMAGES_COMPLY(state, info) {
    state.hasBrandImagesComply = info;
  },
  SET_NEW_UPLOAD_ID(state, uploadId) {
    if (state.fileDetails) {
      state.fileDetails.uploadId = uploadId;
    }
  },
  SET_SLIDES_LIST_ON_FIRST_STEP(state, slidesList) {
    state.slidesListOnFirstStep = slidesList;
  },
  SET_ABORT_CONTROLLER(state, controller) {
    state.abortController = controller;
  },
  ABORT_SIGNAL(state) {
    state.abortController.abort();
  },
  ADD_SET_INTERVAL(state, payload) {
    state.intervals.push(payload);
  },
  RESET_SET_INTERVAL(state) {
    state.abortController.abort();
    state.intervals.forEach((interval) => clearInterval(interval));
    state.intervals = [];
  },
  SET_INCLUDE_IMAGE_CONVERSION(state, val) {
    state.includeImageConversion = val;
  },
  SET_ACTIVE_SUGGESTION_VIEW(state, val) {
    state.activeSuggestionView = val;
  },
  SET_IS_DECK_FULL_COMPLIANT_FLOW(state, val) {
    state.isDeckFullCompliantFlow = val;
  },
  SET_TC_ERROR_CODE(state, val) {
    state.errorCode = val;
  }
};
const actions = {
  selectMappingAction({ commit }, payload) {
    commit('setSelectedMapping', payload);
  },
  /**
   * Apply compliance changes to slides.
   *
   * This function takes in slide data, applies compliance checks & updates the state
   * with the modified slides. Both the compliance check and fetching the user token
   * are executed in parallel to improve performance.
   *
   */

  async applyComplianceChanges({ getters, commit, dispatch }, { slideData }) {
    dispatch('setAcceptedSlideChangeLoading', true);
    try {
      const response = await applyCompliance(slideData);
      const token = await getLoggedInUserToken();
      const slideId = getters.slideId;
      const slidesInfo = getters.getSlidesInfo || {};
      const updatedSlideData = response.data;
      const awsAssetsDistribution = process.env.VUE_APP_AWS_ASSETS_DISTRIBUTION;
      const uuid = uuidv4();

      const formURL = (path) =>
        `${awsAssetsDistribution}/${path}?accesskey=${token}&uuid=${uuid}`;
      updatedSlideData.png.thumbnail = formURL(updatedSlideData.png.s3_path);
      updatedSlideData.pptx.thumbnail = formURL(updatedSlideData.pptx.s3_path);
      updatedSlideData.suggestions.data = await fetchSuggestionDataFromS3(
        formURL(updatedSlideData.suggestions.filepath),
      );
      const acceptedRejectedCount = slidesInfo.slide_images[slideId]
        .acceptedRejectedCount
        ? slidesInfo.slide_images[slideId].acceptedRejectedCount + 1
        : 1;
      const newSlideInfo = { ...slidesInfo };
      newSlideInfo.slide_images[slideId] = { ...updatedSlideData };
      newSlideInfo.slide_images[slideId].acceptedRejectedCount =
        acceptedRejectedCount;

      commit('SET_SLIDES_INFO', newSlideInfo);

      // commit('SET_SLIDE_COMPLIANCE_SCORE', {
      //   slideIndex: slideId,
      //   score: getSlideComplianceScore(slidesInfo, slideId),
      // });

      // commit('SET_DECK_COMPLIANCE_SCORE', getDeckComplianceScore(slidesInfo));

      console.log('Compliance changes successfully applied:', response.data);
      return response.data;
    } catch (error) {
      console.error('Applying compliance changes failed:', error);
      throw error;
    } finally {
      dispatch('setAcceptedSlideChangeLoading', false);
    }
  },

  async fetchScore({ commit, getters, dispatch }) {
    dispatch('setAbortController');
    const slidesData = await fetchSlidesData(
      getters.getFileDetails.uploadId,
      getters.getSignal,
      true,
    );
    if (slidesData.compliance_score === 100)
      dispatch('updateDeckLevelFullCompliance', true);
    commit('SET_SLIDES_INFO', slidesData);
  },
  setAbortController({ commit }) {
    const controller = new AbortController();
    commit('SET_ABORT_CONTROLLER', controller);
  },
  abortSignal({ commit }) {
    commit('ABORT_SIGNAL');
  },

  setSelectedSuggestion({ commit }, suggestion) {
    commit('SET_SELECTED_SUGGESTION', suggestion);
  },

  setReplaceImageSuggestionState({ commit }, val) {
    commit('SET_REPLACE_IMAGE_SUGGESTION_STATE', val);
  },
  setColorPickerState({ commit }, val) {
    commit('SET_COLOR_PICKER_STATE', val);
  },

  resetState({ commit }) {
    commit('RESET');
  },

  setFileData({ commit }, fileDetails) {
    commit('SET_FILE', fileDetails);
  },
  updateSlideSuggestions(
    { getters, commit },
    { category, categorySubIndex, mappingIndex, suggestedToUpdate },
  ) {
    if (category === 'template') {
      return;
    }
    const slideId = getters.slideId;
    const slidesInfo = getters.getSlidesInfo || {};
    const newSlideInfo = JSON.parse(JSON.stringify(slidesInfo));

    let byCategory =
      newSlideInfo.slide_images[slideId].suggestions.data.slide_data
        .format_properties.format_suggestions[category];

    if (categorySubIndex != undefined && byCategory[categorySubIndex]) {
      let bySubCategory = byCategory[categorySubIndex];
      if (mappingIndex != undefined && bySubCategory?.mapping[mappingIndex]) {
        // bySubCategory.mapping[mappingIndex].status = isAccepted
        //   ? 'accepted'
        //   : 'rejected';
        
        /* this block of code is overriding the response from the API and assiging a temporary variable data. hence the real data is not been shown to the user on refresh
        if (category === 'images') {
           bySubCategory.mapping[mappingIndex].suggested = [suggestedToUpdate];
        } */
      }
    } else {
      // When categorySubIndex =null i.e. bulk update all by category then change all nested mapping status
      for (let subCategory of byCategory) {
        for (let mapping of subCategory.mapping) {
          // mapping.status = isAccepted ? 'accepted' : 'rejected';
        }
      }
    }

    commit('SET_SLIDES_INFO', newSlideInfo);
  },

  updateUserSelectedColor(
    { getters, commit },
    { suggestion, userSelectedColor },
  ) {
    const slidesInfo = getters.getSlidesInfo || {};
    const newSlideInfo = JSON.parse(JSON.stringify(slidesInfo));
    const slide = newSlideInfo.slide_images[getters.slideId];
    const matchingSuggestion = getMatchingSuggestionMapping(slide, suggestion);
    if (matchingSuggestion) {
      matchingSuggestion.userSelectedColor = userSelectedColor;
      commit('SET_SLIDES_INFO', newSlideInfo);
    }
  },

  acceptUserSelectedColor({ getters, commit }, { suggestion }) {
    const slidesInfo = getters.getSlidesInfo || {};
    const newSlideInfo = JSON.parse(JSON.stringify(slidesInfo));
    const slide = newSlideInfo.slide_images[getters.slideId];
    const matchingSuggestion = getMatchingSuggestionMapping(slide, suggestion);
    if (matchingSuggestion.userSelectedColor) {
      matchingSuggestion.suggested = matchingSuggestion.userSelectedColor;
      matchingSuggestion.userSelectedColor = null;
      commit('SET_SLIDES_INFO', newSlideInfo);
    }
  },

  acceptAllSelectedColorInCategory({ getters, commit }, { category }) {
    const slidesInfo = getters.getSlidesInfo || {};
    const newSlideInfo = JSON.parse(JSON.stringify(slidesInfo));
    const slide = newSlideInfo.slide_images[getters.slideId];
    let byCategory =
      slide.suggestions.data.slide_data.format_properties.format_suggestions[
      category
      ];
    for (const bySubCategory of byCategory) {
      for (const mapping of bySubCategory.mapping) {
        if (mapping.userSelectedColor) {
          mapping.suggested = mapping.userSelectedColor;
          mapping.userSelectedColor = null;
        }
      }
    }
    commit('SET_SLIDES_INFO', newSlideInfo);
  },

  updateDeckLevelFullCompliance({ commit }, info) {
    commit('UPDATE_DECK_LEVEL_FULL_COMPLIANCE', info);
  },

  async applyFullDeckComplianceChanges({ getters, commit, dispatch }, makeFullCompliant = '') {
    dispatch('setAcceptedFullDeckConvertionLoading', true);
    let deckData = {
      upload_id: getters.getFileDetails.uploadId,
      reference_template: getters.getSelectedReferenceTemplateName,
      category: ['color', 'font', 'images', 'template'],
      source:
        getters.getCurrentSlideSuggestions.length > 0
          ? 'template-conversion'
          : 'deck-compliance',
      include_image_conversions: getters.getIncludeImageConversion,
      applyBGtoFont: ['bg_to_font_color', 'bg_to_bullet_color', 'bg_to_svg_color']
    };
    if (makeFullCompliant) {
      deckData = { ...deckData, ...{ "isMake100Percent": makeFullCompliant } }
    }
    try {
      let prevSlideInfo = getters.getSlidesInfo;
      const response = await deckFullCompliance(deckData);
      // await dispatch(
      //   'fetchComplianceSlidesWithPoll',
      //   getters.getFileDetails.uploadId,
      //   false,
      // );
      dispatch('setAbortController');
      await checkCallbackCompletion(response.data.callbacks, getters.getSignal);
      const slidesData = await fetchSlidesData(
        getters.getFileDetails.uploadId,
        getters.getSignal,
      );
      // comparing with previous state to determine the user action to resolve all the suggested changes https://prezentium.atlassian.net/browse/PM-6729
      if (Object.keys(slidesData).length > 0) {
        slidesData['color_suggestions_count_resolved'] =
          prevSlideInfo.color_suggestions_count !== 0
            ? slidesData.color_suggestions_count === 0
            : false;
        slidesData['font_suggestions_count_resolved'] =
          prevSlideInfo.font_suggestions_count !== 0
            ? slidesData.font_suggestions_count === 0
            : false;
        slidesData['image_suggestions_count_resolved'] =
          prevSlideInfo.image_suggestions_count !== 0
            ? slidesData.image_suggestions_count === 0
            : false;
      }
      dispatch('updateDeckLevelFullCompliance', true);
      commit('SET_SLIDES_INFO', slidesData);
    } catch (error) {
      if(error.message === "Polling timed out"){
        dispatch('pollingLimitExceeded');
      }
      console.error('Error applying full deck compliance changes:', error);
      throw error;
    } finally {
      dispatch('setAcceptedFullDeckConvertionLoading', false);
    }
  },

  async applyFullDeckChangesOnAcceptOrRejectAction({ getters, commit, dispatch }, { deckData }) {
    console.log('payload input', deckData);
    dispatch('setAcceptedFullDeckConvertionLoading', true);
    try {
      let prevSlideInfo = getters.getSlidesInfo;
      const response = await deckFullCompliance(deckData);
      await checkCallbackCompletion(response.data.callbacks, getters.getSignal);
      const slidesData = await fetchSlidesData(
        getters.getFileDetails.uploadId,
        getters.getSignal,
      );
      // comparing with previous state to determine the user action to resolve all the suggested changes https://prezentium.atlassian.net/browse/PM-6729
      if (Object.keys(slidesData).length > 0) {
        slidesData['color_suggestions_count_resolved'] =
          prevSlideInfo.color_suggestions_count !== 0
            ? slidesData.color_suggestions_count === 0
            : false;
        slidesData['font_suggestions_count_resolved'] =
          prevSlideInfo.font_suggestions_count !== 0
            ? slidesData.font_suggestions_count === 0
            : false;
        slidesData['image_suggestions_count_resolved'] =
          prevSlideInfo.image_suggestions_count !== 0
            ? slidesData.image_suggestions_count === 0
            : false;
      }
      commit('SET_SLIDES_INFO', slidesData);
    } catch (error) {
      if(error.message === "Polling timed out"){
        dispatch('pollingLimitExceeded');
      }
      console.error('Error applying full deck compliance changes:', error);
      throw error;
    } finally {
      dispatch('setAcceptedFullDeckConvertionLoading', false);
    }
  },

  async applyCategoryDeckComplianceChanges(
    { getters, commit, dispatch },
    { categorySelected, action, applyBGtoFont, actionAfterReview='' },
  ) {
    dispatch('setAcceptedSlideChangeLoading', true);
    let deckData = {
      upload_id: getters.getFileDetails.uploadId,
      reference_template: getters.getSelectedReferenceTemplateName,
      category: [categorySelected],
      source:
        getters.getCurrentSlideSuggestions.length > 0
          ? 'template-conversion'
          : '',
      action,
      actionAfterReview,
    };
    if (action.toLowerCase() === 'accept' && applyBGtoFont.length) {
      deckData = {
        ...deckData,
        applyBGtoFont
      }
    }
    if (deckData.category[0].toLowerCase() === 'images') {
      deckData = {
        ...deckData,
        ...{ include_image_conversions: action.toLowerCase() === 'accept' },
      };
    }
    console.log('Deck data:', deckData);
    try {
      let prevSlideInfo = getters.getSlidesInfo;
      const response = await deckFullCompliance(deckData);
      console.log('response', response);
      dispatch('setAbortController');
      // await dispatch('fetchComplianceSlidesWithPoll', getters.getFileDetails.uploadId);
      await checkCallbackCompletion(response.data.callbacks, getters.getSignal);
      const slidesData = await fetchSlidesData(
        getters.getFileDetails.uploadId,
        getters.getSignal,
      );
      // comparing with previous state to determine the user action to resolve all the suggested changes https://prezentium.atlassian.net/browse/PM-6729
      if (slidesData) {
        switch (categorySelected) {
          case 'color':
            slidesData['color_suggestions_count_resolved'] =
              prevSlideInfo.color_suggestions_count !== 0
                ? slidesData.color_suggestions_count === 0
                : false;
            break;
          case 'font':
            slidesData['font_suggestions_count_resolved'] =
              prevSlideInfo.font_suggestions_count !== 0
                ? slidesData.font_suggestions_count === 0
                : false;
          case 'images':
            slidesData['image_suggestions_count_resolved'] =
              prevSlideInfo.image_suggestions_count !== 0
                ? slidesData.image_suggestions_count === 0
                : false;
          default:
            break;
        }
      }

      // const newSlideInfo = { ...prevSlideInfo };

      for (let i = 0; i < Object.keys(prevSlideInfo.slide_images).length; i++) {
        const category =
          slidesData.slide_images[i].suggestions.data.slide_data
            .format_properties.format_suggestions[categorySelected] || [];
        const totalCount =
          (prevSlideInfo.slide_images[i].acceptedRejectedCount || 0) +
          (getSuggestionsTotalCount(category) || 0);
        slidesData.slide_images[i].acceptedRejectedCount = totalCount;
      }

      // slidesData['slide_images'] = { ...newSlideInfo.slide_images };
      commit('SET_SLIDES_INFO', slidesData);
    } catch (error) {
      if(error.message === "Polling timed out"){
        dispatch('pollingLimitExceeded');
      }
      console.error('Error applying full deck compliance changes:', error);
      throw error;
    } finally {
      dispatch('setAcceptedSlideChangeLoading', false);
    }
  },

  selectSlide({ commit }, { slideData, index }) {
    commit('SET_SLIDE_ID', index);
  },
  setPlacement({ commit }, position) {
    commit('SET_PLACEMENT', position);
  },
  async processReviewDetails({ commit, dispatch, getters }) {
    dispatch('setReviewDetailsLoading', true);
    let payload = {
      slide_images: getters.complySlidesDetails,
      reference_template: getters.getSelectedReferenceTemplateName,
      upload_id: getters.getFileDetails.uploadId,
    };
    try {
      dispatch('setAbortController');
      commit('SET_TC_ERROR_CODE', '');
      const slidesData = await reviewDetails(payload, getters.getSignal);
      commit('SET_SLIDES_INFO', slidesData);
      commit('SET_SLIDE_COMPLIANCE_SCORE', {
        slideIndex: 0,
        score:
          slidesData[0]?.suggestions?.data?.slide_data?.format_properties
            ?.format_metrics?.compliance_score,
      });

    } catch (error) {
      if(error.message === "Polling timed out"){
        dispatch('pollingLimitExceeded');
      }
      dispatch('setReviewDetailsLoading', false);
      console.log('error while passing initial compliance', error);
      throw new Error('Failed to convert to the template.');
    }
    dispatch('setReviewDetailsLoading', false);
    commit('SET_RIGHT_PANEL_COMPONENT', 'complySlideDetails');
  },

  async pollingLimitExceeded({getters}){
    await retryLimitExceededTC(getters.getFileDetails.uploadId)
  },

  setReviewDetailsLoading({ commit }, isReviewDetailsLoading) {
    commit('SET_LOADING', {type: 'tcReviewDetails', val:isReviewDetailsLoading});
  },

  setAcceptedSlideChangeLoading({ commit }, isApplyingSingleChangeLoading) {
    commit('SET_LOADING', {type: 'tcAcceptSuggestions', val:isApplyingSingleChangeLoading});
  },

  setComplyCheckLoading({ commit }, val) {
    commit('SET_LOADING', {type: 'tcAllSteps', val:val});
  },

  setAcceptedFullDeckConvertionLoading(
    { commit },
    isApplyingFullDeckConvertionLoading,
  ) {
    commit('SET_LOADING', {type: 'tcMake100Compliant', val:isApplyingFullDeckConvertionLoading});
  },

  setSmartToolsOpen({ commit }, value) {
    commit('SET_SMART_TOOLS_OPEN', value);
  },

  setRightPanelComponent({ commit }, component) {
    commit('SET_RIGHT_PANEL_COMPONENT', component);
  },

  openSmartTools({ commit }, value) {
    commit('openSmartTools', value);
  },
  async fetchSlidesOnFirstStepWithPoll(
    { commit, getters, dispatch },
    uploadId,
  ) {
    commit('RESET_STATE');
    try {
      dispatch('setAbortController');
      const slideImages = await getSlideImagesTC(uploadId, getters.getSignal);
      commit('SET_SLIDES_INFO', slideImages);
      commit('SET_SLIDES_LIST_ON_FIRST_STEP', slideImages);
      return slideImages;
    } catch (err) {
      console.log(error);
      commit('RESET_STATE');
      throw err;
    }
  },

  async fetchRecomendedTemplatesOnFirstStepWithPoll(
    { commit, getters, dispatch },
    uploadId,
  ) {
    commit('RESET_STATE');
    try {
      dispatch('setAbortController');
      const templates = await getTemplatesListTC(uploadId, getters.getSignal);
      commit('SET_TEMPLATES_LIST', templates);
      return templates;
    } catch (err) {
      console.log(error);
      commit('RESET_STATE');
      throw err;
    }
  },

  async fetchSlidesAndVerifyWithPoll({ commit, dispatch, getters }, uploadId) {
    commit('RESET_STATE');
    try {
      dispatch('setAbortController');
      const response = await fetchSplitPPTx(
        uploadId,
        getters.getUserSelectedSlidesList,
        getters.getSignal,
      );
      dispatch('setPPTXObject', response);
      return true;
    } catch (err) {
      console.log(err);
      commit('RESET_STATE');
      throw err;
    }
  },
  async fetchComplianceSlidesWithPoll({ commit, getters, dispatch }, uploadId) {
    commit('RESET_STATE');
    try {
      dispatch('setAbortController');
      const slidesWithImages = await fetchSlidesInfo(
        uploadId,
        getters.getSignal,
      );
      commit('SET_SLIDES_INFO', slidesWithImages);
      return slidesWithImages;
    } catch (err) {
      commit('RESET_STATE');
      throw err;
    }
  },

  setPPTXObject({ commit, getters }, slidesListResp) {
    try {
      const userSelectedSlides = getters.getUserSelectedSlidesList;
      userSelectedSlides.forEach((slide, index) => {
        let updatedSlide = slidesListResp.find((s) => s.index === slide.index);
        if (!!updatedSlide) {
          if (updatedSlide.pptx && updatedSlide.pdf) {
            const { pdf, pptx } = updatedSlide;
            userSelectedSlides[index].pdf = pdf;
            userSelectedSlides[index].pptx = pptx;
          }
        }
      });
      commit('SET_SELECTED_SLIDES', userSelectedSlides);
    } catch (error) {
      console.log(error);
      throw error;
    }
  },

  updateSelectedSlidesList({ commit }, slidesList) {
    commit('SET_SELECTED_SLIDES', slidesList);
  },
  async fetchComplianceTemplatesWithPoll(
    { commit, dispatch, state },
    uploadId,
  ) {
    try {
      const templates = await fetchTCTemplates(
        uploadId,
        state.abortController.signal,
      );
      commit('SET_TEMPLATES_LIST', templates);
      return templates;
    } catch (err) {
      commit('RESET_STATE');
      console.log(err);
      throw err;
    }
  },
  updateSelectedTemplate({ commit }, templateName) {
    commit('SET_SELECTED_TEMPLATE', templateName);
  },
  resetSelectedTemplate({ commit }) {
    commit('RESET_SELECTED_TEMPLATE');
  },
  setHasBransImagesComply({ commit }, info) {
    commit('SET_HAS_BRAND_IMAGES_COMPLY', info);
  },

  async addInitialComplianceCheck(
    { getters, dispatch, rootGetters },
    source = '',
  ) {
    try {
      let payload = {
        slide_images: !source
          ? getters.getSelectedSlideImages
          : getters.complySlidesDetails,
        reference_template: getters.getSelectedReferenceTemplateName,
        upload_id: getters.getFileDetails.uploadId,
        source,
        include_image_conversions:
          rootGetters['users/isReplaceBrandImageEnabled'],
      };
      dispatch('setAbortController');
      const response = await initialComplianceCheck(payload, getters.getSignal);
      // const hasBrndImgs = await getBrandImagesInfo();
      // dispatch('setHasBransImagesComply', hasBrndImgs);
      console.log(response);
      return response;
    } catch (error) {
      if(error.message === "Polling timed out"){
        dispatch('pollingLimitExceeded');
      }
      console.log('error while passing initial compliance', error);
      throw error;
    }
  },

  async getFileUploadId({ commit, getters }) {
    try {
      const payload = getters.getFileS3Details;
      payload.uploadId = getters.getFileDetails.requestIdentifier;
      payload.origin = 'template-converter';
      const resp = await uploadAsyncDeck(payload);
      const fileDetails = getters.getFileDetails;
      fileDetails.uploadId = resp.data.upload_id;
      console.log(fileDetails);
      commit('SET_FILE', fileDetails);
      return resp;
    } catch (error) {
      console.log(error);
      throw error;
    }
  },
  async resetFileData({ commit }) {
    commit('RESET_FILE');
  },
  async resetComplianceData({ commit }) {
    commit('RESET_COMPLIANCE_DATA');
  },
  async uploadComplyFile({ commit, dispatch }, file) {
    commit('RESET_FILE');
    try {
      commit('SET_FILE_UPLOAD_LOADING', true);
      const preprocessUploadResponse = await dispatch('uploadChunks', file);
      if (preprocessUploadResponse) {
        try {
          const postProcessUploadResponse = await dispatch(
            'uploadFilePostProcess',
            file,
          );
          if (postProcessUploadResponse) {
            commit('SET_FILE_UPLOAD_LOADING', false);
            commit('SET_FILE', postProcessUploadResponse);
            return postProcessUploadResponse;
          }
        } catch (err) {
          console.log(err);
          commit('SET_FILE_UPLOAD_LOADING', false);
          return {
            error:
              'The selected file exceeds 30 slides. We recommend splitting your deck into shorter versions.',
            isSlidesThresholdExceeds: true,
            numOfPages: err.numOfPages,
          };
        }
      } else {
        commit('SET_FILE_UPLOAD_LOADING', false);
        return { error: 'File upload failed!' };
      }
    } catch (error) {
      commit('SET_FILE_UPLOAD_LOADING', false);
      console.log(error);
      return { error: 'File upload failed!' };
    }
  },
  uploadChunks({ commit }, file) {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = async () => {
        const chunkSize = 3 * 1024 ** 2;
        const chunks = [];
        let chunkIndex = 0;
        for (let start = 0; start < reader.result.length; start += chunkSize) {
          const chunk = reader.result.slice(start, start + chunkSize);
          chunks.push({
            chunk,
            chunkIndex,
            requestIdentifier: file.requestIdentifier,
            filename: file.name,
          });
          chunkIndex++;
        }
        const chunkBatch = chunks.reduce((resultArray, item, index) => {
          const chunkIdx = Math.floor(index / 5);

          if (!resultArray[chunkIdx]) {
            resultArray[chunkIdx] = []; // start a new chunk
          }

          resultArray[chunkIdx].push(item);

          return resultArray;
        }, []);
        try {
          await uploadOPFileBatchChunk(
            chunkBatch,
            chunkIndex,
            (prog) => {
              console.log(prog);
              file.progress = prog;
            },
            'comply',
          );
          resolve('success');
        } catch (err) {
          reject(err);
        }
      };
      reader.onerror = (error) => {
        reject(error);
      };
      reader.readAsDataURL(file.file);
    });
  },
  uploadFilePostProcess({ commit }, file) {
    return new Promise(async (resolve, reject) => {
      try {
        const postProcessUpload = await uploadOPFile(
          file.requestIdentifier,
          file.name,
          'comply',
        );
        if (postProcessUpload) {
          file = {
            ...file,
            ...postProcessUpload.data,
            processingCompleted: true,
          };
          return resolve(file);
        } else {
          return reject('error while uploading');
        }
      } catch (error) {
        file.processingCompleted = true;
        file.error = true;
        file.progress = 'failed';
        console.log(error.response.data);
        if (error.response.data.data)
          file.numOfPages = error.response.data.data.numOfPages;
        return reject({
          message: 'Error while uploading',
          numOfPages: file.numOfPages,
        });
      }
    });
  },
  setIsTemplateConverted({ commit }, val) {
    commit('SET_IS_TEMPLATE_CONVERTED', val);
  },

  fetchTemplateThumbnailUrls({ commit }, template) {
    return new Promise(async (resolve, reject) => {
      try {
        commit('SET_TEMPLATE_URL_LOADING', true);
        if (template.category === 'template') {
          const path = `/assets/img/themes/`;
          const thumbnailPromises = [];

          if (template.selectedTemplate?.thumbnail) {
            const p1 = await getFileURL(
              null,
              `public/${template.selectedTemplate.thumbnail}`,
              null,
              'storage',
            )
              .then((s3url) => {
                template.selectedTemplate.thumbnailUrl = s3url;
                commit('SET_SELECTED_TEMPLATE_THUMBNAIL_URL', s3url);
              })
              .catch((error) => {
                console.error(
                  'Error fetching selected template thumbnail:',
                  error,
                );
                template.selectedTemplate.thumbnailUrl = `${path}${template.selectedTemplate.thumbnail}`;
              });
            thumbnailPromises.push(p1);
          }

          if (template.currentTemplate?.thumbnail) {
            const p2 = await getFileURL(
              null,
              `public/${template.currentTemplate.thumbnail}`,
              null,
              'storage',
            )
              .then((s3url) => {
                template.currentTemplate.thumbnailUrl = s3url;
                commit('SET_CURRENT_TEMPLATE_THUMBNAIL_URL', s3url);
              })
              .catch((error) => {
                console.error(
                  'Error fetching current template thumbnail:',
                  error,
                );
                template.currentTemplate.thumbnailUrl = `${path}${template.currentTemplate.thumbnail}`;
              });
            thumbnailPromises.push(p2);
          }

          await Promise.allSettled(thumbnailPromises);

          resolve(template);
        } else {
          resolve(template);
        }
      } catch (error) {
        console.error('Error fetching template thumbnails:', error);
        reject(error);
      } finally {
        commit('SET_TEMPLATE_URL_LOADING', false);
      }
    });
  },
  setNewUploadId({ commit }, uploadId) {
    commit('SET_NEW_UPLOAD_ID', uploadId);
  },
  setIncludeImageConversion({ commit }, includeImagesInConversion) {
    commit('SET_INCLUDE_IMAGE_CONVERSION', includeImagesInConversion);
  },
  setActiveSuggestionView({ commit }, view) {
    commit('SET_ACTIVE_SUGGESTION_VIEW', view);
  },
  setIsDeckFullCompliantFlow({commit}, val) {
    commit('SET_IS_DECK_FULL_COMPLIANT_FLOW', val)
  },
  setTcErrorCode({commit}, val) {
    commit('SET_TC_ERROR_CODE', val)
  }
};


export default {
  namespaced: true,
  state: {
    ...getDefaultState(),
  },
  mutations,
  actions,
  getters,
};
