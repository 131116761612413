<template>
  <v-app>
    <v-main v-if="!isSuccessIntermediateLoader">
      <div v-if="!isLoading && !isFailed" class="logo">
        <img
          src="/assets/img/prezent_logo.svg"
          alt
          width="200px"
          height="44px"
        />
      </div>
      <div v-if="isLoading || isFailed" class="loading-wrapper">
        <div class="logo-loading">
          <img src="/assets/img/prezent_stack.svg" alt width="248px" />
        </div>
        <p v-if="isFailed" class="expired-link">
          Redirecting you to teams.prezent.ai...
        </p>
        <clip-loader
          v-if="isLoading || isFailed"
          :loading="loading"
          :color="`#21a7e0`"
          :width="'60'"
          :height="'60'"
          :size="'50px'"
          class="cliploader"
        />
      </div>
      <div v-if="!isLoading && !isFailed" class="signup-wrapper">
        <div>
          <div class="signup-content">
            <div class="signup-content-left">
              <v-img src="/assets/img/signup-image.svg" alt />
            </div>
            <div class="signup-content-right">
              <v-form
                class="signup-form"
                ref="form"
                v-model="valid"
                lazy-validation
                v-on:submit.prevent="submit"
              >
                <div class="signup-heading">
                  <h1>
                    <span>Sign up to Prezent</span>
                  </h1>
                </div>
                <div class="label">First name</div>
                <v-text-field
                  v-model="firstName"
                  :label="firstName ? '' : 'Enter your first name'"
                  :rules="nameRules.firstName"
                  :disabled="isReadonlyfirstName"
                  required
                  single-line
                  color="#000"
                  class="mb-4"
                  id="first"
                />
                <div class="label">Last name</div>
                <v-text-field
                  v-model="lastName"
                  :label="lastName ? '' : 'Enter your last name'"
                  :rules="nameRules.lastName"
                  :disabled="isReadonlylastName"
                  required
                  single-line
                  color="#000"
                  class="mb-4"
                  id="last"
                />
                <div class="label">Email</div>
                <v-text-field
                  v-model="email"
                  :label="email ? '' : 'Enter your work email'"
                  :disabled="true"
                  required
                  single-line
                  color="#000"
                  class="mb-4"
                  id="email"
                />
                <div class="label">Company name</div>
                <v-text-field
                  v-model="companyName"
                  :label="companyName ? '' : 'Enter your company name'"
                  :rules="nameRules.companyName"
                  :disabled="true"
                  required
                  single-line
                  color="#000"
                  class="mb-4"
                  id="first"
                />
                <div v-if="!ssoUser">
                  <div class="label">Create Password</div>
                  <v-form ref="passwordControl" v-on:submit.prevent="submit">
                    <v-text-field
                      v-model="password"
                      :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'"
                      :type="show1 ? 'text' : 'password'"
                      name="input-10-1"
                      label="Minimum 8 characters"
                      id="password"
                      single-line
                      class="mb-4"
                      @click:append="show1 = !show1"
                      :rules="passwordRules"
                    />
                  </v-form>
                  <div
                    v-if="password && passwordRequirements.length"
                    class="password-errors-box"
                  >
                    <div
                      v-for="(value, index) in passwordErrors"
                      :key="index"
                      class="password-wrapper"
                    >
                      <span :class="checkError(value) ? 'visibility' : ''"
                        >&#10003;</span
                      >
                      <p :class="checkError(value) ? 'password-error' : ''">
                        {{ value }}
                      </p>
                    </div>
                  </div>
                  <div class="label">Confirm Password</div>
                  <v-form
                    ref="confirmPasswordControl"
                    v-on:submit.prevent="submit"
                  >
                    <v-text-field
                      v-model="rePassword"
                      :append-icon="show2 ? 'mdi-eye' : 'mdi-eye-off'"
                      :rules="confirmPasswordRules"
                      :type="show2 ? 'text' : 'password'"
                      name="input-10-1"
                      label="Minimum 8 characters"
                      id="rePassword"
                      single-line
                      @click:append="show2 = !show2"
                    />
                  </v-form>
                </div>
                <div v-if="ssoUser">
                  <p>
                    Once registered, please use your corporate<br />email id and
                    password to log in to Prezent
                  </p>
                </div>
                <div class="terms">
                  <p>
                    By clicking below, you agree to our<br /><a
                      href="https://prezent.ai/terms-of-service/"
                      target="_blank"
                    >
                      Terms of Service </a
                    >and
                    <a href="https://prezent.ai/privacy-policy/" target="_blank"
                      >Privacy Policy</a
                    >.
                  </p>
                </div>
                <div style="text-align: left">
                  <v-btn
                    @click="submit"
                    :disabled="
                      !firstName ||
                      !lastName ||
                      !email ||
                      !!passwordRequirements.length ||
                      ((!password ||
                        !rePassword ||
                        !(password && password.length >= 8) ||
                        !(rePassword === password)) &&
                        !ssoUser)
                    "
                    color="primary"
                    rounded
                    min-width="144"
                    height="50"
                    id="submit"
                    :loading="isSignupLoading"
                  >
                    Sign up
                  </v-btn>
                  <p class="card-details">No credit card required</p>
                  <div v-if="error !== ''" class="error">
                    {{ error }}
                  </div>
                </div>
              </v-form>
            </div>
          </div>
        </div>
      </div>
    </v-main>
    <v-main v-else>
      <div class="logo">
        <img
          src="/assets/img/prezent_logo.svg"
          alt
          width="200px"
          height="44px"
        />
      </div>
      <div class="redirect">
        <v-img
          src="/assets/img/account-creation-success.svg"
          alt
          max-width="358px"
          max-height="281px"
          style="margin-bottom: 24px"
        />
        <p class="heading">
          Yay! Your Prezent account was created successfully!
        </p>
        <p class="subHeading">Redirecting you to login {{ counter }} ...</p>
      </div>
    </v-main>
    <div v-if="isSuccessIntermediateLoader" class="privacy-policy-footer">
      <div class="privacy-policy">
        <a href="https://prezent.ai/privacy-policy" target="_blank"
          >Privacy Policy</a
        >
      </div>
      <div>&#169; Prezent Inc. 2021</div>
    </div>
  </v-app>
</template>

<script>
import ClipLoader from 'vue-spinner/src/ClipLoader.vue';
import { trialValidate, trialSignUp } from '../utils/api-helper';
import { clearCachedUser } from '../utils/common';
import { isEmojiExist } from '../components/utils';

export default {
  name: 'TrialSignUp',
  components: {
    ClipLoader,
  },
  data() {
    return {
      id: '',
      isAudienceFlow: false,
      ssoUser: false,
      valid: true,
      isReadonlyfirstName: false,
      isReadonlylastName: false,
      isLoading: true,
      error: '',
      firstName: '',
      lastName: '',
      email: '',
      companyName: '',
      nameRules: {
        firstName: [
          (v) => !isEmojiExist(v) || 'Emojis are not allowed',
          (v) => !!v || 'First Name is required',
        ],
        lastName: [
          (v) => !isEmojiExist(v) || 'Emojis are not allowed',
          (v) => !!v || 'Last Name is required',
        ],
      },
      passwordRules: [
        (v) => !isEmojiExist(v) || 'Emojis are not allowed',
        () => this.isPasswordMatched('password') || 'Password must match',
      ],
      confirmPasswordRules: [
        (v) => !isEmojiExist(v) || 'Emojis are not allowed',
        () =>
          this.isPasswordMatched('confirmPassword') || 'Password must match',
      ],
      show1: false,
      show2: false,
      password: '',
      rePassword: '',
      passwordErrors: [
        '8 characters',
        '1 letter',
        '1 number',
        '1 special character',
      ],
      isSignupLoading: false,
      isFailed: false,
      loading: true,
      isSuccessIntermediateLoader: false,
      counter: 3,
    };
  },
  computed: {
    passwordRequirements() {
      const errors = [];
      if (this.password && this.password.length < 8)
        errors.push('8 characters');
      if (this.password && !/[A-Za-z]{1}/.test(this.password))
        errors.push('1 letter');
      if (this.password && !/\d/.test(this.password)) errors.push('1 number');
      if (this.password && !/[^A-Za-z0-9]/.test(this.password))
        errors.push('1 special character');
      return errors;
    },
  },
  methods: {
    checkError(err) {
      return this.passwordRequirements.includes(err);
    },
    isPasswordMatched(control) {
      if (
        (control === 'password' && this.rePassword) ||
        (control === 'confirmPassword' && this.password)
      ) {
        return this.password === this.rePassword;
      }
      return true;
    },
    async submit() {
      if (
        !this.$refs.form.validate() ||
        !this.isPasswordMatched('password') ||
        !this.isPasswordMatched('confirmPassword')
      )
        return;
      try {
        this.isSignupLoading = true;
        const user = {
          email: this.email,
          firstname: this.firstName,
          lastname: this.lastName,
          password: this.password,
          trialRegID: this.id,
        };
        await trialSignUp(user);
        this.isSignupLoading = false;
        this.isSuccessIntermediateLoader = true;
        const countDownTimer = setInterval(() => {
          this.counter -= 1;
          if (this.counter < 1) {
            clearInterval(countDownTimer);
            clearCachedUser();
            this.isSuccessIntermediateLoader = false;
            this.$router.push('/signin', () => {});
          }
        }, 1000);
      } catch (err) {
        console.log(err);
        this.isFailed = true;
        this.isSignupLoading = false;
        setTimeout(() => {
          this.$router.push('/signin', () => {});
        }, 3000);
      }
    },
  },
  async mounted() {
    if (this.$route?.query?.id) {
      const queryString = this.$route.query.id;
      try {
        this.id = queryString;
        const userData = await trialValidate(this.id);
        this.ssoUser = userData?.message?.sso_enabled;
        this.firstName = userData?.message?.trialRegistrationUserData?.firstname
          ? userData?.message?.trialRegistrationUserData?.firstname
          : '';
        if (this.firstName) {
          this.isReadonlyfirstName = true;
        }
        this.lastName = userData?.message?.trialRegistrationUserData?.lastname
          ? userData?.message?.trialRegistrationUserData?.lastname
          : '';
        if (this.lastName) {
          this.isReadonlylastName = true;
        }
        this.email = userData?.message?.trialRegistrationUserData?.email;
        this.companyName =
          userData?.message?.trialRegistrationUserData?.companyDisplayName;
        this.isLoading = false;
      } catch (err) {
        this.isFailed = true;
        this.isLoading = false;
        setTimeout(() => {
          this.$router.push('/signin', () => {});
        }, 3000);
        console.log('err', err);
      }
    } else {
      this.isLoading = false;
      this.isFailed = true;
      setTimeout(() => {
        this.$router.push('/signin', () => {});
      }, 3000);
      console.log('No access token');
    }
  },
  watch: {
    rePassword(value) {
      if (value) {
        this.$refs.passwordControl.resetValidation();
        this.$refs.passwordControl.validate();
      }
    },
    password(value) {
      if (value) {
        this.$refs.confirmPasswordControl.resetValidation();
        this.$refs.confirmPasswordControl.validate();
      }
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.v-application {
  background: linear-gradient(
    0.61deg,
    rgba(149, 208, 234, 0.6) -22.84%,
    rgba(255, 255, 255, 0.048) 38.59%
  );
}

.logo {
  padding: 30px 30px 0px 40px;
  width: fit-content;
}
.logo-loading {
  padding-bottom: 20px;
}
.expired-link {
  font-size: 20px;
  font-weight: 500;
  text-align: center;
}
.loading-wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: 400px;
}
.signup-wrapper {
  height: fit-content;
  margin: auto;
  padding: 0px 20px;
  width: 100%;
  .signup-content {
    align-items: flex-start;
    display: flex;
    justify-content: space-evenly;
    .signup-content-right {
      margin: 0px 50px;
      max-width: 300px;
      width: 25%;
      .signup-form {
        position: relative;
        .signup-heading {
          margin-bottom: 25px;
          padding-top: 10px;
        }
        .password-errors-box {
          box-shadow: 0 3px 10px rgb(0 0 0 / 20%);
          font-size: 12px;
          left: -46%;
          padding: 5px;
          position: absolute;
          top: 48%;
          .password-wrapper {
            align-items: center;
            display: flex;
            justify-content: flex-start;
            .visibility {
              visibility: hidden;
            }
            span {
              font-size: 18px;
              font-weight: 500;
            }
            .password-error {
              color: red;
            }
            p {
              margin-bottom: 0px;
            }
          }
        }
        .card-details {
          font-size: 14px;
          margin-top: 20px;
        }
        .label {
          font-size: 24px;
          font-weight: normal;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: left;
        }
        button {
          font-size: 24px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          text-align: center;
          text-transform: none;
          margin-top: 38px;
        }
        .error {
          background-color: transparent !important;
          border-color: transparent !important;
          color: red;
        }
      }
    }
  }
}
.terms {
  align-items: center;
  display: block;
  margin-top: 20px;
  p {
    margin-bottom: 0px;
  }
  a {
    text-decoration: none;
  }
}
.password-errors-box::after {
  border: 10px solid transparent;
  border-left: 10px solid white;
  clear: both;
  content: '';
  position: absolute;
  right: -20px;
  visibility: visible;
  top: 40px;
}

.redirect {
  align-items: center;
  display: flex;
  flex-direction: column;

  p {
    font-size: 24px;
    line-height: 28px;
    font-weight: 600;
    margin-bottom: 22px;
  }
}

.privacy-policy-footer {
  bottom: 0;
  color: gray !important;
  display: flex;
  justify-content: flex-end;
  padding: 20px 60px;
  right: 0;
  margin-top: auto;
  .privacy-policy {
    padding-right: 20px;
    a {
      color: inherit;
      text-decoration: none;
    }
  }
}
.signup-content-left {
  margin-top: 40px;
}
</style>
