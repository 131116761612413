import { cloneDeep } from 'lodash';
import createPersistedState from 'vuex-persistedstate';
import { addRecentSearches } from '../../utils/api-helper';

export const searchInitialState = {
  snapshot: {},
  recentQueries: [],
};

export const search = {
  namespaced: true,
  state: {
    ...searchInitialState,
  },
  getters: {},
  mutations: {
    SET_STATE_SNAPSHOT(state, snapshot) {
      state.snapshot = snapshot;
    },
    SET_RECENT_QUERIES_ON_MOUNT(state, queries) {
      state.recentQueries = [...queries];
    },
    UPDATE_RECENT_QUERIES(state, query) {
      if (state.recentQueries.length >= 5) {
        state.recentQueries.pop();
      }
      state.recentQueries.unshift({
        ...query,
        name: query.value,
        item: query.object,
      });
    },
    UPDATE_RECENT_QUERIESS(state, query) {
      const recentQueries = state.recentQueries.filter(item => item.value !== query.value);
      recentQueries.unshift({
        ...query,
        name: query.value,
        item: query.object,
      });
      state.recentQueries = [...recentQueries];
    },
  },
  actions: {
    setSearchStateSnapshot({ commit }, snapshot) {
      commit('SET_STATE_SNAPSHOT', cloneDeep(snapshot));
    },
    setRecentQueriesOnMount({ commit }, queries) {
      commit('SET_RECENT_QUERIES_ON_MOUNT', queries);
    },
    async updateRecentQueries({ commit, state }, query) {
      const data = state.recentQueries.find(ele => ele.value === query.value && ele.type === query.type);
      if (!data) {
        commit('UPDATE_RECENT_QUERIES', query);
        await addRecentSearches(query);
      } else {
        commit('UPDATE_RECENT_QUERIESS', query);
        await addRecentSearches(query);
      }
    },
  },
  persist: createPersistedState({
    key: 'vuex_search',
    paths: [],
  }),
};
