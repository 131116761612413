import createPersistedState from 'vuex-persistedstate';

export const slidesStoreInitialState = {
  favoriteSlides: [],
  currentAudience: {},
  savedQueryPayload: {},
  activeSlide: {},
  currentThemeCode: '',
  imageReplacedSlides: [],
  downloadedSlides: [],
  routeToNavigate: {
    content: '',
    type: '',
    action: '',
    pushAction: false,
  },
  slideViewOrigin: '',
  slideBackClicked: false,
  slideCollectionTool: false,
  slideLocalSearch: '',
  slidesSnapshot: {},
  bestPractisesOnSlide: [],
  isReplaceImageActionClicked: false,
  bestPractiseSelected: {},
  isSlideDetailsDirty: false,
  isSlideACLDirty: false,
  isSlideBestPractisesDirty: false,
  flowActive: '',
  slideViewConfirmationModalState: false,
  bestPractiseSelected: {},
  slideDetailViewChangedAudienceAndTemplate: {},
  slidesLoadedInUI: [],
  slideViewDialogContentOnLoad: null,
  replaceStackData: {},
  iconReplacedSlides: [],
  isIconNotFound: false,
  isImageNotFound: false,
  sidePanelAction: '',
  sidePanelOpen: false,
  toggleBestPracticesSwitch: false,
  toggleBestPracticesSwitchInitialState: false,
  showLoadingOverlay: false,
  currAnimationImages: null,
  replacementViewSelected: '',
  isLoadingInRepImage: false,
  isLoadingInRepIcon: false,
  isDetailViewInPageAction: false,
  detailViewInPageAction: '',
};

export const slidesStore = {
  namespaced: true,
  state: {
    ...slidesStoreInitialState,
  },
  getters: {
    getFlowActive: (state) => state.flowActive ? state.flowActive : '',
    getIsSlideDetailsDirty: (state) => state.isSlideDetailsDirty,
    getIsSlideACLDirty: (state) => state.isSlideACLDirty,
    getIsSlideBestPractisesDirty: (state) => state.isSlideBestPractisesDirty,
    getFavoriteSlides: (state) => state.favoriteSlides ? state.favoriteSlides : [],
    getCurrentAudience: (state) => state.currentAudience ? state.currentAudience : {},
    getSavedQueryPayload: (state) => state.savedQueryPayload ? state.savedQueryPayload : {},
    getActiveSlide: (state) => state.activeSlide ? state.activeSlide : {},
    getCurrentThemeCode: (state) => state.currentThemeCode ? state.currentThemeCode : '',
    getSlideCollectionToolStatus: (state) => state.slideCollectionTool,
    getSlideLocalSearchText: (state) => state.slideLocalSearch,
    getImageReplacedSlides: (state) => state.imageReplacedSlides ? state.imageReplacedSlides : [],
    getDownloadedSlides: (state) => state.downloadedSlides ? state.downloadedSlides : [],
    getRouteToNavigate: (state) => state.routeToNavigate ? state.routeToNavigate : {
      content: '',
      type: '',
      action: '',
      pushAction: false,
    },
    getSlideViewOrigin: (state) => state.slideViewOrigin ? state.slideViewOrigin : '',
    getSlidesSnapshot: (state) => state.slidesSnapshot ? state.slidesSnapshot : {},
    getBestPractisesOnSlide: (state) => state.bestPractisesOnSlide ? state.bestPractisesOnSlide : [],
    getBestPractiseSelected: (state) => state.bestPractiseSelected ? state.bestPractiseSelected : {},
    getSlideViewConfirmationModalState: (state) => state.slideViewConfirmationModalState,
    getBestPractiseSelected: (state) => state.bestPractiseSelected ? state.bestPractiseSelected : {},
    getSlideDetailViewChangedAudienceAndTemplate: (state) => state.slideDetailViewChangedAudienceAndTemplate ? state.slideDetailViewChangedAudienceAndTemplate : {},
    getSlideViewDialogContentOnLoad: (state) => state.slideViewDialogContentOnLoad ? state.slideViewDialogContentOnLoad : null,
    getReplaceStackData: (state) => state.replaceStackData ? state.replaceStackData : {},
    getIconReplacedSlides: (state) => state.iconReplacedSlides ? state.iconReplacedSlides : [],
    getIsIconNotFound: (state) => state ? state.isIconNotFound : initialState.isIconNotFound,
    getIsImageNotFound: (state) => state ? state.isImageNotFound : initialState.isImageNotFound,
    getIsReplaceImageActionClicked: (state) => state ? state.isReplaceImageActionClicked : initialState.isReplaceImageActionClicked,
    getSidePanelAction: (state) => state.sidePanelAction ? state.sidePanelAction : '',
    getSidePanelOpen: (state) => state.sidePanelOpen,
    getToggleBestPracticesSwitch: (state) => state.toggleBestPracticesSwitch,
    getToggleBestPracticesSwitchInitialState: (state) => state.toggleBestPracticesSwitchInitialState,
    getShowLoadingOverlay: (state) => state.showLoadingOverlay,
    getCurrAnimationImages: (state) => state.currAnimationImages ? state.currAnimationImages : null,
    getReplacementViewSelected: (state) => state.replacementViewSelected ? state.replacementViewSelected : '',
    getIsLoadingInRepImage: (state) => state.isLoadingInRepImage,
    getIsLoadingInRepIcon: (state) => state.isLoadingInRepIcon,
    getIsDetailViewInPageAction: (state) => state.isDetailViewInPageAction,
    getDetailViewInPageAction: (state) => state.detailViewInPageAction ? state.detailViewInPageAction : '',
  },
  mutations: {
    SET_IS_DETAIL_VIEW_IN_PAGE_ACTION(state, val) {
      state.isDetailViewInPageAction = val;
    },
    SET_DETAIL_VIEW_IN_PAGE_ACTION(state, view) {
      state.detailViewInPageAction = view;
    },
    SET_IS_LOADING_IN_REP_IMAGE(state, val) {
      state.isLoadingInRepImage = val;
    },
    SET_IS_LOADING_IN_REP_ICON(state, val) {
      state.isLoadingInRepIcon = val;
    },
    SET_REPLACEMENT_VIEW_SELECTED(state, view) {
      state.replacementViewSelected = view;
    },
    SET_CURR_ANIMATION_IMAGES(state, img) {
      state.currAnimationImages = img;
    },
    SET_SHOW_LOADING_OVERLAY(state, status) {
      state.showLoadingOverlay = status;
    },
    SET_TOGGLE_BEST_PRACTICES_SWITCH_INITIAL_STATE(state, status) {
      state.toggleBestPracticesSwitchInitialState = status;
    },
    SET_TOGGLE_BEST_PRACTICES_SWITCH(state, status) {
      state.toggleBestPracticesSwitch = status;
    },
    SET_SIDE_PANEL_ACTION(state, comp) {
      state.sidePanelAction = comp;
    },
    SET_SIDE_PANEL_OPEN(state, status) {
      state.sidePanelOpen = status;
    },
    ADD_TO_ICON_REPLACED_SLIDES(state, data) {
      const existingSlides = [...state.iconReplacedSlides];
      state.iconReplacedSlides = [...existingSlides, data];
    },
    SET_REPLACE_STACK_DATA(state, data) {
      state.replaceStackData = data;
    },
    SET_SLIDE_VIEW_DIALOG_CONTENT_ON_LOAD(state, content) {
      state.slideViewDialogContentOnLoad = content;
    },
    SET_SLIDE_DETAIL_VIEW_CHANGED_AUDIENCE_AND_TEMPLATE(state, slideDetailViewChangedAudienceAndTemplate) {
      state.slideDetailViewChangedAudienceAndTemplate = slideDetailViewChangedAudienceAndTemplate;
    },
    SET_BEST_PRACTISE_SELECTED(state, bestPractiseSelected) {
      state.bestPractiseSelected = bestPractiseSelected;
    },
    SET_SLIDE_VIEW_CONFIRMATION_MODAL_STATE(state, slideViewConfirmationModalState) {
      state.slideViewConfirmationModalState = slideViewConfirmationModalState;
    },
    SET_FLOW_ACTIVE(state, flowActive) {
      state.flowActive = flowActive;
    },
    SET_BEST_PRACTISE_SELECTED(state, bestPractiseSelected) {
      state.bestPractiseSelected = bestPractiseSelected
    },
    SET_BEST_PRACTISES_ON_SLIDE(state, bestPractisesOnSlide) {
      state.bestPractisesOnSlide = bestPractisesOnSlide;
    },
    SAVE_SLIDES_STATE_SNAPSHOT(state, snapshot) {
      state.slidesSnapshot = snapshot;
    },
    SET_SLIDE_BACK_CLICKED(state, value) {
      state.slideBackClicked = value;
    },
    SET_SLIDE_COLLECTION_TOOL(state, value) {
      state.slideCollectionTool = value;
    },
    SET_SLIDE_LOCAL_SEARCH(state, value) {
      state.slideLocalSearch = value;
    },
    SET_SLIDE_VIEW_ORIGIN(state, slideViewOrigin) {
      state.slideViewOrigin = slideViewOrigin;
    },
    SET_ROUTE_TO_NAVIGATE(state, routeToNavigate) {
      state.routeToNavigate = routeToNavigate;
    },
    ADD_TO_FAVORITE_SLIDES(state, favoriteSlide) {
      const existingFavs = [...state.favoriteSlides];
      state.favoriteSlides = [...existingFavs, favoriteSlide];
    },
    REMOVE_FROM_FAVORITE_SLIDES(state, favoriteSlide) {
      const existingFavs = [...state.favoriteSlides];
      const filteredFavs = [...existingFavs].filter((item) => item !== favoriteSlide);
      state.favoriteSlides = [...filteredFavs];
    },
    SET_CURRENT_AUDIENCE(state, currentAudience) {
      state.currentAudience = currentAudience;
    },
    SET_SAVED_QUERY_PAYLOAD(state, savedQueryPayload) {
      state.savedQueryPayload = savedQueryPayload;
    },
    SET_ACTIVE_SLIDE(state, activeSlide) {
      state.activeSlide = activeSlide;
    },
    SET_CURRENT_THEME_CODE(state, currentThemeCode) {
      state.currentThemeCode = currentThemeCode;
    },
    ADD_TO_IMAGE_REPLACED_SLIDES(state, imageReplacedSlide) {
      const existingSlides = [...state.imageReplacedSlides];
      state.imageReplacedSlides = [...existingSlides, imageReplacedSlide];
    },
    ADD_TO_DOWNLOADED_SLIDES(state, downloadedSlide) {
      const existingSlides = [...state.downloadedSlides];
      state.downloadedSlides = [...existingSlides, downloadedSlide];
    },
    RESET_SLIDES_STORE(state) {
      state.favoriteSlides = [];
      state.currentAudience = {};
      state.savedQueryPayload = {};
      state.activeSlide = {};
      state.currentThemeCode = '';
      state.imageReplacedSlides = [];
      state.downloadedSlides = [];
      state.routeToNavigate = {
        content: '',
        type: '',
        action: '',
        pushAction: false,
      };
      state.slideViewOrigin = '';
      state.slidesSnapshot = {};
      state.bestPractisesOnSlide = [];
      state.bestPractiseSelected = {};
      state.isSlideDetailsDirty = false;
      state.isSlideACLDirty = false;
      state.isSlideBestPractisesDirty = false;
      state.flowActive = '';
      state.slideViewConfirmationModalState = false;
      state.slideViewDialogContentOnLoad = null;
      state.replaceStackData = {};
      state.sidePanelAction = '';
      state.sidePanelOpen = false;
      state.toggleBestPracticesSwitch = false;
      state.toggleBestPracticesSwitchInitialState = false;
      state.showLoadingOverlay = false;
      state.currAnimationImages = null;
      state.replacementViewSelected = '';
      state.isLoadingInRepImage = true;
      state.isLoadingInRepIcon = true;
      state.isDetailViewInPageAction = false;
      state.detailViewInPageAction = '';
    },
    SET_IS_SLIDE_DETAILS_DIRTY(state, isSlideDetailsDirty) {
      state.isSlideDetailsDirty = isSlideDetailsDirty;
    },
    SET_IS_SLIDE_ACL_DIRTY(state, isSlideACLDirty) {
      state.isSlideACLDirty = isSlideACLDirty;
    },
    SET_IS_SLIDE_BEST_PRACTISES_DIRTY(state, isSlideBestPractisesDirty) {
      state.isSlideBestPractisesDirty = isSlideBestPractisesDirty;
    },
    SET_SLIDES_LOADED_IN_UI(state, slides) {
      state.slidesLoadedInUI = slides;
    },
    SET_CONTENT_NOT_FOUND(state, data) {
      if (data.type === 'icon') {
        state.isIconNotFound = data.value;
      } else if (data.type === 'image') {
        state.isImageNotFound = data.value;
      }
    },
    RESET_CONTENT_NOT_FOUND_FLAGS(state) {
      state.isIconNotFound = false;
      state.isImageNotFound = false;
    },
    SET_REPLACE_IMAGE_ACTION_CLICKED(state, data) {
      state.isReplaceImageActionClicked = data;
    }
  },
  actions: {
    setIsDetailViewInPageAction({ commit }, val) {
      commit('SET_IS_DETAIL_VIEW_IN_PAGE_ACTION', val);
    },
    setDetailViewInPageAction({ commit }, view) {
      commit('SET_DETAIL_VIEW_IN_PAGE_ACTION', view);
    },
    setIsLoadingInRepImage({ commit }, val) {
      commit('SET_IS_LOADING_IN_REP_IMAGE', val)
    },
    setIsLoadingInRepIcon({ commit }, val) {
      commit('SET_IS_LOADING_IN_REP_ICON', val)
    },
    setReplacementViewSelected({ commit }, view) {
      commit('SET_REPLACEMENT_VIEW_SELECTED', view);
    },
    setCurrAnimationImages({ commit }, img) {
      commit('SET_CURR_ANIMATION_IMAGES', img);
    },
    setShowLoadingOverlay({ commit }, status) {
      commit('SET_SHOW_LOADING_OVERLAY', status);
    },
    setToggleBestPracticesSwitchInitialState({ commit }, status) {
      commit('SET_TOGGLE_BEST_PRACTICES_SWITCH_INITIAL_STATE', status);
    },
    setToggleBestPracticesSwitch({ commit }, status) {
      commit('SET_TOGGLE_BEST_PRACTICES_SWITCH', status);
    },
    setSidePanelAction({ commit }, comp) {
      commit('SET_SIDE_PANEL_ACTION', comp);
    },
    setSidePanelOpen({ commit }, status) {
      commit('SET_SIDE_PANEL_OPEN', status);
    },
    addToIconReplacedSlides({ commit }, data) {
      commit('ADD_TO_ICON_REPLACED_SLIDES', data);
    },
    setReplaceStackData({ commit }, data) {
      commit('SET_REPLACE_STACK_DATA', data);
    },
    setSlideViewDialogContentOnLoad({ commit }, content) {
      commit('SET_SLIDE_VIEW_DIALOG_CONTENT_ON_LOAD', content);
    },
    setSlideDetailViewChangedAudienceAndTemplate({ commit}, slideDetailViewChangedAudienceAndTemplate) {
      commit('SET_SLIDE_DETAIL_VIEW_CHANGED_AUDIENCE_AND_TEMPLATE', slideDetailViewChangedAudienceAndTemplate);
    },
    setBestPractiseSelected({ commit }, bestPractiseSelected) {
      commit('SET_BEST_PRACTISE_SELECTED', bestPractiseSelected);
    },
    setSlideViewConfirmationModalState({ commit }, slideViewConfirmationModalState) {
      commit('SET_SLIDE_VIEW_CONFIRMATION_MODAL_STATE', slideViewConfirmationModalState);
    },
    setFlowActive({ commit }, flowActive) {
      commit('SET_FLOW_ACTIVE', flowActive);
    },
    setIsSlideDetailsDirty({ commit }, isSlideDetailsDirty) {
      commit('SET_IS_SLIDE_DETAILS_DIRTY', isSlideDetailsDirty);
    },
    setIsSlideACLDirty({ commit }, isSlideACLDirty) {
      commit('SET_IS_SLIDE_ACL_DIRTY', isSlideACLDirty);
    },
    setIsSlideBestPractisesDirty({ commit }, isSlideBestPractisesDirty) {
      commit('SET_IS_SLIDE_BEST_PRACTISES_DIRTY', isSlideBestPractisesDirty);
    },
    setBestPractiseSelected({ commit }, bestPractiseSelected) {
      commit('SET_BEST_PRACTISE_SELECTED', bestPractiseSelected);
    },
    setSlideBackClicked({ commit }, value) {
      commit('SET_SLIDE_BACK_CLICKED', value);
    },
    setSlideCollectionTool({ commit }, value) {
      commit('SET_SLIDE_COLLECTION_TOOL', value);
    },
    setSlideLocalSearch({ commit }, value) {
      commit('SET_SLIDE_LOCAL_SEARCH', value);
    },
    setBestPractisesOnSlide({ commit }, bestPractisesOnSlide) {
      commit('SET_BEST_PRACTISES_ON_SLIDE', bestPractisesOnSlide);
    },
    saveSlidesStateSnapshot({ commit }, snapshot) {
      commit('SAVE_SLIDES_STATE_SNAPSHOT', snapshot);
    },
    resetSlidesStore({ commit }) {
      commit('RESET_SLIDES_STORE');
    },
    setSlideViewOrigin({ commit }, slideViewOrigin) {
      commit('SET_SLIDE_VIEW_ORIGIN', slideViewOrigin);
    },
    setRouteToNavigate({ commit }, routeToNavigate) {
      commit('SET_ROUTE_TO_NAVIGATE', routeToNavigate);
    },
    addToFavoriteSlides({ commit }, favoriteSlide) {
      commit('ADD_TO_FAVORITE_SLIDES', favoriteSlide);
    },
    removeFromFavoriteSlides({ commit }, favoriteSlide) {
      commit('REMOVE_FROM_FAVORITE_SLIDES', favoriteSlide);
    },
    setCurrentAudience({ commit }, currentAudience) {
      commit('SET_CURRENT_AUDIENCE', currentAudience);
    },
    setSavedQueryPayload({ commit }, savedQueryPayload) {
      commit('SET_SAVED_QUERY_PAYLOAD', savedQueryPayload);
    },
    setActiveSlide({ commit }, activeSlide) {
      commit('SET_ACTIVE_SLIDE', activeSlide);
    },
    setCurrentThemeCode({ commit }, currentThemeCode) {
      commit('SET_CURRENT_THEME_CODE', currentThemeCode);
    },
    addToImageReplacedSlides({ commit }, imageReplacedSlide) {
      commit('ADD_TO_IMAGE_REPLACED_SLIDES', imageReplacedSlide);
    },
    addToDownloadedSlides({ commit }, downloadedSlide) {
      commit('ADD_TO_DOWNLOADED_SLIDES', downloadedSlide);
    },
    setSlidesLoadedInUI({ commit }, slides) {
      commit('SET_SLIDES_LOADED_IN_UI', slides);
    },
    setContentNotFound({commit}, data) {
      commit('SET_CONTENT_NOT_FOUND', data);
    },
    resetContentNotFoundFlags({commit}) {
      commit('RESET_CONTENT_NOT_FOUND_FLAGS');
    },
    setReplaceImageActionClicked({commit}, data) {
      commit('SET_REPLACE_IMAGE_ACTION_CLICKED', data);
    }
  },
  persist: createPersistedState({
    key: 'vuex_slidesStore',
    paths: [],
  }),
  persist: createPersistedState({
    key: 'vuex_slidesStore',
    paths: ['slidesStore.slideDetailViewChangedAudienceAndTemplate'],
  }),
};
